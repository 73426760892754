import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_sswj = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var photostocklist = {
        TGI_SPBG: {
          
            "1":"1T8HcVE7Hq6pC9GCfwLFAX7LvW6L25v2Q",
            "2":"1FC630v1cMCHlfRQnh8gsa1S0iRFABohk",
            "3":"1mzRCJYXT-D-bJAcJNTblUqDd9Oik59dj",
            "4":"1Mjs5Xcw3sk7gFpIkIpI_Y0Pl-TCtgvGL",
            "5":"1xUi8XzfXTRN6bXBUkDJg7sZA0KvpyMXe",
            "6":"1WRyHNAT6x_kWtd8kpLfnL6zTqfaS2Oxt",
            "7":"1jUGFDGgjvB9hpD1TrBxkpLnijBuwnCg0",
            "8":"1tgs5G4emErwqaFnHm5ht1-fYb0E5QhWn",
            "9":"1BA3bug6YDkkCCyd1a5m2XeDnFndkj9HY",
            "10":"1tNTM2JUGGz_vMwagtzOeoFxh-KnSdWTY",
            "11":"1eGkyT6PRwaS1AzbDkJ00MbyiDTjeOCBE",
            "12":"1bn1hMA0brX_9nkbVYfsRcBBvX-gcPWnu",
            "13":"1kvCVHI_V6J--wHzk4g7cHqSymKNWx_zx",
            "14":"1n3TKGYoMdIHT5Ui1HKcm4dB80vmjWOV8",
            "15":"1YYK--l4kqvQBpOE6kxHysL3AtjxXLEIq",
            "16":"1bTeuSBekFw3Km7P98jOefGEee7tljnmN",
            "17":"1pZG_2Ofb26AX8e3KyY0iQ5BXIYrDOv04",
            "18":"1WNXYFkrmUal961YPIxCecOQTwZqqbSaN",
            "19":"1GQJ82FHk41P1pjTwsP44IPc93msby0PA",
            "20":"1eLyGJE8cxVlc5iD4OxuxbHLIYtI8andM",
            "21":"1aMbHoSi5IU88wesfF2mEjoVsnVXR0dwO",
            "22":"1gdu_674TXJAmUJ0MJNsnq3Hhk6lqCcd0",
            "23":"1bd5IxtjodjpiZ2qht5uGKTvXUJgeyOEC",
            "24":"1sMExxRWhxZ32SqCCKrIghJ_dFnuO8yHv",
            "25":"1SSurL38pEAcOAnDHA5cJwN9RZ99ote-s",
            "26":"1ZznSGJ0hkgvDBEgMWJIVG7Zr382ajeN2",
            "27":"1hd5R-439VoOXUtFfsoWxA8QuMbOok1YN",
            "28":"1Zd7JAkq2QG_fP-ZZiLMs2KWwuTGOEtwB",
            "29":"1I2ECCmqK7lORxqrJYJZ0M6Lh3KTY0Gs3",
            "30":"1MSLqMv6wJy677XLdMIRy6rpLalq-L1z3",
            "31":"1rY0MP5uuWslMR04LRrIQvR-XH8qtnrUU",
            "32":"1v9hXaY_UbBiPvgD4BbhaGNwIMi6IYp1I",
            "33":"1jAETzrPJmvQo8iMRoiAocsD2SWyPwbFp",
            "34":"1syb9JM0iu0TLbKTUVdf9_1PCiHw8d8Pe",
            "35":"1DEeDecPxY5ORWuzxxnHfk4brC4i1Rf9U",
            "36":"1i-wBVFZuIkJrViM4yd8R-l5H4RhOzNd_",
            "37":"1TR0ELqA8NCWSL7qBR4igNS71Yy-fb8-K",
            "38":"10zDgUiZsetRWtub88kGB-dpmEgc8EqcN",
            "39":"1FkNXJVoJtXsUYqawuR3rbivxvaX-pISR",
            "40":"1KgagP3DRkbK07qIq7uajuspA_JcRr9DI",
            "41":"10ZDT5Iws2URj_2szTENxsjtYaveJOTup",
            "42":"1qPiL-_ElWp5uCg23TV9zKIa0gcZvplQT",
            "43":"1ao426A0w1Jk6tImZxQFpBtbWpSEuRCzn",
            "44":"1wyJSu-DktoDBVZxihUiELWt9gR8wXJgj",
            "45":"1wJvGYmY3PNFJOU2lWU962jKwnXxVQM2o",
            "46":"1VYRiqXGtKcfAoLXGsRP_pshgjq1xK_iv",
            "47":"1hq5HiGqwqCVFIElj3Xh_37oV_4i5ocvP",
            "48":"1uZZTH0RWmkao7rqmYFOUE6KAW4H8wIzj",
            "49":"1sm0cbb9nqx8deyqU76aU-bqX7idnJ7I-",
            "50":"1Y4oeoPhA7dEQHMb5gjfmNWhlZZ0uJtfS",
            "51":"1ipTU7pVqo55AtmMjOTKCw51SlFUOwo45",
            "52":"12qByj1-ZmN_L6Pp3458Z3bX4cPmhwLXf",
            "53":"1UfbBOxyYVIxo-xh62vEQnisu_lUg18pq",
            "54":"1_vyvZ44WRuFh3fWLwQFEC_51-cVdxOBr",
            "55":"176tqxaCv8jHF6g0FdKPKThvrdEOMn0yC",
            "56":"1BfnlFIlGcMaPEoh_meBthqXVSRvVruQR",
            "57":"1j9PbSfyK4qYi4-gQZNuaxeKMcc8bs6sH",
            
        },
		
    };
	
	 
 const photostockData = [
   { id: 'TGI_SPBG', label: 'SPBG Batam' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock.png`}
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_sswj;
