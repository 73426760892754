import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_sor1 = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	    var photostocklist = {
        
        GRESIK:{
            "1":"1MPZYu_BXP09vW1ezUVGwnhpQU4duYf5E",
            "2":"1Rp7Wm8YWbRXj6WJ-GuhIEsJ9F8aVXPzC",
            "3":"1zrruqLBIcafdW8a5G9BqekIWYy5fWaJ6",
            "4":"1L9sZo3asQmCr9U_SQOkNXJqAt-yPl2KI",
            "5":"11e1tyFLpLDyUI2hlCaCqycufo4lEzEt9",
            "6":"1CDGqlj3aJf5pINuMiYTw9PnnpqUY9m1e",
            "7":"1PkO82ExVVo933XtX-Al_A0VPrCTKBxas",
            "8":"1QX_R3OeUwgofkWeNsTxcND8n_WjP4KFS",
            "9":"1R0A2JQhtzc-agxs2Nhpt_nqOHqyDT6Nx",
            "10":"1R3ZxdoCrBY7hqNdJOwKP3m53hzK5h8zs",
            "11":"1YR-MCAn8AUTZMvSN_kW0shHxz0ekHuCC",
            "12":"1ZEz0gJRbFVDxpRRjXegXn7aR8CXebQB8",
            "13":"1aOau9-xDaGzHMGbcpSuylqNau0y5hCMY",
            "14":"1dk-D8k8jDQQ3XJxzHNrzSF9ZNZinNL3u",
            "15":"1meIugEvDjntwlGUaNboRjW5bTs_2k06z",
            "16":"1rHGJ2zsvWhvPbLGHJn0omjhSJTnbPn1N",
            "17":"1twQur59WXfFHKor_6LLlqODSMKLaUdB4",
            "18":"1-0jW3pzqt_fhtekb-bm6ccG2zuRSFoRg",
            "19":"12Ab1XmcEIpiLK5xCq7FCxOaXuCgyXlNY",
            "20":"14Ev5jEXLjykhC5fDXlIC_8UgwV7Yz0aC",
            "21":"1928-Xqy7jbRFBS6U1XRGb_T-OwKqsGWX",
            "22":"1CrctFH53hXCkxjssPvKqp6HH9VZcP6GC",
            "23":"1HVdEv4Hag0t7ayyOCkCSVFjamNqpGU0I",
            "24":"1MiRqc9_jF7u967Z45sMfh0q_AB5dKsgF",
            "25":"1Miraa4U2xC8aZnhnFxMz-ZgTrneXOtMj",
            "26":"1Mkln54ualcFBbg7Dibs6VIeo-cB1w91n",
            "27":"1T7G268xW9Y7ipvT7KSEZrXWwuBnHd1Jo",
            "28":"1TissJELCaRE6ex_Z-LIuo_u7Q1_IoUw0",
            "29":"1V-eB38lZHsq71WjzrzUJO612Kj_CocgX",
            "30":"1VtpxfGqcUvUwDF6o319DZVSxv5bJFot0",
            "31":"1Yrzl1m9-PG-fGHI77sNQuOc7dNnqSSyg",
            "32":"1aTuHfOoHEMcFLxPu7GxMj3mHfmXW7yJ_",
            "33":"1axNIG8WEqLaGZSvcu4pmNCnTRF182t8V",
            "34":"1cAFtuwqMp-mCraS4wq4aeE9bdNzsAJb1",
            "35":"1drCGczvap2fNFdPAy--oM5zzvhMtPYi3",
            "36":"1eRwDNDcOi_bhkvtXYsmku3kOu0waL3ub",
            "37":"1nz2_siBfr1VICiBFxD5GLoxFBZoHrxOJ",
            "38":"1ur0JI_XnLjexvuSFHYgfeyaPuckI6LtO",
            "39":"1vNlzkYB5vbgCH5U5qxEwEIylPVxIyA8U",
            "40":"1vm9umtlQovOFHhSr-hV0WSfn_Bjepy9m",
            "41":"1vvkQuJY8-Ffavy6MMWTQyjMtiMg1ayPJ",
            "42":"1wzSH2aoXuaur1qmsBpzcOfgktZ4OGYAN",
            "43":"13mKsEq5iu-VZLq_dlB0MJesCf_tS2mJ6",
            "44":"1DOaFZXyd4nXx0AqdvQDS5pp0XHPWKPuS",
            "45":"1EdkMi7nbBFjndNP500ELr2XL9T-wzeNn",
            "46":"1IuesnJdtEyTw6dfrfuSVVdun8HL-FiVB",
            "47":"1L3kByxgf9q1qQVkb5usGUDFR2mXl3WxM",
            "48":"1MegsPpo5dEv8cLkgTs_ycxvG5e_LNcMv",
            "49":"1QZQ6YNHAluwOYlD1or_6qPr8l9jfzIjg",
            "50":"1SPDrpiNJuBQtOCqeEdZAM7odw1gQX4lq",
            "51":"1SnB_e_rwQc1rAhr6XOAU_9N10wAJ7JEQ",
            "52":"1UkpwJvfRarfTVTJjjnL44aHtN6RupWTr",
            "53":"1XR5yZhCt0BkvohFJpWHBgBnKFm8e0oj6",
            "54":"1YC7nOcknJomyC_PDhdZeLV-2tG_f0K_q",
            "55":"1YwBobtKWH-mXP_bGY3HEqHTqlp3ihZ28",
            "56":"1agsB9hIZdXBXpI_mLFBW9dHEQCKt0RH5",
            "57":"1bdxu3zg-e-bkpi9wngK3BXlhJDLWeejp",
            "58":"1dM434AXUAbb_YtYKoeW7LGpRn3lDrAAF",
            "59":"1es5pDpNht6Cxv68ohDN1hoGtWOsO1bx3",
            "60":"1fUN_JWJGTypLMYZANqJBrsTNPjROCz3R",
            "61":"1l1WF8tdnRGNJLkzXwwxumIeqiwfAVtTH",
            "62":"1pYFsRlY42Uzi3fp31RJ1XXoX1yR1EXl4",
            "63":"1pdJM9kmclTMKu7YeYSLYSR7AGLJRPhsg",
            "64":"1tX_7pTrJZYp1i0Ni0kLAOcOIxcHbPV4g",
            "65":"1tpM-AzQplNitjcoZxRNwlc3y0_EzP2ID",
            "66":"1vTz5WwM6wDNkboHBhgtRSho090686mFV",
            "67":"1w9ghCZ0ZZtQlOdoE3yylFD63wcYGvI6i",
            "68":"1-JoJ6TwzOW0L93tpSZrz7QKPN28JIrKl",
            "69":"11ks-f_UkjutvZ5kvBSxo9onrrO6jSvVk",
            "70":"12uXidAjErKNqqUgNO7uIZh_acY9AWbT5",
            "71":"15F5MVIa4X_0QR0XwkV97LtAzg4DYRppL",
            "72":"17CuVHlEt1kmSmRghaZWBb_rRQ2ZL6ml9",
            "73":"1DxyxwjyzW7Q9ehH6FCpsC54JfsyrebUL",
            "74":"1Fwxd4VztN24jHDBsv69z4aktp39srThg",
            "75":"1MA9ElYNPKZ8uekby3uV-qplPEATHagiv",
            "76":"1OEENk6yE_OzRlCRFwsTAcy4u3YQ8CeZ7",
            "77":"1SegXxWXFaQxh4hJyF4LynOKCqxcfV-BI",
            "78":"1UzTneDfHODR_Q6ctz6iClutbFnKq-c2Z",
            "79":"1YobBOnzRUrWOOZ-xb3X4uYw290ogH_d6",
            "80":"1Zmlp5RNww_HTUqzczXr16Wkm1cXTsxTO",
            "81":"1dQjnoSkt8n9IK8t6p8Ub6VpMx4_Rgld5",
            "82":"1eqdR07sPZXR7jENofFGHsV4P0idBIv17",
            "83":"1hx0zLutHoTK44TnrMEV9IJUANmk8LS53",
            "84":"1k2-Ca9EDGGbmSkFpi1Phl386UFl56Nnm",
            "85":"1ohlO0wbOprj_MLjP24SxSY1394ilUEcU",
            "86":"1rz2lo4bhxNFruNgfSPSSZNLBCenic0ka",
            "87":"1vFEPMVJHxWZYgNKjKqC2STxdQD29Zed0",
            "88":"1wDIZ34Kovm5_LSPpG7vDKEIi3ERUkUf4",
        },
        
        TANDES:{
            "1":"1ah0koHXJEBkgLCTaVk7oYZ3ObB383AL6",
            "2":"13HE865wxXfM6DQnDH_uwu_1_ZkFw-6hV",
            "3":"14r4R3WcpQfwyv_365w5X4z7yTA5V88tf",
            "4":"17txi_O8P3JbVETbmggLIpPqQvWokSN2A",
            "5":"1H1IZxv1qNaQbQOLJpbEcT5tdlJHMgTkK",
            "6":"1NdjsnctW1j-AOMFoK08fgaLucXbSpj9A",
            "7":"1QpVmfU8kOwv2Wsi5KLNUgurTonw0ZnN6",
            "8":"1TXV2fO2l8SZdq8gZDSWhiTs-E81WGVTC",
            "9":"1ZWgGAuOEP3E_b3q9GIwfatxES11H0DRT",
            "10":"1so9j2_zM-XneP3QQ9rYpL4oJZqK6XSTL",
            "11":"11IQNYG2E7nLJV97HGQh7agvT8AMlHPFS",
            "12":"14Ewu_96ZkdqMHy1KbMAXzIuXADdKuUtc",
            "13":"19z4M2kw7atVR7Rn5YpUkNLKmmihR3Mpi",
            "14":"1APE4Tk1XMRl78RsLMyQ6UnGQKmwb-6dA",
            "15":"1AnTp8_00L4RKpRyedie-JHlFDGjXvtgz",
            "16":"1B2P1T1mnSD1YOWsvuoftWyX0UCFqPMll",
            "17":"1F4Pt2v5kSQgv6-SvFnJNQe5Szo6CJDZq",
            "18":"1FQy_G1N_lvAoTf38tu7tGqp8fXkyQUat",
            "19":"1HNnsum8hVDUXiryZGdRyiLP5kXscoWI7",
            "20":"1J0LccDjvJtMe9Q8VWmTjrxoq0o5Z4l0r",
            "21":"1KSrnrcvUP0OUg54NcwZ4lomRmvSIAVFh",
            "22":"1L5HzdCcgxI_AYNG4gZ10LEB6rVnU1Dkn",
            "23":"1Mt3U-wwqHSJYWZ6TKxXkGpXIZaP-p17-",
            "24":"1ROMkbrCqoC6mPM89eBIwjMIKFftuaJ_i",
            "25":"1U8RZ_46tiBQ-6pZ0DY0n_xcHPE-RpfKC",
            "26":"1V4m5GjP5gu0beYiBsqsYSTCOqZ2Z5C0L",
            "27":"1V7Gltm7oGA9AKWuf0xvpmKkWNoECV57R",
            "28":"1VVR9Lw8oL2df2TFWJPfPtmfeOzlEuyUa",
            "29":"1X9TVxPcvhyD9clV9TN-21jBXcfJ7xHCp",
            "30":"1_un5X0-Cgm2VsJKizWmTMAPmw8g6XsVk",
            "31":"1aLDR8XKhzU4Nbjo8T6VIPHuanRRU5yJc",
            "32":"1aWLfot0SDK3C0ifvjkrGulQ1GTmtBGiO",
            "33":"1bh4XVTB99DQSioOEVs4F7bNWX5NMu4Pv",
            "34":"1fu8ZEuHiPUwBYZ6u252kNPK5DBBhcXPB",
            "35":"1ky5doIEYRxaVHimsIDuXD7hJNzmh_ogi",
            "36":"1tMjFJ1Mdd4Fg54rgDIKYhhdauHYP3tim",
            "37":"10SwmNm5iZUqvkJiU2KcwNYVlyi758FrQ",
            "38":"14LnaY_b54LK3GFgFHNuiSCqiQVn79RW4",
            "39":"15E2PCu8iNdGcHlcbELRwYCmB5cQhjWIX",
            "40":"196_wH8QQlBieJolB3mhxyssbvhualWi9",
            "41":"19Bhx_sX9v9ZQO7HRilQBtdD4NXYbOnwO",
            "42":"1CtF8S1f_fqgJPeX7YBd9HHysHmRvFfL0",
            "43":"1E93bDb1NRjhhtCMN2dc5Q0ZKVkolOb9G",
            "44":"1H-E8vOYKC3FXYe0U7AwySz1m3r7BaatR",
            "45":"1HJFZYjpdlQ_avWGEaw8IZ0nVSlucUhmk",
            "46":"1LajfYHdgrk1NeKZm6ZS-ZP9Ap_oo7f2X",
            "47":"1M6EmdZBwdCbmh2q7Ydn8-zqksRcJPqXh",
            "48":"1QV6ZugazPh5KFJwBJPUoVc2ooqkbYY41",
            "49":"1WwMJhXeitziGJc3ZHRAdEOA_FdiRrfFd",
            "50":"1cMtrcDD7ylzv4stCtoD3PoXBF6aU5aAW",
            "51":"1crugEZ_o7yskfH21rhdaEBIAm8PL32g5",
            "52":"1d2sgWWBUW7eL75aXiY1U_7I0hxCAvEsM",
            "53":"1dLYnRnDkEjsd5UCw1aRwmNvqJywJ2Nz3",
            "54":"1fuCO1I348sp2OnmvACtWUA9SbizDwqcZ",
            "55":"1i0yy1K5OQKI7FG3Qj3q0WlZjEqCFcBOX",
            "56":"1mTslNaOy8e1GcqBmpiEoYI-UyzirZkq7",
            "57":"1oFyHglnuLr2rrM6SGERH1WijIei-M7ME",
            "58":"1rfnqcx_A7A6e5uR3ENYmYf_OcCHPNKOQ",
            "59":"1rrICWoZ0ijc9EX911XnZ1ZKi-qzYrdqJ",
            "60":"1va2GXCSO5QQIAphMX1xZJhVvKib_IheA",
            "61":"1y1rsGAuoY8jVgMy9pIkuwSXRIxjj0tOP",
            "62":"10B79DRbKnIvhNYaKh6pIyTnJ5Pne-DGn",
            "63":"11eqqzSruukRiWdslC9yP1XsMSQklQ9YI",
            "64":"1CAYbQITiHi05Gp_Qgrm9PrbgIzsU-oCY",
            "65":"1Ehg6-IPFFzTUOIWw49dc_5wc5nKxUrY7",
            "66":"1GOam3It-UPkZljHkdDnorfDM-N2n14NX",
            "67":"1NoB12_yQpO7bcKvfH-LvOL621a5VDQfh",
            "68":"1ONzLPkqBI6lP-oKTB62HM3-V8hgu2S7H",
            "69":"1OaePeFTcHHOjuiwEnv2YObZhSNtMjsHo",
            "70":"1PhnyabtpRD0tXWcJ0kr-UStPmUxBDcMd",
            "71":"1WRNle90xVA33XIhi0RZftE8CH2CRYcLn",
            "72":"1Y_AtHUsa-3Xe-d7ySulPdg_pf3sL3APD",
            "73":"1ZqDJ7D5DAQLh5wssXbAg4Cpt4tnXSVCZ",
            "74":"1aLz6yHNWTNHhtSxX92O98YKnbUTQdv3o",
            "75":"1iVTYXIvYPqU-tcd4f1xT9etnJLm66D7O",
            "76":"1m_Fv826uyPLcGzA9hiKIS_fHbIOSfly9",
            "77":"1p6TiLKsXGe6c4iR4GjlgSg-MS0tgyaoc",
            "78":"1qwUjRIO6DA-n6jfrejrO8CWhVDM-cQyR",
            "79":"1viSJa1kMFtkhXa_agZAZXhPOIoOXD6PR",
            "80":"1zygI9IgPPZVAzkKrH9cve6HJTXLNnCq2",
            "81":"1Pil_7rmjrxqJfy5Jj2f_F3hFBoWc2mGB",
            "82":"1DWSds2TGPJAz2qG0KI2HzLt9PObYdi7C",
            "83":"1ZIeXVKiwcei-5enpno-HroPFHy3jjqgn",
            "84":"1mAINSmHTj2vgy5B6Mb01dL113l9UXTev",
            "85":"1p11--OIlp3qawvGle59sAKMf5i8h02yk",
            "86":"1OI0EUZDaFUiDXzy6Rx5CEUQ6crl3fyp5",
            "87":"1pfndFp-Q-LTtvNgdf9VQbXajfKE4a42J",
            "88":"1FpTHXV1pu199B0WBVrLCoq6kkPdSUf-F",
            "89":"1xJTBO9bBt_j2Qf1IkChohUC7EcbSAI8Z",
            "90":"14mV-OEoF3tCqnVKroPgl2BFle6V8oFUH",
            "91":"1AbgA3awbNewUbsr2wTD8C4op9TrDbkI4",
            "92":"1BQXTllYsab2tqFFiIyKWqFUda76Tv8S2",
            "93":"1HFSf5Xd8ko_KaY7C30ZgAItS4xSImi4A",
            "94":"1K1y0CxgBjeyqwD5yb3aeffp6sJFNt0mG",
            "95":"1L1Mf6OaEJnL8LRdJPRXTF1-Ph79x5qXO",
            "96":"1PLqeofD0csgDzROZxFms3ayQ6MzTGbIl",
            "97":"1QChmB8HKAk-SJJXjWj6Q6fLeX44WXCXW",
            "98":"1RJWXBd6kuryraod9K_YASGlr1DsA9g6t",
            "99":"1Sc3ADlYc2Gi7m0guM515_N3VN_IP6I-0",
            "100":"1VPmT9Fh3YlN9dLh51V480OLYDoXK6Vfm",
            "101":"1X1TT4OHq4nnJtrNYspPc3fe2BhywDAAb",
            "102":"1cTpP3Y75TAqDXahSwiLpte896lSzDNgq",
            "103":"1hytPEqoDWqIibyZkygUv-LIVf1ld5jqQ",
            "104":"1j6v9rhg6qrL6S8zvUY0Ndgi1Mldz0McS",
            "105":"1r7YXDF3sueMggE1rPNxjNuX0l5kPcfoQ",
            "106":"1ubplySrlNCUanpplYyzCVE2YRjxIQP79",
            "107":"1w0eI27RD7MEr_qUIjA5FasC0_Lq00XxD",
            "108":"1xNKDUKQIMAlX03pNZFi6kUHww1uZeQQZ",
            "109":"107BsiIsfowUeYwLCUyzTMZkGNaSzkXKQ",
            "110":"11tPmJdnlmLUiN4cmbUX568n4-5dNtDHv",
            "111":"13nXHSvWcB6jDvhmK3fMwOGPbg1eGV4mz",
            "112":"1AGKbfoyS3HWm75bxkcS4LYYANYBxr0KO",
            "113":"1DLXkS-6TgwAGExBzuAjwZGwt_HeTjLKE",
            "114":"1Gvz55zAvusfeK-D1QLGY7aUqUIBuJiPd",
            "115":"1N1Fw3sn52r3PWcu3LxF4BuIVfLs8gf_f",
            "116":"1P8gkjUTtTUCh1uEspX3nxxhbWPw4batF",
            "117":"1R9vl1hCbBr2fNruW8aC37Sw1orSz8hxC",
            "118":"1Sj9855B3Em1T627Mied_G_b5ZO-QEeur",
            "119":"1Toj6p-7sALUtft7YpIdUTlWvYmvYLsw2",
            "120":"1YAfAcdzOAQMfcPhN0dpHPeaYumNDhSqg",
            "121":"1a1qUjOQWnOFOthsYK3BAvtHA3WuYeozY",
            "122":"1gtsnN6LaIy213YOg3q8AuVr-dJu5RGWc",
            "123":"1j1PCv-AOIZyag9vSxgApUYO3Kfrp5nQS",
            "124":"1p_tBw4c9Efb-URluyBG0_tq0UXyU_rDk",
            "125":"1tGchaaXnUS2K0ufZK1Wmg_fU5rw8FBq8",
            "126":"1uYkJF1E4rr6KQyNIyc1neBp-B6iR5nzk",
            "127":"13ub22_RY-AU73ReZEhs_yUSvvBoE7DEW",
            "128":"19R2fSkMsC6ZfX6p2aHDpeaCxG6YuEFqE",
            "129":"1CZbtdUMi8onSBMi5S1jpBg3tMYp6qHJt",
            "130":"1ChkwPJWSfSJ5t374VmPMlX8NPALTkd2R",
            "131":"1DrALjP8rCgWcRxHdhBuSa1SRtqALgnAm",
            "132":"1KR1f2cZfpXsVy_QTndtpBuChssLDV2mF",
            "133":"1UDuaf8c_Gx7liWnG3mw7xZmpeR9Pikku",
            "134":"1VVNParz_TeBVcwNFFDbWb3IVjfa3bF9M",
            "135":"1WVayU4Tn1OUABzZud3B-zU4SsAizzVBn",
            "136":"1Y6SNCzczIpr4n0hNuPwHqYwQzj3HzX_L",
            "137":"1dm-reD6Jr0Vl9RG1gKIdd2tbOS2i9NCp",
            "138":"1iu71ppHWXYClavPPpBnfAbEVVlgpKrnR",
            "139":"1k_toK3NasQmZMj1Q4q6N1pAadTE4U5ch",
            "140":"1kxoTpf_bNWcCunA8VgK4bCAEkddsKzcN",
            "141":"1qfnmlQE46H28mXnxtk0HEQ125J4fhrY9",
            "142":"1tFksBCtU4K4yhlA5cca6_nHFQgOOj2Ps",
            "143":"1wIHmm4Ez7h5zZgET90YnvLBmee14uJoQ",
            "144":"1xsgCvwINRqaHoWq9nuONveGbNCZi8CDt",
            "145":"1zQFRpIUwmQzwlEZAE-y7vvGi8L60LZxe",
            "146":"13liAJpoTb7ylKe_rT6VDvyQsaJ4KJJrx",
            "147":"18o2vx1-iLXCxkDYw5YveZwwfn0TGrraN",
            "148":"1GCVK0KpEgZ49VszAJZZXvziMvsaTSrxq",
            "149":"1KpTaXn_K2MFgYC4ddocMqaZcaf3XHYQM",
            "150":"1MC77iCNDLFEcMWuk7RvXl2SCSN9QaIQG",
            "151":"1NxEF2ytrujKdrkXqm7HXrRPAgM-ATcOD",
            "152":"1OJcUTHVc4Zld8aH7p6z49YKtlZhCsWdN",
            "153":"1RIE6LiK3VnJ3mD6x2-KmO1-ZgaKm0-9d",
            "154":"1WGsQoFJOyY66Da-7e59TmjnVIeiZ7s_v",
            "155":"1XP557DuqFIgQjDvQzboVP31TLF5gq_Bo",
            "156":"1Xj4rX3vPzo851_5Lv4cwJMAFgE-C_Xrh",
            "157":"1YNGdi6roWLU1Bw7PFWQ2ZMZhbV15mHQi",
            "158":"1bQbcj9_Rxj9mKgMBKGd-PpjTnCwZD6Gd",
            "159":"1dWl07KxKGpc9sQNTM1iTpuER7A4iWyGR",
            "160":"1dqSSgH0I5b4hbeCIVwYt0uzp7mABkEWC",
            "161":"1ep9GTuGg_9mzgOglwm2tiOnYzgUeq0RH",
            "162":"1flEHt74jzCw_lXxHgeW2U40xZxR8OjC8",
            "163":"1ktiBhPlzHbQob_zK-XGb9OmzWxrktT6w",
            "164":"1tivYTxEWUsGTIasoE7xdoZklJxhqV7_M",
            "165":"12dG7N46rWAPoUmD6yOFmWh_g3v4WfVFX",
            "166":"17QmouyxiDi4J0oH9TSri1jGJrx6F6IkQ",
            "167":"192K530Qh_fIak5S06FAUoXd_g-by5T9J",
            "168":"1CHsBHFhFA805kkFiKid8Ef4c9Ii7wyvl",
            "169":"1DlsImEcwdmJt1Fs4rgpDx1l2O7zKzlaj",
            "170":"1G-W6jpDRkO1HAr-gymbS8WT5RZdoIdy3",
            "171":"1HENFVG0BRYmc2GXd4EbIXDsvbasLDSmb",
            "172":"1MTAEPYNB6hTXmQxpuqmqFTDrCTyaE77Q",
            "173":"1XRl7_QLKnl2CLa6S6xyhEgszryTnG4vA",
            "174":"1bIdPoJFj2UThRK8JBLlf2OrMX6Fdhh3h",
            "175":"1geUMxrtFEfJ7UWi_7EHsXsBF73jI6Jw_",
            "176":"1hM0vRhbokjhmHHnOnqJgr34YJI2jfFq-",
            "177":"1hMFrVeomHJHnI6OAU67eqcTrWtVTfsDe",
            "178":"1nNDLmDxJQEG3aPmABx18GDnLrOZf6xYL",
            "179":"1nWA1ghokcXYANAKeeuJPrkx0uSgTzzH5",
            "180":"1qDboIspUcHmOILc9ny7OOgi6Avp9TZCm",
            "181":"1qR5_86OE6YScIrry4nhYLcAMwu65FMBW",
            "182":"1uWoDu5YIdc8wpwDTI-BsO8qP_mD5mfOg",
            "183":"1wKF9_9484jXF3XqIrFVBjJUZqXntNEBx",
            "184":"1wSpiJUtPqOAZx3AW-pC5xJeZH6axu_pB",
            "185":"1UysHVKeWPG68HJiE7S0iv6OVjJKYs59g",
            "186":"1-B--kdgj-9l8iTzXAGQp3Gp7cVc0xyH2",
            "187":"1-RoOoYXYpXkWgW0usYnuso-5weytSTdl",
            "188":"11ECcfSA6G5WLSRTAbce8MMkUyU7jwSkt",
            "189":"15nK_PzBTiwcN6VTlOff8keu-9qiUqPfR",
            "190":"16B05vwQUzHnfRKhz1bzo7vlNSnWGjyOS",
            "191":"1ABrfl3Ch9_4vCyOtykigD5Y7tpJolqwS",
            "192":"1CF0hko1_UX_8oXp-fEvu6v4OyFYsXdJb",
            "193":"1MS0HJ4X4OhLdfTxcp9uhQqG201OF9vZI",
            "194":"1Mt04j7_EgQEuEom-NARtTv_WnAPF7qjz",
            "195":"1SiDKS7GGAncOUdqjuDFrN5Oy5ORuwzMr",
            "196":"1USNV1fYNmE_kRT7ZZIknv7qKrgq6M8hB",
            "197":"1bhbeXtNtfenZ531YXhsIPmwel9NwC3O9",
            "198":"1h6C_rLgugy8fV9LapdTjrM2GimnYtUsR",
            "199":"1kiCLKryRC27W0IrFZJb2aMNXWiXCPegR",
            "200":"1qBgFephKWe8RbLjE_g0dVFcUfKW46apT",
            "201":"1sedx-OUZe_aCd2ojPiRrbNxbxCaLG8Km",
            "202":"1usTTkTuogwzuFvUG-8APOXe0K6Wqbga7",
            "203":"1wPa1k6JwkBvWqnGPm6lAlVgNH-8Llqwj",
            "204":"1wU9j60dddnxgAnYUa3BvRogv_4hQSCzF",
            "205":"1x1Ni3yMpEye_HkJ-LV3o13lcMzo0wVx-",
            "206":"1FN5Bnu7Xozfc5J_paGiUqRocmiJirLJ0",
            "207":"1FmjBDMIVQ96ezN6XCI_pZuesCrA59sMY",
            "208":"1GTZmEBD_eokRiRbnDz-1dvfpulqPvEdW",
            "209":"1OeyAjZ4xPmZG6IB6Lf4fhk7M4i6FE3pp",
            "210":"1X5AsFFKeTcIiEot_akfFhOHE4xN5Pu99",
            "211":"1_qvV-_Xt-cKdflCKPUjC-MNvaLygxZ-o",
            "212":"1ax7jKiDiSNHtoq9NH6DYC82TXHfnPOTb",
            "213":"1bgIRRMFuGSJBDmZD6wR40RgQuG1SdCNc",
            "214":"1bgM9ee2-0Z5grlmyawcOF4qjv8jqcXmq",
            "215":"1bp7z3U6ka-8QHL2_fAhFkP9Eimzdypbo",
            "216":"1fFshNrSn3jhtM6BviqRfF7WCYX03zFT-",
            "217":"1h7zjpmbavZdCp1QNgnxe7Q2Gcm9RTxLS",
            "218":"1hYKdsfGWZfTd3ZlonBks_BPLko23WJxx",
            "219":"1k3kj102x7PUt0sirpiLjWuvFabSSWnBC",
            "220":"1k7dyvmJYY_XnyDWaicORVYHrCPE-pCrK",
            "221":"1mi6R7OJG4HutfqR-nKy14jsgqrKnjSOG",
            "222":"1oMvXkNoOvziosl88h3UlMj5wVtWK-ENx",
            "223":"1q7_bnTMktArLYIkp6SBM925xsw15h77M",
            "224":"1w63mtXmycOA00U8LgPHzqoreVqmSoYnE",
            "225":"1zSb6c09bDY67wq_2YWVZiw_WGzTEAi2j",
            "226":"13-Ui1ga-fTWX-YO8Yl9aiq67i3W__S4A",
            "227":"13adke9D9_T74nTrj-1ynTJ3FPBshrhFM",
            "228":"15Fvth3PM1srF7icpQ0o1a58wE87YZ_7r",
            "229":"17XSI2iX_i8UJwIr0r938P5S33wUTyMSF",
            "230":"18fL-01ATSzOrdh6ANUFSzJOgkC8NHgjr",
            "231":"1AsclSQUhvDzVCCeQox7wiYuyWwYVHg9e",
            "232":"1D3_OCI-wzujCyXD-wxqvkFD-E_7splDw",
            "233":"1ENg6Wu-Ddjz7Vv7OB_JuXGIvsxHxOgFj",
            "234":"1F2MTyH6XYgV8S9mHnbA7aQbfrXy_nnVk",
            "235":"1GTYWYVHgP3tMjvB_yJSo40h8jDxJFe3M",
            "236":"1HUrzhTzrRha1y-nrPDbYesTuFM19bdk6",
            "237":"1KsjAocDpEUALPxcSfDJ15Hv4NhTztMk5",
            "238":"1L-GPOD9b943Sj1L7K0Jx2skhHJ8s62F4",
            "239":"1SzlEwBfTHBdL4R_ym_VrDOgxjhL3dWge",
            "240":"1aFgdw1h5RAVojyHDoUewr8Kp-uYniVLS",
            "241":"1bq7UnIp9fBlxwwI85bZiAtZl6dFqY89t",
            "242":"1f5mYTP5mb-OnS6xjo3HmDHYfXkD2jjxd",
            "243":"1iOg_OFzfB1GkioveF-TqrqAQMW-VNiNc",
            "244":"1mdhJkpJ0djlzcqkuVyl9sGccsfmm7rRH",
            "245":"1zWbx6ISRteuunWWYnhoCf8V_aNdOE848",
            "246":"1-GMt4bUs7mNPWu8gFWAdNJR7096N2BgG",
            "247":"1C07cx44Z1Eag9fWL8Ww8xevt1KCh-CXa",
            "248":"1HkP7ctRJ0SQhCBuZ197aWUr7JzJ_EtLP",
            "249":"1HnLavGBa_2MFUw3WbDvCRytC4aBvdVuZ",
            "250":"1Ln2xqul49q4Rq1DiT7oZk_sMA2oOPvYv",
            "251":"1OBuzuHA4n8DUIC0Hi1h-jETE--9e5d-Q",
            "252":"1S53CuzSWtcSfVbNH5WMngW2Q5bM1YnCK",
            "253":"1T8a-uxReTCv25lf6BZ4UM1SYbertxtKU",
            "254":"1TSlJoKQ72dFvWXyJt144UIdtlbVxCDPh",
            "255":"1WdZ2YQvKpmIao36ZRdY3fTa9wlfvOQj4",
            "256":"1_v-Iq9BseGRfZJnpT6g4zhF-rYGSomSx",
            "257":"1hVTRmFyZ5QfIbFMr9v9s7yA0Ow_FHCn_",
            "258":"1m4rhTtfOpwybv1O7ggc2N4qPYAzMXfAx",
            "259":"1nsXlYqrwhzY8Lvop_j4LNW0TJyQnGjEM",
            "260":"1qvFU1wwukDf7oxnpUM7ePPNnn-1ENNtN",
            "261":"1rOrlEgW6cZGF3tEbAI5A82ZmL1TCi0MW",
            "262":"1sBi4wWcFAkNWmAhhHngoR0hVuad78euy",
            "263":"1tuHA_FIAZAfKEiFUiowaJTFRWWMDCqJG",
            "264":"1xwulyjwTJXU78Oxi0h6E_KaywGyTE1Pv",
            "265":"192Oz7CpN7JHoKYsOngZLvdZR-z6eZY-a",
            "266":"1HqqdMeQODanaQClfOBafD5kIx5u_AHBf",
            "267":"1Mf6h2Ts-MURO_x0P2a8m3auRYI6QsVpb",
            "268":"1NMSUGAH1HMYw0FrmAjU1st5YnIeibmFx",
            "269":"1Syk-FNkA-QtIITSlz4LUkrqnPw-VUy1j",
            "270":"1_e9xfaPUEnAQAOIHpIFM_dm2piayNz45",
            "271":"1hyhed5KaVyBo39c4Jgf1yRoPcEk0jhhZ",
        },
        
        WARU:{
            "1":"1diEUrW1IsJY4p_0pe12hUxdFV9hSyWVv",
            "2":"1CKrC7RVQCecsyUmtsP_Rx7LOvJMBHhXR",
            "3":"1VNVq7e_HhTnm319FsOd5H-ZMvzQ_5k0p",
            "4":"1XeOLTI2kUiDWADRXdflOXKb4CV52BImm",
            "5":"114-XF0sC9BFmVeUMOWfy81fMO2FRgFXj",
            "6":"11AUSsRm7MZ46vFvUNISuKv2wD_Y-4E1A",
            "7":"12_swTF3zcxoMG2WsHJzqfKfT61PgIZqn",
            "8":"141cqxQpw7RAy7hl6sTuWRXpk8MYwYjUl",
            "9":"14xB_8GuFdtdGPsdhyqBOThz1xaxNUqsZ",
            "10":"18HQfeNRpf1VijnRmb9V5vkiSMzKwm0EO",
            "11":"19ggMVtjNy7nKaIPEp5iiG7JS9xkQ0Fi4",
            "12":"1ASlaA4Xqz6zoa19fMNlMcv6VS6nUPptc",
            "13":"1BMxnQF8oLfl77bY24mwrsGvhPE7bRD3E",
            "14":"1C53BUiQMrsWuXfYLM9nwGGpvas2_zf5i",
            "15":"1DEzBg6eo-mctkwcnKtErLGPzbLwHeICL",
            "16":"1EpCNai6lKzlC7LvIDqqfSwUd55ZCkK_W",
            "17":"1GIzKgaLu4USAIlFFPgEgQDXqsCymNhzE",
            "18":"1ItW2FZrXOhRj5ZgSmh21rhQECjAZFFUt",
            "19":"1LEYArr6Pj51R-4QPL5wmtxlsl-EKE3RH",
            "20":"1NrjCyq-p_JpCGTZoyp2uN9NTLUf6uYLZ",
            "21":"1T-SNN_IVUTWhQNlux3jhsaU4oSSbpOgV",
            "22":"1bK5V7s8JSQr3wUfPXrnNkxMCLel1_j6S",
            "23":"1cUFUiTRVNzalo9sMPbzeORTU6q7RaFSG",
            "24":"1fI9lkb1oAp1PsRAVTGg9xA2eAch2ZyBz",
            "25":"1gy0ELeYE2q1KWwj5STUle7BOiWrKcUhB",
            "26":"1hKF3au9s68UEXCWJcSZXxXJBfwVtcQqr",
            "27":"1i4B1KvOX24_heOaXBWUXO1KuwvWzY28Q",
            "28":"1iXomMSmfWwg07z8eJcV4TvXFetq7vR32",
            "29":"1inPTdOcrOXNzbtVMeao1H9R0RyYRXGfD",
            "30":"1mOUHrrQzIqcFiFyWaxwdXSVfopre4ZE6",
            "31":"1mWKFrF6YXFRk5zex6498GzBtbLX_Ln5_",
            "32":"1oYPKVI3taGLq_6Bcco8toSsxxeofx1dd",
            "33":"1qQk2M8fgiWbDQmqAgiABJ528T5RgAaPi",
            "34":"1r__THKc8qLHWWDMQFwh47LoZUzZ2eE0R",
            "35":"1tR78AmxPIlPXUrdaLwdzbZax2O3BGsQd",
            "36":"1uCoObvdSa5ST03viMHLVKzWRoGnrVSec",
            "37":"1ugsWgdjZPJicUs3u902WgKq8dO-xs-i-",
            "38":"1w6J3lrx3-gZopdHLhUo_DrIJGHtbROEt",
            "39":"1ycdpvGyN32yAP5iuoHaPUNUfV6H-Hhzm",
            "40":"1yefna7cr1XxOKOfU9xyf4hPIy3c_W2oq",
            "41":"11BukcPkUBDJyBs8WQveMyZkdeua6-9fn",
            "42":"1AfWZK6vg1wHD5P-qH9FoDrzbGLT_-sWI",
            "43":"1ClAkxz5yXDk0JsylJgwVulLpnrArUPsv",
            "44":"1EW75H1tLoLVcFULurntzdTG1Zu2SRwui",
            "45":"1EcPbRVF84MeynAjhZYaercsw4kqkMg5x",
            "46":"1Evciu3ybFmek41SyKeUSxsDN8A0k18LO",
            "47":"1Hgb-_EY4zQpObwseBnRWpF1DDYmrmTh6",
            "48":"1KOIRkh86BwTyLufiCUCNuGIdhkTjGuet",
            "49":"1Kq2SCm824q4mf4U2R7PuaFAXvE5a5iKa",
            "50":"1LSsTRDsEFAayUmQNimsV69T7QBtmWWOg",
            "51":"1NLmPo0r80i9DoepvVw80J3wZgUNz_b_S",
            "52":"1NrxHtPNNf2bMQX6I4IRjFfVuRz2GDJ8T",
            "53":"1OKqx77KkU8ZeS0XjZOAheBx2nqbByfYq",
            "54":"1PyciHVmtBF19vpNqLYelry0h6yArJkhH",
            "55":"1QPBPHrA3HN1G6fWPnel2ZTqRuWaPFICx",
            "56":"1UON59tDHilkgYqkcGCJnrMKBTiJDX-Tc",
            "57":"1VlojARFpHzcNcKrLS6bANC-_-OglHRAT",
            "58":"1Y8cguvm0q7e9gGF4zL3cgQ9eGsqJ2lpG",
            "59":"1cRTeCIGlYLMqeLm7C-Plkcz-3B__zOA_",
            "60":"1gGk6TTgtcrRTRBigl6Dm3hd3yR9qgk8v",
            "61":"1hHVne-xcnhvbTs4Pi_7cNkIWKeZWDQXj",
            "62":"1l3w3Im5XT4KwqJ60PtZyNHLOkDjyxWD8",
            "63":"1lOdjaC421DUB_wbbXbajNtSS2um29-NA",
            "64":"1pHNBMH6NLH5PifewYanXMoD33lJ4NPDi",
            "65":"1pNeMPAbjkwpox4ZemGzo1PHrn0JKChXl",
            "66":"1qf82qc5ivYlhgEb1H5VMmyX1lGW7ke9s",
            "67":"1rMhtpkmDXY8OxW5dxyYniFQ3acQlJWxW",
            "68":"1tg_u-aj0BdAA3qOSNuqFRELCUERpHGd1",
            "69":"1uePBDVg5RkymYOJ0cZWUqlS0E9q_M7Vy",
            "70":"1wQ6FHVwBHkuH3wecBx_0vtx8oHjQGwvU",
            "71":"1widFelsboqOZrJSPj0kzzNWzc2JiaZlL",
            "72":"1ybWYx8C4YSkVRJD7-JcevMxahAy-7OD5",
            "73":"14JG4xA0_CxeF79J1tMzwAV55ysARktJx",
            "74":"1C2yo7elAFoGfp3xkEk_8xLIKYiQDCCB1",
            "75":"1CY5V3OXKn4Pcnl7XSTdUlg8C302kBLfZ",
            "76":"1D_j3UlhZ2y5MlZB7Hmd0HW6l4-h-ZzFH",
            "77":"1Fr_WeCznr7XZRP74SpivlMCS7qpf9Vtg",
            "78":"1KyaOzbEeV9Cy0NKhgrqGOW6A5rm22X2p",
            "79":"1L3UqUchSVxoQiPy6eKKca5L86H_1jr0h",
            "80":"1OAOmR3Apd_fmKGjfRtngxyL0iEyvgB7F",
            "81":"1OC3wLmriP6x3Eq4sXrHBGWUbKT9NIRt3",
            "82":"1SAlcUW_aWxFWvTenA_kY_j-cn1B2ne0S",
            "83":"1W53DngyAbZOSLdaltbOK9fJVzDmjGqUL",
            "84":"1ZYVUa7Ubyru52rIf6y8aORMV8PP_E_05",
            "85":"1Zc-PfvUJDh1sZ6abbnLqpUph6oyEmFDt",
            "86":"1c2xb5pMScMa7ERFBU_2fDQri9IryOMIL",
            "87":"1chxP3eIsMSZfn-MztXTVMmQDjWRfuWpA",
            "88":"1dK5Bv6VJO9XOn5rGaur4qDDyDtAGuZ6m",
            "89":"1ghq9UkuRj-fPB7hfgCDKclTBKkkw9DER",
            "90":"1goIDoiTRKCOQUtZoWLWFLK80n7nn_gvS",
            "91":"1ijhjktywxoFzPjMyVrPJOlFU73b-MQ0m",
            "92":"1jKPqLNKhZ11eD3-9CRZwRbt5jvH_lz8S",
            "93":"1mZXS6Jltsbfj2d2Zp0cAXiNiKP_U0ynS",
            "94":"1mprcslGFS8vwrxqXZjQXjaXHKDMdzHT2",
            "95":"1rdKbMo33mfGlIJc9BryFmoauPdrVpsSw",
            "96":"1t5drzSZD47LuT4cX0CXvxVSNRAbX1VmK",
            "97":"1tqAVzkVGmRcBmNgA0QtF0UobXf_mxFy9",
            "98":"1v6rnOymUeqBkRLLPptH2WFOjMyC60Wqm",
            "99":"1vhcgZwve7ET0PpM7CmUNyqHyiQ0LVt-O",
            "100":"1wfLTw04NOFl47Jci3PKq5HUbBpXG4KkA",
            "101":"1BK10vQ_GY1eLZF_DSHkUh7ncIKRUwHT3",
            "102":"14OVAqf1zk0aFAvMiK8eovcRBPSEZp0zw",
            "103":"15GHVqvewbY_lVYoamWv33-vSAsRH6w-F",
            "104":"16pvZjmAbvBnZKbKzmNmJpqQ3Ofca1q9-",
            "105":"170nKWemxAANi59w1YQ6wm148k2YZVXz_",
            "106":"17utu_ftec_7aTp4_qilCQKV_tRQR-uvz",
            "107":"17w678xY0-XulUuOF5CAmznZN32HeKfJB",
            "108":"19Dpd9crKzdGgwSrfnKC0JpSJIH_p2Lhm",
            "109":"19Jil_IuiN39oYwkNXvI77y5nNirVsHnv",
            "110":"19e16uXOsNqf98OFTMbfq2Oigb90QJTg6",
            "111":"1LQHd2DMxun3ddRY5rbcp9RmfORB8hdIR",
            "112":"1M912TPGc5TqFzKLrQ4Y1_TBQ2Bsy4MbV",
            "113":"1Ov4mO_KNKGzkd8Tbcpx-z0EpSYr9eKAC",
            "114":"1QG6jdeYP_BI5YGPBwmOPQWMJiPsnoGjB",
            "115":"1RsQ-z4glOiNwoU5WFDOFKqDAnTeJwDzU",
            "116":"1SEl52tmFGIicapAwCWmwHjbK4jjv4nqD",
            "117":"1VYDUyHG8kAxqkVJ4lC6Vd0ajvWxuuLy6",
            "118":"1Y73E53bT4LNZ90rmMdrISEpzfIE6QsSG",
            "119":"1e2JiG478uzKZbfpIHRk7heTsxWmzGMCW",
            "120":"1i5mUncLTrXo9zSvMSgINrG7akzgjbp0R",
            "121":"1okv9Wey2ChcLcfpp22cBtyOJRU0iL8Mj",
            "122":"1sH7I_XLxdYXLX9fzma4XssepbJYIAgp9",
            "123":"1vOQYKzv9uprKbNy-7906rkHMX4BorYfZ",
            "124":"1-LIAspr7U--c83OnITT-TQYY7_gtkrMg",
            "125":"1-QC93uDs4V2T-hmMnmAj-16DPrspoZzR",
            "126":"1-iQwuUyXIZ81pQicCTnnqNsv9keb3B3p",
            "127":"10aoLD98CNjpeFh3-NwZB9Bui9y_cWvlD",
            "128":"12kaBHvEvcgXd5xWo6cZSd7Uo6YZjXxn9",
            "129":"12zWm0TrM1fa05uS2kJHsQKUGzpsKzObc",
            "130":"14rllWzZO_8KOODGG-Kgk7tkzd0kfjG4P",
            "131":"1EoMwnzKg-9OLnyIzQ2XQ_DyxLiXEvrLr",
            "132":"1HwlWqHeC_ERXJR9VlCJrdZ52g_cX-2P6",
            "133":"1IBK5olRq0E-c3GNmoIdHhBq5Llw_lc6Y",
            "134":"1KLehR2xuXG2ww-ILjQw3yyCKXeX2Dkb5",
            "135":"1RbAiemegZd892wwDeGpZ5b0TTM5h1fcL",
            "136":"1SmLTFf4ZBnK1-1u17FXsZYH03t_2CM4S",
            "137":"1WX_6nzjkJ-PKCmyG-2L4pmrN9yOAvVAt",
            "138":"1YXNekmj4oOkm9EVPWQD7LSIMKvwNwJ4x",
            "139":"1aKSkQ0-TAgEzKCZQkOldLY_cNXnnAW-5",
            "140":"1bgeO4CGM-WAe3gBa6trBWNrF4csO2tXl",
            "141":"1cMZz1OrfljAIR0E5URdwMaK5diCT_OKn",
            "142":"1ls77SiL3nOeJhDRmOyXzK5H-s7Ot5xTt",
            "143":"1ms1jzyb1w2kabr6Yn6pjMbFkY1_q1Zqs",
            "144":"1ncETg4m80530UUl1wBGPAQl60l2Sgjof",
            "145":"1qleIhn0QkgLh1k1Vy8QEX4vv_KDyNfwM",
            "146":"1rs-KXPsXHL3IQFk-ifjANEKRsEweoaZY",
            "147":"1tOfw7AW8PjN4VEcTMCsIEcQ0Mp9SNxsQ",
            "148":"1wEhj78X0nRSABICaHX4jAXDkvJV4E1RM",
            "149":"1A8M27LRZ3A9xkphYCDOU4EAI0q1FMG61",
            "150":"1A_Xts1hc8w-9YeOLYych6bitmM25tPUt",
            "151":"1Akg96WS8MmbwDlQohO9tkJZ8ZD0EMpRl",
            "152":"1Elkmd6WgjplQt8LBQxH-MorZU3Mtif5F",
            "153":"1KOSNoS9bAeOOAeIgu-scJF9KnbzJP-_5",
            "154":"1KfLb8PnipevGKEFgG98dOmbuEqxWXdNH",
            "155":"1LpOuYlgwzGaLe9zLzHg_H-Q9bilc4XDM",
            "156":"1VtIkkkV6JuWtUo-jmarpOEcHnWYXL4mp",
            "157":"1ZDDJisZonC4BXP7wEmHawjTwabYeZTmb",
            "158":"1Za4wKnmxwudLjRPDQkGYmHdr5sFsiZL6",
            "159":"1fLuUAtfod8bfqzy6X8J-jntDvr9KHRsh",
            "160":"1g-AGdeGb13zppmMI0X4xrbWLIPuCp13E",
            "161":"1mwylD9WgrbNB_QECVVQn4Rl7ChtxLmqI",
            "162":"1mxYvzQpen7OkJG9fjwGXln6h9VE8NpSu",
            "163":"1nae-8y4k4FW-UoOyXk78VLyYCPJUNu2b",
            "164":"1nsXIUMBavCt1eB3-Ph7lnpqOR_8XBf1u",
            "165":"1om84F116owVrWRzkJEOGPqTR2wJABpKS",
            "166":"1oyPmVC5q5-nYg-uJJrhZx0pMFsdTlLlA",
            "167":"1qKUDxtpYSOLze8Bor647PHwPmoqke4iJ",
            "168":"1r30-11vwZM1dz_zohCEicEPCRkv0Ybs-",
            "169":"1xwXq7_lR-ahCq2A_5CxRRSAQWAo7H0C7",
            "170":"11fmRn1wUzt-hvbzh36dXVAhxJgD-TuSo",
            "171":"14tt9_q2I9FKMH8_hxaXz5Wjhr_RfwXzf",
            "172":"15iyYmnGbBSzZgCX-fXMufC4KZaYpsJM-",
            "173":"19qNTs-DilpHRdqLHnw7GihhiwGH85PnF",
            "174":"1E1CUQpRRLXMBGRa3x7qaD4sCFxaUhJMU",
            "175":"1IyEN985quIABfD3ls_EN9ANNGbbpIzy0",
            "176":"1NGenhdDTM2m5dNjDwDEk8GSHgelCnPvu",
            "177":"1OfQHSauLG_8U5r_SrLqOCY0VMEE4qwvr",
            "178":"1XvbdNXgptN5TNX_oQDobzukC3sTe_wap",
            "179":"1eq8lTKJ3hlXaVveF6Ohj3rCjM-wIUTWN",
            "180":"1fieX-c27o10NuQLvW4fq2lPNUCD2zv7D",
            "181":"1fjrZLFfm5uPzzYqysn9dRibEfFDke8f4",
            "182":"1lu5r8t3OnGrmzYiGo29An4TnKM5tRJw5",
            "183":"1paBQvbVh8eCF9h-SOgrwIjxpy7TykKOz",
            "184":"1scRit11K6vX5fnGCyG2Dtp0WwhUpmvxB",
            "185":"1xhiAzajYORwttHbQSPLH11FbzJkSBUWY",
            "186":"1zjAkJazB89htom_9pQCZbXCu0MwsfFxW",
            "187":"16GThgsmcnYAXRZ--mllhOgguvtTODoJq",
            "188":"16pNnvt4KgCGPdkTeOVEDnUxZCmsPQzOj",
            "189":"1ANpbYrqDNxVuwyJzrnDHCbsrY2QqjCW6",
            "190":"1KadoxjmUn1Al6RERjVr1gW6SA0EwbQk_",
            "191":"1QuP-lG07jG-6F84laELIoUdFxALBWeyN",
            "192":"1TzNY90ImH6tWSDdB5d80p1gDLfyrN_Kq",
            "193":"1Xd9wWHh5AZ-OZnf1nxcED3vErsL1u7XT",
            "194":"1_NtvVln-VpLGRkuy4em6hlnAD__1M2D-",
            "195":"1gDSEq25hOWxi7an1VKK0JQaYHMvp-7Zc",
            "196":"1lQD2AkO4rzTFcY_8QOpsen9riTAFQPXq",
            "197":"1s4eXd7Xf3nuunsooaUbE3qv-W3OY1kuF",
            "198":"1tIyOq8Djkqy7cHpxR6G-vanj0ErA7xII",
            "199":"1v5q1iI_KKdfnPAWmHA4iJ0_7rM8RbWk_",
        },
        
        KALISOGO:{
            "1":"1TiiG0adBN8TF30-35XYDtXuvhfHfSu03",
            "2":"1WC_MVLxEwtIcxY8ULGZ78iIBA1i-s6QC",
            "3":"1gahR86aA3_Yq9pDowO_D2lubcBoTuzAj",
            "4":"1dS8iHeXE_ocPYwBIhWBJNdm8xh26EthH",
            "5":"1j5zJCCfeuyCadEIn-H7iU7uX771la3tC",
            "6":"13-Xkn98cZAQRmP4eRNwiNnRIsPEsm8Bo",
            "7":"134O_2Kr0hPcQ6kgN6-OazDkCtp6xvUOO",
            "8":"1C6PqNIVHNaUU7pIfSsQD-6Zza05C2I5i",
            "9":"1CyEPYi01jVVw-WaFUqQKldyruCxc9P1i",
            "10":"1FKzu-6OrTtSxBOIFXrsqKmCZEvEhXRt5",
            "11":"1JRlQ0sC0O8bTT9b5L-ZitM_z4k4gNopX",
            "12":"1Kqbk9N_7XDTJYlE1Y64mj2cETncJGHqT",
            "13":"1SNA3amWSNMFxpato0yogKdHGp2LeyqvG",
            "14":"1WgIM_cAruQA-EYyGTDlgLqW8PNluvBid",
            "15":"1ZtyLNhOXkbIBxTvKRJCmt42z0biplYdR",
            "16":"1jknaxqEoq3nRrOVB6uGeQe06NqdDWIw1",
            "17":"1khSTefrDfWn8oACNgLPr_5ONEfTJptzj",
            "18":"1q-PSUBaokz2BlKEuNt5A8qqwJr5VJu3c",
            "19":"1r546mYbPV-p6s16TLrVqqcuVV0JKdbSx",
            "20":"1wBUOtyxOdSbaJ684zgSgUlSl9BgqlOOT",
            "21":"1zRBhAseyFOFPI9ek1c6tgyVKKR_EM8QX",
            "22":"10s6HRUiAWoZ_9ccv1lZ3H2RkngHR6WkM",
            "23":"19OHa56vA1RP6aqQ5mQWxq5BmLNUo-Dey",
            "24":"1Aps75IG-fr-QW5K5R8lP5Og5oBNZ9Kf1",
            "25":"1EgVCCHfXwqu68l5LJ63a15xaYSYm0nke",
            "26":"1I5nr4BDP7qKQ1HjGO_LSQdFOeaF7H00d",
            "27":"1IrrU3t94iOmT1GKOIWW-SR-Oag3T1hHi",
            "28":"1KJaQIJT5CYJJDqxZknhC9eMvD1oPwHVc",
            "29":"1NOMYKnn-EC7I-IaO95dUCBT3L2tMrhu-",
            "30":"1PVsf-aMWw26hgYfCTJwSjJP8qpYIWEX4",
            "31":"1PaVBl2MC9BTCi9ZcIPhv37M3lTKUZHaU",
            "32":"1RZLaIokTaZophHe0Z6Ci01N2gnlq-Dl2",
            "33":"1XIRfGZlU4Zj-swACpPMwcD3CKQA_Fj2B",
            "34":"1aIb2IkZC3iA5XtiMTj2MTzmx7RcBdabi",
            "35":"1cGtVhxx27pqBabiahjw3JWcq0jySu3o_",
            "36":"1dh2x9XPUObShGo3QlvS3SNPc-6I0rDUY",
            "37":"1e52zfE2cwLfCdZf2q6ji4chtvcHJaCaz",
            "38":"1iMEfI5GxyuXi6f9q0OjYoUE2FybAkIXQ",
            "39":"1jP11g1aKWoKErrYa-rp9izJe6CTA4kfZ",
            "40":"1n6HQGNBRisjlwluKgT4MBArff2uqe70e",
            "41":"1pX9dWwL4cO5SwW5_ca-cGTT3ZZIJkJIu",
            "42":"1rqIa6QQmTiOyE138ZA-A5UWikeMRS1dM",
            "43":"1tJuItMSQluOKZIHeThq8tmn4IrCJcNsW",
            "44":"1tV8nUY-zp2m3PW8PU4CMxwWY2z7OzC2a",
            "45":"102PUTJcypvrfGGuLDCCdwSK_d_YnF1wM",
            "46":"19M2iPVco5yTcbXjAYCOZAPETtRi45X9I",
            "47":"19P0IuwhpDODWImqqle7PzebAF2sh0Fnt",
            "48":"1EGsIp21TnWhsYpCBduEtgXvV8vcIZZCN",
            "49":"1FgZE61yTQviMMzxmokSr7bprFCtD67sh",
            "50":"1JJcysQtE_oeDE-iuUBqWv6KJHaGpepCn",
            "51":"1KXr8VyE-bqjfcBUjbL3fbmcExQ3m56UW",
            "52":"1KxcY_zwO-nmcyFDX_4vWLNPjUFTGYDvL",
            "53":"1S6K5IlqFtLAkeLGl-1Zkd2KNGSXHxgCE",
            "54":"1SL8joUjfWSdXLiM0au5q7oDxezmCFuAF",
            "55":"1WcuF5mSJMngxDv6o5BVzUur0cY3bd9Za",
            "56":"1_6QrVanN2aeV8arTbuhFZliu4hyDfhh8",
            "57":"1bl_gHMGuySkiCirPuc6Oo4PbXSr8ogCb",
            "58":"1bwHpDUa121IpPZui01Chss9l78jESlEH",
            "59":"1cPitewTILYgADW_weu5-YIFe0zgwIfmt",
            "60":"1cy9bKmXFfg-AK8cWOhx0BjlZrS5-Hsi_",
            "61":"1e_lE1KblmX4h6TMXeuzJGN0mbOubsYNC",
            "62":"1hf8Z2sXyu-OGh2DvU3MLGvhtB03nR3IW",
            "63":"1q1YEssN9kFgLNvRzmolSBfOT_cD3w_lp",
            "64":"1rLOgWorh1MEhvmTlW3os_0eXWWi4KWLN",
            "65":"1uRNwzSxNEhJIgfvvRagM_MT2pid_7Tw4",
            "66":"1v2a1tcIqq83R8HPcYi0NoVtGuOAYg2Po",
            "67":"1-83YyRCa3b52JWZIYG0lZJiA5pLFRZHB",
            "68":"11CgsYnsECNLpqJWRwhQX-xoaEQsM6YdS",
            "69":"12a_DV7aBr-mwxgP0vYEppxg0GC45u3dX",
            "70":"18QoFY6qvSYCLXEDftnQtqmLcHiKtsU5q",
            "71":"1BN-JlASJI4ipAHdjzV_ZD4ujKzEtOKcQ",
            "72":"1ECPu8CNn5xMcYcyA-4fgzO7LUrbvob-D",
            "73":"1KmiQ18mEHZBv_WImSw4JGI5YdhGtmO_Y",
            "74":"1QiA5AEdPU6ITXZd-tziJV4XkIDIgyz-m",
            "75":"1ULW7LgmTgQ7SxGHwc9-Gb9FHD3MbzSrI",
            "76":"1Us_XntlY1g8xvnbqW8nFd9afAGa5m2e8",
            "77":"1VAttDTOiwP0oURjBVRo-tLQ8hXGq91Wf",
            "78":"1VzKlgCCPZeSWAU6sqhEo330hM8uVeXCY",
            "79":"1YStsEinOIEevwBzqMuVhzHnW389aPLAL",
            "80":"1_zkpPLzAcbP6FD9ND4uvFxU-U9X-intv",
            "81":"1aVTqw8ejmy82D30OJWY8IJavKcA6x9z5",
            "82":"1aam91XxN8iJr2RwTJLS0xfTtwAlrW3Fa",
            "83":"1bFxjYg5sfeLItz0yYxaeOFa0cl850fyG",
            "84":"1dW1o20Uwp1c8GY4EkXyuJvvC4PlxlFzX",
            "85":"1eJOwghFNEgogW0eAkPlvGjwqDCeCa2-z",
            "86":"1gncsFfKRMPK8m8EZXm-UsWThiqQhvtA9",
            "87":"1hpgYgs_qLqMG8n8GEBaQ8vimzZTGD9Dy",
            "88":"1skLCa7CmzN7ssXLN8dsW7VVgWBpvloC3",
            "89":"1xWTn21sekpqPUHqxJHHBkzfhxTIMiHVw",
            "90":"117PKBJaxpjDbU3isxvxRvDwwos0lsvJq",
            "91":"12FfzundipTV2h3mrgRmbhFJQkyRqkHj7",
            "92":"14VfZqWIUSp-i0-uNC-0u41Bzbgv52ONw",
            "93":"17ThugFSQJ7L2r-zswCVvedwbs6ZRsimn",
            "94":"187hJ6SopvC7TZwfVND5SI8Vz4aY-aSHv",
            "95":"188O-bDz53F9ujq6tzaqQdoJOHgHkUw2I",
            "96":"19guzji0hW5TVDg8B-vo6x04_EAJfE9YU",
            "97":"1Owr8IrhG8PDcLrfjILXNmM-08-gT5bxg",
            "98":"1aw3H60Ew0V46Bk3gaMLgHSQdXG9Nm3cg",
            "99":"1eX9IjJJKoJNp6nKAUtSTU-HNA-0MbfkM",
            "100":"1fmbwnJr_bcz6pwoZmXw8ezKyUeJOEzdQ",
            "101":"1m4J39M3a3Zx93_4HHdXxxgRC569ZhkEZ",
            "102":"1mYg-zQ6TNUia7Dt8JetPS5pgNh4ZX8LK",
            "103":"1xzIG9mnOCF5uWYStsChidgcg_Vqy02PY",
            "104":"1y7Il1tXB4Tgui9khOMuvlswtGHcN8T8o",
            "105":"1zSv8RF0XzOJ-h3v53Qnjz_0MrK1eXgmr",
            "106":"13GXyxowWjV-6Y2b3XyOsIMOrWAerHV84",
            "107":"1EHAqbdAPuvBDJrPAQLuPCwFWeRVTs1Si",
            "108":"1VX-rxxe8NewDYekpESt1WAwLYCl_OFl-",
            "109":"1hKulWS8Tn33X_l0XDA8rXXCIb-2rR_Qy",
            "110":"1lpHTFm9HF51svMqeGOdlD6Wch4vnxIsD",
            "111":"1u86uiuJcATNzKIrMqKGRi5FHBWYBmtWQ",
            "112":"126X2FdDAkTEboISBebFA2qieIs9jnLiB",
            "113":"14kJKnGlODPaK1YO2wk88uJAlfYl4UnXL",
            "114":"16LQf7Mw_WuOwhMf3WkD13OQDRjdo2n5W",
            "115":"16dfI-9nI2i_j9RxSMuvRRk_IajR1weRW",
            "116":"17B_xwxLSncXHBsGMq6p9aJ-XYD1HinO8",
            "117":"17e98X0svCE_glvP1xuarLKG_lNV9OE8z",
            "118":"1H34E_yWH2EJgY-ujTrCHiQ5C7fzokjFI",
            "119":"1I4ROTSCZWlkXHYhctBX4rmFPU_Bpra-w",
            "120":"1Kde83lfvBzRtHOxyAtVlWO0_NvArU9I3",
            "121":"1M4cx2cwDHVLWoTetTrqcACyuzKfLP1QX",
            "122":"1PCAFX8q76MAIsdgEdSFe4hWGVHz1esNg",
            "123":"1S2_2aXrkArBFFc14Dc0p_7M65zdGsGAu",
            "124":"1Sc7nhodAoo7-w8zKgyMUeQQYu-gnJOHT",
            "125":"1SgpSJSkp3DO1COwD0V6oFjNx8QeNSv8-",
            "126":"1VoWeqAnt1XLmmbBx5-0A8skWb0crhguv",
            "127":"1WmTpZTDL9rY7TAqm0wXaTBbJZ9oqqPvr",
            "128":"1YTMNMu5BTo4RpJPwA7zs8bOwqilx5J8c",
            "129":"1Yk0ZiD8EVYawso7vV39ygqojmQ2dzhkZ",
            "130":"1Z4-W3GumWCs-rsBwoj3zdX41Mh7g4CLE",
            "131":"1eSqE_3vIKZRl_d206JqklAEdGLWnBmZn",
            "132":"1y3GEtdy32_iYmPL7tmeSxOaOR2St3KGm",
            "133":"1d4x8-6-5horM8FYDE1ig1fvId0_CCJ9Y",
        },
        
        JAPANAN:{
            "1":"1LhMMReObZISw_pvA5-vtIImer9xmHCNo",
            "2":"1furNM-y0aBU99psss_nlqR3gpx_tsmmX",
            "3":"1hR71cvB1a-6BbbuDGY_oNKeZiRk9-a0w",
            "4":"1jiWSzH9rh89osKg8_SA2GkjexMmi-G_Z",
            "5":"14fdWuHk1-EsA40UTMncVvfEdBEAXLSRV",
        },
        
        NGORO:{
            "1":"11AA7CUnp-jpuZslFq6Xjz8RKPGltyDKC",
            "2":"15hzJpeUneb6BGZQ47fAOOMZLC0d1pW8w",
            "3":"16ix0ev9KKTsMXCasAD4kRGYZchwzbclr",
            "4":"1CuXrb1sZh_9v35fouHCBJpFVvmQYyPff",
            "5":"1E_h43lIsJYLHDlBZaAzfQaqFYiz_EfbX",
            "6":"1JilSIE8sGlKwUwY2-_X-m5EvDDyTFGN3",
            "7":"1KeserRpsLM3s85aPVfYGRRNRt4awSsDZ",
            "8":"1RqgWDJTg-G6zLlhkzrF5O_JvzXctGFi5",
            "9":"1TkZXTW265ZYTbkuaAMqu8MFk7fV49ven",
            "10":"1WK4CB_h3Kk-ZtwQMVKFuI97Y5Wt4Tykd",
            "11":"1frEcOaVY0H5jnhybXoUvaNJ5YaiaOm5i",
            "12":"1i5H6E7q0NVCeuesL4mB2iHXRQGkklJMW",
            "13":"1AcBOVSS6LDEJE9ap5QAh1LEs4YuMNdBZ",
            "14":"1GpHYLlSNaBn6HIGciRkEy5hPO_vrBvcI",
            "15":"1RuTINf5fzkVrXu6Gyi9sPqdUajtTctF2",
            "16":"1TIoqRAxwpPtcF_-WIcI1V780N0FwA51b",
            "17":"1TwEN9-WUtJlNRRbhrmAkyQeeFgX9hrs_",
            "18":"1WCwqYXc8u9UkzjtXDbBuuTvDZOy6Kxst",
            "19":"1YsnJ9PJsST8VyJx4iEDiSr0tqCHygAs2",
            "20":"1c7kHq-ZgyZTpN9VakGS5_oGaQC0xu5cN",
            "21":"1eOctjlKbLDK9fWB_PZJzYoS8kmi0N9JG",
            "22":"1eyOx2smKF6L_GhHnuIx-wy8clqBRgRuR",
            "23":"1ixJd4yVB0M8cey5KmgmEd1zVc54lIozX",
            "24":"11AFiSLmnJv7smREZMd3F3Uf3qKrxS4S9",
            "25":"1OhxsGEDCdKBH1UU4NLQn03_73eKy_Dv5",
            "26":"1SNZ1AyzFIklHEMl4tkY2pn1iQPwxVkZo",
            "27":"1Seum6BMnd0wJ-m1ZSIlsOmbjpA5yI0jP",
            "28":"1ThRQRXT4XPMAqFXNxiX72fEIJD1sofzh",
            "29":"1UKyq8Q0YHwudNpB_A8u_AnXdVLc8nbE1",
            "30":"1gpXxO95u4r1l5Qv6mIznH0RkbqrDtWd-",
            "31":"1zWzLX4ToGzNqHIuTWJM2mmtf_AEnS35i",
            "32":"1zmh_q0qQQ3cf60Xq-1n6XInjjxkaJUUe",
            "33":"1-frTLSyLP4uZZKXwTCispxIq28Gd1SVu",
            "34":"15TvkVF2fKDYLxM7BTdHLafkzSE4y-_KR",
            "35":"1FBZuquax283j1AwOT6xGOB-qh4dgFo0P",
            "36":"1OZ_p9M9IIWs3ozq7sbMIyzHw_DjKNG8I",
            "37":"1PSiASvL7HbBzwi5gKPVyUS6fSByAkQTA",
            "38":"1VOotbJWZg69L6bZSWYIktTOLF0EEyEC9",
            "39":"1lvWRxWUPZ4Cv0g55zJMrqzqCyCaNGoEk",
            "40":"1poOuCfUnhok1Kz--keEBrFqGJq_OeKWD",
            "41":"1zr9rW0kRRdxDGm4ezwWFQi-n4v3ssIIt",
            "42":"1-YsXaoCamkDtbN529FYfrfPSYuH_QCVt",
        },
        
        PIER:{
            "1":"129OUIrANwRC5Zo951yBfCzdQ-iCAhMoF",
            "2":"132iSgp9wmSnoxbcuKgoCHDKk12C6VXPr",
            "3":"1JRACfapHOKAHodPY-DjTOmpXU_yY-f8g",
            "4":"14c1sfKUTAvDiA-AidkvgKitSFSm5sr-o",
            "5":"17KByagL3P2qQdbWOLJ3DtDSb4vhLPHss",
            "6":"18ksTaNXQCYqyMVg9WeJq1fc7afLYAdI5",
            "7":"1DAmJJn0Zz2OnnFTOTrFloq7vsALdqJyT",
            "8":"1IWniaIJYjqUPzzRCEd97ntN4WlqExRBq",
            "9":"1OXgeR7ZUOWT1DYyhRZlCHaIlYEhpNAmH",
            "10":"1XU7XXnJEpuhEdF-9u70S4V7vllxd3SrA",
            "11":"1db3Yyfdc_7diflHaeYIGSh2BAXuZIjtP",
            "12":"1eXScrygx_ZdU2gS_owcwZyrxMqo9SSUF",
            "13":"1lIFqGIq7Y8tHoV08fDKXU1JrBsVvgBE9",
            "14":"1nQLKldWD-nV72cPlMNPQDqiHEf5YTN0y",
            "15":"1oxhO9Fphkcg_v8NcStJ6jpH_zn_akqo4",
            "16":"1xbfYqZGURUOwL5wh0v_FnU3QYs6E2lH2",
            "17":"10TS5G3T6S_ODe5m15a-QRrbaOBA1jEaW",
            "18":"12t1Z5z5awXhSIsqImwTfIWsSs8n6FYpt",
            "19":"13G8VwtpHWZETKkPH1O7kL-IFoD6F2bxd",
            "20":"15QC9TwSyTPRAPrVboPrlGMShdkTXjOdY",
            "21":"16pf02Rzj_fjrpk5j2GwN_IvOPF-QdxcB",
            "22":"1C2RfQa7MpuDu7W3-gLXY2S0oX25loMPH",
            "23":"1C_ZXtjhEOzrOab2mBvqA-X4sP6PuVXU3",
            "24":"1ClEtIo26yLRzkCekEEsxvEyv6NrRVrHR",
            "25":"1DmJqbFP3ishyUsaa23czWk-Q2t5QDqlo",
            "26":"1RZCFzi-YvEHfAyJbGneP5FEmAxWohP_w",
            "27":"1TcLsClcGXG-Oo_c_Bxf5XzpYqhMAdDJP",
            "28":"1Vh54oqcWa0_V8IpeG1RTrwtggUjwEDcj",
            "29":"1dqRfCLHj9SDpvLmpjHe7OCcND-17Jwgw",
            "30":"1fdSF1q4Pnig7dMzm6nqUcx5lPYPo3RyO",
            "31":"1mQ-SBJprWT1PaTyja0OvySWIM6eNbhgD",
            "32":"1msVLj4KbBOLU8FqJeeIcRfY9_9UoIobj",
            "33":"1oaJIhCGsNkML59BiQ51pHU3Ne9veRnzK",
            "34":"1evRhzMRlQcz4hXjMWBkgGws7Z4-2ivjj",
        },
        
        SEMARE:{
            "1":"11ITOePfT6UEkre08zFV4TXMkRS3AHwbC",
            "2":"17UfCrjzp5bnJCPYm1_Ovf7I6l16z7UeJ",
            "3":"1HArQZg2hVTkUhP4ljxwl-igEBTL5zS6h",
            "4":"1IkIwYiCUKmTF0iLYpxcRq9QXuSRphk3m",
            "5":"1Joz2288FpFYhyiEQnzvQfQzZzHPOe40_",
            "6":"1KKcRnpVbEF_uD-O-HfF4rbrdSLtwbc62",
            "7":"1QroAK88ADDXaoyNI_iJdD6XooZ3is89U",
            "8":"1THrQOMbsstoYqt6MhlHBigSXjY2lzl1l",
            "9":"1gpjcGsUXICSbMOgw6nsJFU_uiJ-VRace",
            "10":"1kJ4vVP4r2oa9D_uPfndcXnVLQXtOUGlO",
            "11":"1mUPlUWiszFWMZH31uLqrupUlS-gIdJGm",
            "12":"1okN0XhuBe92grrEWw7PvlvzfSHqDPtk6",
            "13":"1uDWUL4XrJwrkF6EImRgrk5WRs2bYwflz",
            "14":"1ySlBRkvKDkn-kAE0NALtSdpVKNYNkqsd",
            "15":"10P0BO7n08oUN2aL-LyIWq1qEzK-84Bm_",
        },
        
    };
	
	
 const photostockData = [
   { id: 'GRESIK', label: 'GRESIK' },
    { id: 'TANDES', label: 'TANDES' },
    { id: 'WARU', label: 'WARU' },
    { id: 'KALISOGO', label: 'KALISOGO' },
    { id: 'JAPANAN', label: 'JAPANAN' },
    { id: 'NGORO', label: 'NGORO' },
    { id: 'PIER', label: 'PIER' },
    { id: 'SEMARE', label: 'SEMARE' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);
 
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock.png`}
						
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_sor1;
