import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Nativestock_sswj = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var photostocklist = {
        
        GRISSIK:{
            "1":"1GIzviU4EmhMdxR6QG7zmR8iO0bGt1d3W",
            "2":"1IVHbiI1K9CS2Wx0shrt5MPPwW4ECb9ai",
            "3":"1Iubz7waYaH5GBnYuN6Z9Jg053vpnjVB3",
            "4":"1mssFy_UD5Zr9IArGct0LnlnFB3IPKYsH",
            "5":"1wma9B41fHnuCd4TY9y2u4Tpvgr9XMQ9u",
            "6":"1ynmEEfpbllG6VPKcUhlVo5MNr13Yde38",
            "7":"19u8xtuTRefhhpJ9C60u1oCdpB4af2v-a",
            "8":"1DCHSzhF3vzCPtVvS2dJeYeyfXn9jDGC3",
            "9":"1ElIdyWWwf5_J0xpsFsPRD1w4HU-JiOD3",
            "10":"1G8B5pSW-RAkvG8ja7_5g3LJMoQWgxeno",
            "11":"1VgxW6_Roro1iTmWlhmd1bkCneJNqiXLY",
            "12":"1Wc0SNQNepyzNS7xnkE9R3ExyH6uO_quh",
            "13":"1c4SzAmrSgAz6ukWJefR9UVCU29EGzyzm",
            "14":"1eqY12RiJ841BWz19tNw82rdA7oshzShW",
            "15":"1bYMMgQ4t6h3WJZQK9SRDcuMgLpDfBaoC",
            "16":"1cTI9IkvGFqRDgOA63OzYGtaeEh2RrGRl",
            "17":"1vfRpxuPLxfakvTVJZSANCr1xOx1jG8RQ",
            "18":"1yLmIJKGTgL06nk3EEs9hBGlcEFJmeu43",
            "19":"1ndLl6W76JlhXoQlQtnpITNAvXSDSwSDw",
        },
        
        SPG_PAGARDEWA:{
            "1":"159vQ-MluslSos85gFfVpTew3me9OSnFa",
            "2":"1jXS7jNN9NQdw_hvXb-cIIj-nvbHl3xHF",
        },
        
        METERING_PAGARDEWA:{
            "1":"1NMYFgtPnXQCwx4m0DJGWiLi6pP4w6JuB",
            "2":"1m_QCMdJ_09VMm9Be4xDLa1-RLr03Eyz8",
            "3":"1xPsTBpl6FqZ0HFwLddPZdRulCPaEdQpD",
            "4":"1Dkfbd8IHOrkk1UIRK1Vm_heLU72ucP17",
        },
        
        SKG_PAGARDEWA:{
            "1":"1brQLYorLVXyCl-bVfCliMe4uGMQve9oh",
            "2":"1WHJyP_3tA_0HuBZamhRBOxlGotjxfd5I",
            "3":"1d6aQ1pPMV0KV1DTIE-bVhzvPOSUDka1r",
        },
        
        TERBANGGI_BESAR:{
            "1":"1-NCxfEWLoV9r4y_VRZcchVdQQt-U9S-4",
            "2":"10BPJXtdIRCCIsLOtJbeCeR5ykkheROns",
            "3":"1DtwH8dAM8AbYBpORQYeg6aLSsB1nVrle",
            "4":"1xHefNzpVzi7ZDSf0M_h30IaX_HGP973U",
            "5":"1CDZAS9VMHvqzBayBzTokhLamCSQNZmaE",
            "6":"1HJZkWGAFLPG-qBLT-lZe0-k3AWeWSHK3",
            "7":"1TQ4F1oO7yD50gsZ4a8OdF64IH6Ac2Tf_",
            "8":"1Z2ytMcGxmoqVWuJ0R219udq3fpQ26CrN",
            "9":"1ZeQk3R-756i6eV50y8SGlFkzVihC6pu7",
            "10":"1lVrpJFuhFa3TRxYiJlGVqBxAbc0rgblI",
            "11":"1o0nZ4yHUiEABRGZ6jqtwAX4TvQIVGQpn",
            "12":"1rQy8b668Z8FgR8P7H8irxNcN6mmgB8Z2",
            "13":"1wlldsHUoJcLGiA4TkJK7ZQICURAh6h4r",
            "14":"10MnP1poGvigJUSGlxlEsfiuGAAszjCv-",
            "15":"19Jwq-4RpJrTEYa3GwQDpGHENet5QfVUG",
            "16":"1s2N_h004450U1bTd5oxXfWxMQz3Lch0v",
            "17":"1oPUaUCcct6P6KqUkmqbIFkoxy0MikWNK",
            "18":"1KCDR9WbzV13XLNrQYEJQZ7FEOclh3-a5",
        },
        
        LABUHAN_MARINGGAI:{
            "1":"18Ky_XHJMzEskIU3KDUxFJQPAS2Y9fQVv",
            "2":"18XRDecXAob7ci42Ncm2cOdQxOsrpKanJ",
            "3":"19bzL1t3TFO6wdZCtb_crqrJXLcrC8R6z",
            "4":"1UubvO_IJNADaokpn2nbhfMOiXpc936Fo",
            "5":"1hMebHSUuwtsXDhLG9juzvO_X6ycDCALM",
            "6":"1itrv91V9KsvILcCd9cCYso53Ii456IY3",
            "7":"1ivtlkeD0UGRri9rrZzFoq_aosOyLd04h",
            "8":"1jweRCv_Ga9y0MNy94DnWwTlGlS1ECEqO",
            "9":"1oUIja0T5F8uf4WKCu0upMnTKIYwsBHxX",
            "10":"1u0aZyu25M1GPBKSuWhplz2-PiT4QAewV",
            "11":"1Bxo-XcYTBB7wHNBJGKlLYfw6fWqFR3xW",
            "12":"19tkLW9xzK7SI0xSETM7hhvjBMWzrKLbF",
            "13":"1oQn9_2UstzITK1eaY3ugEhH2bVSnnzBY",
            "14":"16u2nZIBu-1MAS61ZFwe0-HkkFUqurBXO",
        },
        
        BOJONEGARA:{
            "1":"1szeKeVi0p3ZHOqzwsnAUTwKgfH5zfg-S",
            "2":"1ZExYnGc0vKlm98bzNvRdM43xvhO9bmxK",
            "3":"1gKbx_TllwD0COc2iwUBLBpdtL-Rvu_fH",
            "4":"1ANZgeM_L6zhxdV6uroP9FZOpdJeS6-x-",
            "5":"1hB9HbLRztE6FyDx_oLF552ay9abplqzT",
            "6":"1AHyNcEIlePVjWSudE8MXBOC6OY1fbe4I",
            "7":"1fDZ_dtC7ZsGwhioaCDw6u5VyNTUZYILZ",
            "8":"109wm0ClAN5LJdH9kKDq34Kz9k3uEA8Q_",
            "9":"19LHXM0-FH7XaJLrVXpHkm6K91xAzqEyH",
            "10":"1WeouWeEgMPGUGi8ncktDBwUaJybWRqdY",
            "11":"1WkW8-D6f8TsP4j41L4XnRwUj7KcLN8Xp",
            "12":"1_drmZqDSJazJLjLFEkIlXwCb68vwEEng",
            "13":"1h1iATS9VrcD1yEYdsy8gGghgSV9yGuWh",
            "14":"1mLdOY0BlRIriz8I0c-DzSEYzU0qbEPTa",
            "15":"1rnxhJJc6kF0Za3lWSbkRlAzTErI5NJ8f",
            "16":"14vthXbj7gWKWCqPqXoqNoVuMHfgBQHvC",
            "17":"1CRUWgqw2GlSuh9KSjaO9ltofwqfgL1iJ",
            "18":"1VfGtNPsZemlDJmXvAQjmVqhWK_nw8VHA",
            "19":"1zzONQTUYI5MVeEsNmY9I_np3e3EeVjhw",
            "20":"1Agi_Qpb8KgCsTGHVVAKsfjFYWyRYwX9u",
            "21":"1CsVMAoJwzms1Eh_Xefp3ezcce5fRK9fs",
            "22":"14Xflix6Y58OOt0TZE1Y_k8ryfoHGmdeW",
            "23":"1M_oQFDW7AlszzC4ghd9bxDF5LjDB17zl",
            "24":"1dayWh73vswPt1JRcewPpGHhfJI5q0weX",
            "25":"1-7GkINVzELhuJgUhiQ2IE9t0sJVDIrVV",
        },
        
        MUARA_BEKASI:{
            "1":"13RTSdleOufATgZPZs9CHkASLvLjBoznu",
            "2":"1M1DYI3fNFZ-kjfjgwXw0Lsi4SWpkGoPN",
            "3":"1TAxVzx5X6jg7FXAuW1-yWRscCjsXzn0v",
            "4":"1c9ajEm9A3NO65OMrnzMBnfhz6O1leshe",
            "5":"1es3G51Ru8qK2bYcZUA9f8-eeaDx2dS2t",
            "6":"1nzcxyWl_lTDbQvFPbwWLKYdTIRl24rEC",
            "7":"1qolGY8bqRWYO5SfFvD4e7x7oeeRoZJy0",
            "8":"19QhiZMEzQdKJ1mT69BYdWzZFBaeMdGHd",
            "9":"1cilQOeYjiDnhWh7r17usk24gVQErZG2O",
            "10":"17OIhTgrlv5WbRs-coNS8INIk51rqGo3D",
        },
        
    };
	
	 
 const photostockData = [
  { id: 'GRISSIK', label: 'GRISSIK' },
  { id: 'SPG_PAGARDEWA', label: 'SPG PAGARDEWA' },
  { id: 'METERING_PAGARDEWA', label: 'METERING PAGARDEWA' },
  { id: 'SKG_PAGARDEWA', label: 'SKG PAGARDEWA' },
  { id: 'TERBANGGI_BESAR', label: 'TERBANGGI BESAR' },
  { id: 'LABUHAN_MARINGGAI', label: 'LABUHAN MARINGGAI' },
  { id: 'BOJONEGARA', label: 'BOJONEGARA' },
  { id: 'MUARA_BEKASI', label: 'MUARA BEKASI' }
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesNativeStock, setSelectedTablesNativeStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesNativeStockOptions, setTablesNativeStockOptions] = useState([
    { value: '', label: 'NativeStock' },
    
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'kosong', label: '2. Pertamina Gas' },
	{ value: 'nativestock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'nativestock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'nativestock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'nativestock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'nativestock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'nativestock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'nativestock_sor1', label: '16. PGN-SOR1' },
	{ value: 'nativestock_sor2', label: '17. PGN-SOR2' },
	{ value: 'nativestock_sor3', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesNativeStock) {
		
		if(selectedTablesNativeStock.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesNativeStock(null);
		}else{
			navigate("/"+selectedTablesNativeStock.value);
		}
	  
    }
  }, [selectedTablesNativeStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tnts" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesNativeStockOptions}
			  onChange={setSelectedTablesNativeStock}
			  placeholder="NativeStock"
			  value={selectedTablesNativeStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/native_stock_bg.jpeg`}
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Nativestock_sswj;
