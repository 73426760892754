import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_sor1 = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	 var photostocklist = {
        
        SPBG_RAPAK:{
            "1":"19cg3yTOdqBINw1GFE0EjCsocTlrA0xjp",
            "2":"12TJbp-9_FDk6SG5RyhZzUhqxpvF_2U-2",
            "3":"1HhZRmqdoXW1T4PH-KwBlyq7eEVq2UNd1",
            "4":"1CaHT3Jg9WE7RFk6TFQ9xpXqG9la9P0me",
            "5":"1JTd3OZPISEYDEAeaMnAdAOIqpSK_axg9",
            "6":"1HWvjX3yKHu3EkiINT_z9bOifusCLzIpW",
            "7":"1QUjF-bFjVA2MHxJuXug-qZodlq-C2aoP",
            "8":"1MZCwWEWY_cMIjeq1uWPcgqOlm44cgS-1",
            "9":"101CE968oOnTsrWjb9Fc7jX5agPmchGXU",
            "10":"1iw_Ly5YxV3s7w4vYpUkwAsHJXPYvTk7A",
            "11":"19PgTWzA34DBJ0kpM5FZJ312KJk3Cig3h",
            "12":"16QLx799aFsRnX6STl02N1l-2DM8EkT_t",
            "13":"1lQCxhROhGWYtAUvUVkspQuPICz81eW1K",
            "14":"1DuPzhEn-qgTGFiMnc3ZIV2c_AXL2R1Gm",
            "15":"1xaIaOV4w_-XoRMEKEg7ggzFzE9vPxgbn",
            "16":"1ZBhjPja3tViqyY7iH7bJXRnC0OrHUI9X",
            "17":"1Q99rX-pC2KXn5U12vwDWs0AJZqLdwnRb",
            "18":"1fQTjYV8ydyIHmuGA3jxkH7IPfgDUkmbb",
            "19":"1IkVxWVAAgWmnzVENdEzB8dCKFBDWnDwO",
            "20":"1nkrVG1ZdFa6rYnptlY57G2xwKdcfh0t2",
            "21":"16VMXw7RrOchaxppVTAWbItBYk_SPYcg_",
            "22":"1z6g1jRJ85JqE6h7eD_RrjCkEEqZoSLcU",
            "23":"1PwAE5KDcuK1IwB7xz6Nuu_3hZvjce5QU",
            "24":"1Nu-62mMaOTivtB-QqfrpTm6OHvykTrpZ",
            "25":"1CotWHMb2uGhVS3cOIE4_oWUjUYf5Ft9X",
            "26":"1NvwWIV4ol2J6VlJtHnBGqLKSDhiQGpJv",
            "27":"1aRUa7YDNELBqLcpVsLIXKQvMaNbJhaIG",
            "28":"1DIyNw-in0EbUpUTamgBv6LlbF9FdejSf",
            "29":"1TZZvaDOu7dsKI4aM2TjB089KobdyesVN",
            "30":"1BQFu8CiSB55F8-IKKGxe-vY17yRr39Y4",
            "31":"1J4aVfsa1KrB-ks3-lMolzPaLeDpv2zwo",
            "32":"1Y0oUreV2oMsdnOA45AETQSnzKZ1dabzG",
            "33":"1TgKGuR8jW2O4EXeItv7JEXbCwOGRMuVL",
            "34":"1oUzNZoQXvlM790qi4RyMatpCxkz75t_0",
            "35":"1V3rGqwDVEGqBH8L2olnThuSD9-_JVwr8",
            "36":"1rWwS9ei2DMQ95iycYtnc8RLJ_mh1GwEX",
            "37":"1L3url9ldSaA3OlHlaFM2GL97ZRTeYnyz",
            "38":"1-ZYY-PgN8etIbo7GYi2OpbuoNdxCdHga",
            "39":"1GyTVeUpt5mCm10bQSkO8CtcWefa0W7N_",
            "40":"1xQjtWRZg4ePbvcPClPG0GlncmJ2YJlYs",
            "41":"1u0BvI8j5fRheMoP_AWg57pYtjs5hcPOp",
            "42":"1iPGgwKO-e_U_Lsn5LKf4TeOAGma5JqMU",
            "43":"1y-Rhl_iyILdfwUn8fbF1Rf4CraeWL2aZ",
            "44":"1DG-Kdo2FMBh1vSpdST5TDj4uuPFDd_iF",
            "45":"15Av4pr-0nDnZ5gR8V1vBMDQ3dkscyHJP",
            "46":"1aNDRhaM5JoO3SEC3QwNa2_Oe_AqI8BEO",
            "47":"1zKdowCSo5MOGOWb7qlDT098QUiHup76h",
            "48":"1r4tYvG3oGagfJxmqL7i5m9VB-dRcBzKd",
            "49":"1i5T7RlsrELNvYvo0vsNsVmGBiimqWQth",
            "50":"1HgjgGMU4vkhz4uKKK8WdG-dvQOrj-Vrc",
            "51":"1JLrVTjy1UT12YKL0unrXlzRbP1hnaBn1",
            "52":"11s33aVu2JohzapuxKgsqSWeHjmnfXMp6",
            "53":"1Q87ifAZaYSHuuk9vnJW8bDM4dkjJ3YJY",
            "54":"1S5zNt4O1ZcMtu2rv2_WWUonJgRMZuSHu",
            "55":"117GPtGZSbbLwbqg9R0YLMGm2244VQLXA",
            "56":"15zdWht1I0zoRFr7GQYdRn1eSA5OoCFLF",
            "57":"11-z1NdTalu6et5sDeOhmBz6dfvuxJwtA",
            "58":"1LzmQkfOCLzgLtgGfsyEDNKgKX3yE-Ao2",
            "59":"17no_SLb0zb4C9QgAkJcFWaw8cmJdlXQr",
            "60":"1u67o7mIzQBuQO8KhfjXp8fe3X-RUnE79",
            "61":"1W3-bpTVTIs1IGIDYmmDPbUi8UgXlldDe",
            "62":"1Se9xUFvgkz1Ci73Mw9B4LJoUPKdkEGqV",
            "63":"1fEdcz7NMWTdoWms2UJ1cX73ChGMJ7h-a",
            "64":"14G5rmYUXvP5TVJH1rs5-oPX5ajeDmyYf",
            "65":"1QqT6EXbis5Xx9y2TU2rw3lusSPZNu0mZ",
            "66":"1pflu74EXMtx-h1Msck5rCu3D8d0f9oYF",
            "67":"1b5Vsuzec-s4-MgD8FOf3kzL6ZvtzmzhR",
            "68":"1nnhSCUf_ztb0Trenaa_CWPtQBVDeziEf",
            "69":"1fl2D8bXVqANpvQ1kxXP3AAOW5_6kxvUD",
            "70":"19aiCcvP-vypFe56SNvvwQP84uczQ7Tvl",
            "71":"1M0lTH75W4ygTo0WCUDtc8IRGgUc0BWec",
            "72":"1e9G_51WPqWf5bp7gN_vdEQVypp2LHULF",
            "73":"1iSwQ0D94hjz5j51BXziSVP3h-KyaTM8B",
            "74":"1o1Q-ZD0ZLeRNysooeQxMt5Std_nb8jnR",
            "75":"1jwZxtRBqoyuaPnC3_Y3bXEhkS0lzxitZ",
            "76":"1Cxvcl25mAgBxfg8CaOmq1lenDuL3Cyhh",
            "77":"1LRq9z8yZLVlZfJLZQQ5iMwxoAMP62dA4",
            "78":"1iidDJM3STwQZTEc9be_bAQ5Qe_8-x_X2",
            "79":"1AkTNEWfv6yErD8lRCAKR4-TLNzPttnl6",
            "80":"1nAVbK7iDk8qA_F33besoIDPFmVoFsXmg",
            "81":"1J1_6QFgQTfZQbGuoXYEhIXQxJKmsfRow",
            "82":"1deslP4K9VDGnJ52dYp32FiEJutccGZWJ",
            "83":"1XfOqD0TNE-Tx3ApFixkTGUDpsS-xBva5",
            "84":"1NH-4ZDSaohzDXjNrxHAeJFtzjmnpfrV1",
            "85":"1IgkOzcgAHQxGts3MuKRHeWBY-eaeqtwA",
            "86":"1XLpw765ofzyAE5-XClKcdh877GK2oSbS",
            "87":"1Iwi0U2TiFG5QXZEsx1d04eGnxHlYuw33",
            "88":"1ZYddLHAqkfeQKTvXerzkB_myXPDGWVW4",
            "89":"1z40PUsDTkjlZXbgnw3K6dAsTY3MNjBJb",
            "90":"1AnkMIk2p7N6Jmr56kBICnuv60Z2IaGue",
            "91":"11fx_LxzSFnaU14pS1XiGFSxEgchtPbYJ",
            "92":"1JowqBt3Lr9G8-Rq90yoVrekXqaszhk72",
            "93":"1N68qOhys64XnyKEl1QG1LRewYB8JBy4m",
            "94":"1Z8FTnB7sdVV-wQynSjBDc30Mbeag3zU4",
            "95":"15jGClqQwu_y9V1D2phmmrPiGuWTga_km",
            "96":"1w-uOYeusV0epoTasHB0MzGo66q3ze3oN",
            "97":"1j5XQ_Fna-Y6YgG1lj7lqo1DWb7tspH3d",
            "98":"1LHH5ZX1wKW9rSx6JuLE1KhKZ0GXPME6_",
            "99":"11v13kwdQJW_eBcUudA1v8Hi0kDhwu8T7",
            "100":"1RfmMbbxC0VCmOqZyD6uBnqvRjB1j-BhG",
            "101":"1-5xBvUTkWsTTL0iVtCR6xoOfNKcrTPY9",
            "102":"1FxE6XJxsyUd-WM0WhM1eAc9xMH4pboIU",
            "103":"1rKem-E9i4oZn6Y94VTWJDd1miFVPrwg5",
            "104":"19RYKAD3TxrpkOxbnYjDB6_n0tvuZADn6",
            "105":"1wLtzwVy8sPxmDjHuoSYj6KdREbvlVzW-",
            "106":"1yIvsvIr7ol2zD24CgDI1Z7meXgrn7Nd_",
            "107":"1h1qYgPDOQGfIeQDzDn3jvvabCSwIlcYO",
            "108":"1Do1W9V2qK0_7hskCF0Nx4NN0zZQzBKoo",
            "109":"1yRZcPpw_V72UWejJJ-nOWtJUD9Mx44T3",
            "110":"1fugpwwhI76VXurJH2wX7SQhjfdY6iv1d",
            "111":"1bhD1qBmcpp5LdDlojlgdPzgjqgnnO2p4",
            "112":"1LCQJ5t-crYrQ9FxMRn1AIQRULOfFfAAR",
            "113":"1LUso8tWx1Pk5d_DMQZqCjmItL69eS7h_",
            "114":"1wCcuZBNHJD7N0oJ0J9kbbwLFR1XXsMcb",
            "115":"11JM4q-MX7Jhbo1IMyP7_B94q44V3nTEd",
            "116":"1M8nDesYpMG2Uu7PNwoAuxwdHKGjMXfBc",
            "117":"1dw00HPHA5kcXn6kARkBImSSlz8l-vHG_",
            "118":"1hdi0_wNbOhON5zEiffmIG5UYP7kUmSet",
            "119":"15qOd0aXK4nBiuX3nNUxdt-mg6mO4oRof",
            "120":"17ezgDcJlkNMI7aWWxNgFGZysqyD7KC0j",
            "121":"1Akn7JhzF9ASBvoh8A8wNVpsRiIhP9LjX",
            "122":"1-gDL1edmfYN1dawKcXy9s1_hSO4C6UiG",
            "123":"1_Nj8h32sbwVdz5Zft2uRgSAi7-z1Kfan",
            "124":"1zjs6TjINle4MWR7q2BcFG5aYiHiyOCXQ",
            "125":"1kyRDbR2Ehbh7oRbam__hJIrHfz1QMzln",
            "126":"1Bd_Ny8J6WoXXTu1mha4ZLpVaFsLtjVti",
            "127":"1raS7iXawEW-pLWGOS3M2t958OTgViAa7",
            "128":"1xN-d8HeMBaRHHACI_gBPOrpIGxmWO9GP",
            "129":"162aT4ZTARdLZQb1cvwOIi3GFzHL0J3Ag",
            "130":"1sMS9DLTsrnlMUjXJVZmfs7xhe4r6c_xu",
            "131":"1JU_hHHQZPWu50MKW12o8v7FT8gBaqbn-",
            "132":"12ewKu9Z2kQ6uTQmaVSOI2dr1cg0YiyM3",
            "133":"1WAtWYJ7CtYQzdsby3kLoQq3xgn5PNO9D",
            "134":"12bt8U9CpVnmHYYtzWNZXh1QNCUriKV8V",
            "135":"12x0g7Tg9S3V6qGYE9oVHamMYcnNzlXvU",
            "136":"1RqrfFkVtMn-htk1Hz5Rye5gIBEUfJ1uO",
            "137":"1Zdoquar2gpH4KEg_EVwm8ZpR5F34VQhg",
            "138":"1kz8XMp7LqUAM9Xf5rT5icqN2nWbTTSDx",
            "139":"1ULHDZPvPO3MOgwoGqpM9lWD-OC2673p3",
            "140":"1IWTC4xQiy1HlF4Pt8wRSbkn0bNfsefhl",
            "141":"1YMGMgk9lDRISKzLWPsP1VnB6YyIuXFNK",
            "142":"1pFQQ2gS_YiGD8zT_EIESKzc9oNcRqYdx",
            "143":"1jjgk8wuLtlae3vdIU07ZD0wvBK6XQN34",
            "144":"1jKVd6dMYgZD1t2bBZp_OGeNjbiqyTH5l",
            "145":"11pxbYf9GBXm9B_OcnJs3jfl1mR_4TG-U",
            "146":"1YZgi5FIP74Pd0VVnIx2vbKuG0blf7qZ-",
            "147":"1wh5XycsWN3M098XJwUKwlanIX_8tFF8w",
            "148":"1cbKexszxeblqimL2j5dNLVgYaCKKKbsE",
            "149":"1xEH_ZR6XkTcVFaUAIUPEWa7cj19SGFbe",
            "150":"1iu87O9GMpDUpRk54oVSpeQzAYvOX74Mi",
            "151":"1z2kJRzLhKUk428n93Af4TjHpb2Nq6WVa",
            "152":"1NM6uZpdHJIzdGXtZKUZEWKE8K2yPBu6d",
            "153":"1MUY2DMdoTxvnZNwJduIXFyDCoQPN9eh-",
            "154":"1wI3l9m2z0lYjlwSDfg6Mbc7U1OhBve0k",
            "155":"1lPoCgFmnjRSHfHRW63EkCP_4tKNfjJLt",
            "156":"1RuOtmUpYOPM3TKXdw7VVntA1GK6w1fy9",
            "157":"1MkMwl8l1fAdtOtsRVLxMqg2nqFuVYkYL",
            "158":"1kVs5_CKrm906W_Wh-JP5X0qvCkY-p3ov",
            "159":"1YujjruMhPWQhpTTBig5eNV7WcYRTghAY",
            "160":"13Ww5h-LO39a4X_oBnCORaVfXvvTU9GtN",
            "161":"1qGoP1MTtSe3yFAdx70_18FucAicYkbvS",
            "162":"1lduvexf-cbjXDZe5kf0AMyiLpVbEZdkF",
            "163":"1qBj-l1UQya5CllQUFor3CFtkNCWnEMiD",
            "164":"1ROM25k1t4msZ4zxmsYnj7qCCCAlaH1BH",
            "165":"1aEW9nBiCF3JaHnxrPistzMvGvqD3anKK",
            "166":"1Ni5x05D1drfGvYbHUJm6CZ6oWJA-RC15",
            "167":"1V1w-tX_8HOWzwWUJmfmyJ-K68g9txsTj",
            "168":"1FoBQeCwehj0EzxSwk9uMkSXnN1uaADEG",
            "169":"1AtVr0pOMUt1ochJrODItv0ifzRi3SyJn",
            "170":"1kbzIljPOAabZIdakaUysMWb3zH7bWgMJ",
            "171":"1mV8FTD_GIn21CctVtq36Loze1iFa3UL0",
            "172":"1UmTejYbw0mOFiRQjC3H87oJGtExPf2hU",
            "173":"17kJbqmRct7LqW7sYCSFLsnlxySXLxl8L",
        },
        
    };
	
	  
 const photostockData = [
   { id: 'SPBG_RAPAK', label: 'SPBG_RAPAK' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
  const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`}
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock.png`}
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_sor1;
