import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import Sfpoc from './components/Sfpoc';
import Nipoc from './components/Nipoc';
import Repoc from './components/Repoc';
import Lngrpoc from './components/Lngrpoc';
import Lpgppoc from './components/Lpgppoc';
import Cngrpoc from './components/Cngrpoc';
import Pgpoc from './components/Pgpoc';
import Standardcode from './components/Standardcode';
import Tables_conversion_calculator from './components/Tables_conversion_calculator';
import Photostock_tgi from './components/Photostock_tgi';
import Photostock_sor1 from './components/Photostock_sor1';
import Photostock_sor2 from './components/Photostock_sor2';
import Photostock_sor3 from './components/Photostock_sor3';
import Photostock_sor4 from './components/Photostock_sor4';
import Photostock_sswj from './components/Photostock_sswj';
import Photostock_nr from './components/Photostock_nr';
import Photostock_kjg from './components/Photostock_kjg';
import Photostock_gagas from './components/Photostock_gagas';
import Photostock_pdg from './components/Photostock_pdg';
import Photostock_pertagas from './components/Photostock_pertagas';
import Nativestock_sor1 from './components/Nativestock_sor1';
import Nativestock_sor2 from './components/Nativestock_sor2';
import Nativestock_sor3 from './components/Nativestock_sor3';
import Nativestock_sswj from './components/Nativestock_sswj';
import Nativestock_tgi from './components/Nativestock_tgi';
import Nativestock_nr from './components/Nativestock_nr';
import Nativestock_kjg from './components/Nativestock_kjg';
import Nativestock_gagas from './components/Nativestock_gagas';
import Nativestock_pdg from './components/Nativestock_pdg';
import Psimodeller_pertagas from './components/Psimodeller_pertagas';
import Psimodeller_tgi from './components/Psimodeller_tgi';
import Psimodeller_pgn from './components/Psimodeller_pgn';
import Psimodeller_nr from './components/Psimodeller_nr';
import Psimodeller_pli from './components/Psimodeller_pli';
import Psimodeller_kjg from './components/Psimodeller_kjg';
import Psimodeller_pertasamtan from './components/Psimodeller_pertasamtan';
import Psimodeller_lng from './components/Psimodeller_lng';
import Psimodeller_widar from './components/Psimodeller_widar';
import Psimodeller_saka from './components/Psimodeller_saka';
import Psimodeller_gagas from './components/Psimodeller_gagas';
import Psimodeller_pertagasniaga from './components/Psimodeller_pertagasniaga';
import Psimodeller_pertaarungas from './components/Psimodeller_pertaarungas';
import Psimodeller_pertadayagas from './components/Psimodeller_pertadayagas';
import View3d from './components/View3d';
import Updateprofile from './components/Updateprofile';
import Editfirebasedata from './components/Editfirebasedata';
import Createprofile from './components/Createprofile';
import History_login from './components/History_login';
import Listuser from './components/Listuser';
import Listuser_lrv from './components/Listuser_lrv';
import Page2 from './components/Page2';
import Login from './components/Login';
import Rimox from './components/Rimox';
import Muhlbauer_onshore from './components/Muhlbauer_onshore';
import RBIcalc from './components/RBIcalc';
import Kontribusi from './components/Kontribusi';
import Alivemanual from './components/Alivemanual';
import Testhover from './components/Testhover';
import { auth, db } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

function App() {
  const [user, setUser] = useState(null);
  const [checkingStatus, setCheckingStatus] = useState(true);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/sfpoc" element={<Sfpoc />} />
        <Route path="/nipoc" element={<Nipoc />} />
        <Route path="/repoc" element={<Repoc />} />
        <Route path="/lngrpoc" element={<Lngrpoc />} />
        <Route path="/lpgppoc" element={<Lpgppoc />} />
        <Route path="/cngrpoc" element={<Cngrpoc />} />
        <Route path="/standardcode" element={<Standardcode />} />
        <Route path="/Tables_conversion_calculator" element={<Tables_conversion_calculator />} />
        <Route path="/photostock_tgi" element={<Photostock_tgi />} />
        <Route path="/photostock_sor1" element={<Photostock_sor1 />} />
        <Route path="/photostock_sor2" element={<Photostock_sor2 />} />
        <Route path="/photostock_sor3" element={<Photostock_sor3 />} />
        <Route path="/photostock_sor4" element={<Photostock_sor4 />} />
        <Route path="/photostock_sswj" element={<Photostock_sswj />} />
        <Route path="/photostock_nr" element={<Photostock_nr />} />
        <Route path="/photostock_kjg" element={<Photostock_kjg />} />
        <Route path="/photostock_gagas" element={<Photostock_gagas />} />
        <Route path="/photostock_pdg" element={<Photostock_pdg />} />
        <Route path="/photostock_pertagas" element={<Photostock_pertagas />} />
        <Route path="/Nativestock_sor1" element={<Nativestock_sor1 />} />
        <Route path="/Nativestock_sor2" element={<Nativestock_sor2 />} />
        <Route path="/Nativestock_sor3" element={<Nativestock_sor3 />} />
        <Route path="/Nativestock_sswj" element={<Nativestock_sswj />} />
        <Route path="/Nativestock_nr" element={<Nativestock_nr />} />
        <Route path="/Nativestock_tgi" element={<Nativestock_tgi />} />
        <Route path="/Nativestock_kjg" element={<Nativestock_kjg />} />
        <Route path="/Nativestock_gagas" element={<Nativestock_gagas />} />
        <Route path="/Nativestock_pdg" element={<Nativestock_pdg />} />
        <Route path="/psimodeller_pertagas" element={<Psimodeller_pertagas />} />
        <Route path="/psimodeller_tgi" element={<Psimodeller_tgi />} />
        <Route path="/psimodeller_pgn" element={<Psimodeller_pgn />} />
        <Route path="/psimodeller_nr" element={<Psimodeller_nr />} />
        <Route path="/psimodeller_pli" element={<Psimodeller_pli />} />
        <Route path="/psimodeller_kjg" element={<Psimodeller_kjg />} />
        <Route path="/psimodeller_pertasamtan" element={<Psimodeller_pertasamtan />} />
        <Route path="/psimodeller_lng" element={<Psimodeller_lng />} />
        <Route path="/psimodeller_widar" element={<Psimodeller_widar />} />
        <Route path="/psimodeller_saka" element={<Psimodeller_saka />} />
        <Route path="/psimodeller_gagas" element={<Psimodeller_gagas />} />
        <Route path="/psimodeller_pertagasniaga" element={<Psimodeller_pertagasniaga />} />
        <Route path="/Psimodeller_pertaarungas" element={<Psimodeller_pertaarungas />} />
        <Route path="/Psimodeller_pertadayagas" element={<Psimodeller_pertadayagas />} />
        <Route path="/Updateprofile" element={<Updateprofile />} />
        <Route path="/view3d" element={<View3d />} />
        <Route path="/Editfirebasedata" element={<Editfirebasedata />} />
        <Route path="/Createprofile" element={<Createprofile />} />
        <Route path="/History_login" element={<History_login />} />
        <Route path="/Listuser" element={user ? <Listuser /> : <Navigate to="/login" />} />
        <Route path="/Listuser_lrv" element={<Listuser_lrv />} />
        <Route path="/Pgpoc" element={<Pgpoc />} />
        <Route path="/page2" element={<Page2 />} />
        <Route path="/rimox" element={<Rimox />} />
        <Route path="/Muhlbauer_onshore" element={<Muhlbauer_onshore />} />
        <Route path="/rbi_calc" element={<RBIcalc />} />
        <Route path="/kontribusi" element={<Kontribusi />} />
        <Route path="/Alivemanual" element={<Alivemanual />} />
        <Route path="/testhover" element={<Testhover />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
