import axios from 'axios';
import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Lpgppoc = () => {
	const [isHovered, setIsHovered] = useState(false);
  
   const [userData, setUserData] = useState(null);
   const [token, setToken] = useState(null);
   const [rimoxKey, setRimoxKey] = useState(0);
   const [selectedPointOfConcern, setSelectedPointOfConcern] = useState(null);
   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	
	const [scrollPosition, setScrollPosition] = useState(0);
  const scrollRef = useRef(null);

  const handleScroll = () => {
    
    setScrollPosition(scrollRef.current.scrollTop); 
  };
  
   const [hoveredItem, setHoveredItem] = useState(null);
   let timeoutId;

  const handleMouseEnter = (item) => {
	
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
	
		setHoveredItem(null);
    
  
  };
	
	var ifr1 = {
	"api25102001": {
		"preview":"https://drive.google.com/file/d/12C6uicY1IBfKIuL21svcLTaWJSP2lFNO/preview",
		"view":"https://drive.google.com/file/d/12C6uicY1IBfKIuL21svcLTaWJSP2lFNO/view",
		"download":"https://drive.google.com/uc?export=download&id=12C6uicY1IBfKIuL21svcLTaWJSP2lFNO",
	},
	"NFPA58": {
		"preview":"https://drive.google.com/file/d/176JAjXO6AxVjV1aYj1eKCYfJcZiYFZXF/preview",
		"view":"'https://drive.google.com/file/d/176JAjXO6AxVjV1aYj1eKCYfJcZiYFZXF/view",
		"download":"https://drive.google.com/uc?export=download&id=176JAjXO6AxVjV1aYj1eKCYfJcZiYFZXF",
	},
	
		"panduan_fmea":{
			"preview": "https://drive.google.com/file/d/1enCE-4DFhfmLIvbr-wy5zzi-m6RraipX/preview",
			"view": "https://drive.google.com/file/d/1enCE-4DFhfmLIvbr-wy5zzi-m6RraipX/view",
			"download": "https://drive.google.com/uc?export=download&id=1enCE-4DFhfmLIvbr-wy5zzi-m6RraipX"
		},
		
		"rcm_prcm": {
			"preview": "https://drive.google.com/file/d/1gpG4meWl7zGhM8O-GAnwPV4F1bwoxvAY/preview",
			"view": "https://drive.google.com/file/d/1gpG4meWl7zGhM8O-GAnwPV4F1bwoxvAY/view",
			"download": "https://drive.google.com/uc?export=download&id=1gpG4meWl7zGhM8O-GAnwPV4F1bwoxvAY"
		},
		
		"iec613122012": {
			"preview": "https://drive.google.com/file/d/1Haal3_iPOrbTIKG4Dd4a56l9_4wWUF8d/preview",
			"view": "https://drive.google.com/file/d/1Haal3_iPOrbTIKG4Dd4a56l9_4wWUF8d/view",
			"download": "https://drive.google.com/uc?export=download&id=1Haal3_iPOrbTIKG4Dd4a56l9_4wWUF8d"
		},
		"nfpa7802020": {
			"preview": "https://drive.google.com/file/d/1WiWLnHPGSEy6cgc5R3Q9TavTo-ZEi97F/preview",
			"view": "https://drive.google.com/file/d/1WiWLnHPGSEy6cgc5R3Q9TavTo-ZEi97F/view",
			"download": "https://drive.google.com/uc?export=download&id=1WiWLnHPGSEy6cgc5R3Q9TavTo-ZEi97F"
		},
		"puil2011": {
			"preview": "https://drive.google.com/file/d/15Y4CUWinPxqm6mgZGN6AngFiNHUpxTUq/preview",
			"view": "https://drive.google.com/file/d/15Y4CUWinPxqm6mgZGN6AngFiNHUpxTUq/view",
			"download": "https://drive.google.com/uc?export=download&id=15Y4CUWinPxqm6mgZGN6AngFiNHUpxTUq"
		},
		"qra1":{
			"preview": "https://drive.google.com/file/d/1poUxS2bSb5K3J69DkoiO22C_5cwVmIPP/preview",
			"view": "https://drive.google.com/file/d/1poUxS2bSb5K3J69DkoiO22C_5cwVmIPP/view",
			"download": "https://drive.google.com/uc?export=download&id=1poUxS2bSb5K3J69DkoiO22C_5cwVmIPP"
		},
			
		"qra2":{
			"preview": "https://drive.google.com/file/d/1rOlJu_XCNIqjjybNR3JtZiFqxsG7Dmd3/preview",
			"view": "https://drive.google.com/file/d/1rOlJu_XCNIqjjybNR3JtZiFqxsG7Dmd3/view",
			"download": "https://drive.google.com/uc?export=download&id=1rOlJu_XCNIqjjybNR3JtZiFqxsG7Dmd3"
		},
			
		"qra3":{
			"preview": "https://drive.google.com/file/d/1tf6uMZctBexXrbmp-ogS_KQEi38K-E3m/preview",
			"view": "https://drive.google.com/file/d/1tf6uMZctBexXrbmp-ogS_KQEi38K-E3m/view",
			"download": "https://drive.google.com/uc?export=download&id=1tf6uMZctBexXrbmp-ogS_KQEi38K-E3m"
		},
		
		"rbi_api581":{
			"preview": "https://drive.google.com/file/d/1osr8BWdCnXAmmD3QreJJCQzYn47RptGn/preview",
			"view": "https://drive.google.com/file/d/1osr8BWdCnXAmmD3QreJJCQzYn47RptGn/view",
			"download": "https://drive.google.com/uc?export=download&id=1osr8BWdCnXAmmD3QreJJCQzYn47RptGn"
		},
		"rbi_api580":{
			"preview": "https://drive.google.com/file/d/1x_EBEBk1Ezgpw3E3C_SSswMkVKvd9Ewj/preview",
			"view": "https://drive.google.com/file/d/1x_EBEBk1Ezgpw3E3C_SSswMkVKvd9Ewj/view",
			"download": "https://drive.google.com/uc?export=download&id=1x_EBEBk1Ezgpw3E3C_SSswMkVKvd9Ewj"
		},
		"api_rp_5802002":{
			"preview": "https://drive.google.com/file/d/1esdU3rvLRtpjf6e7NadhsiolU-tTjlYM/preview",
			"view": "https://drive.google.com/file/d/1esdU3rvLRtpjf6e7NadhsiolU-tTjlYM/view",
			"download": "https://drive.google.com/uc?export=download&id=1esdU3rvLRtpjf6e7NadhsiolU-tTjlYM"
		},
		
		"nfpacalc7802020": {
			"preview": "https://drive.google.com/file/d/1xdf3X-AX60f3q23oU72r3BRjUfhIePjZ/preview",
		},
	"lpldc": {
			"preview": "https://www.youtube.com/embed/j2iBUVBsp1k?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=j2iBUVBsp1k",
		},
	"rsm":{
        "preview": "https://docs.google.com/spreadsheets/d/1dv216KVDgDzLEhW2KuS5H0bD0huuRGIl/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "view": "https://drive.google.com/file/d/1dv216KVDgDzLEhW2KuS5H0bD0huuRGIl/view",
        "download": "https://drive.google.com/uc?export=download&id=1dv216KVDgDzLEhW2KuS5H0bD0huuRGIl"
		},
	"lzopc":{
			"preview": "https://docs.google.com/spreadsheets/d/1TNp6jifftNF837e6khheC4BKxLR2T6u_/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
			"view": "https://drive.google.com/file/d/1TNp6jifftNF837e6khheC4BKxLR2T6u_/view",
			"download": "https://drive.google.com/uc?export=download&id=1TNp6jifftNF837e6khheC4BKxLR2T6u_"
		},
        "sphere_method":{
			"preview": "https://public.tableau.com/views/RollingSphereMethod/RollingSphereMethod?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
		},
		
		"fmecacalc":{
        "preview": "https://docs.google.com/spreadsheets/d/1vg8IIihbAO78dGKCUbfiylOy15yn-Wc_/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "view": "https://drive.google.com/file/d/1vg8IIihbAO78dGKCUbfiylOy15yn-Wc_/view",
        "download": "https://drive.google.com/uc?export=download&id=1vg8IIihbAO78dGKCUbfiylOy15yn-Wc_"
		},
		
};

var stasiunData = {

	PGNSSWJ: {
        "SKG SSWJ 2": "",
    },
    
    PTTransportasiGasIndonesia:{
        
        "Sakernan GBS":"",
        "Belilas GBS":"",
        "Jabung GBS":"",
    },
    
    PTPertaSamtanGas:{
        "Extraction Plant Prabumulih":"",
    },
    
    PTPertagas:{
        "SKG Benuang":"",
        "SKG Bitung":"",
        "SKG Bontang":"",
        "SKG Cambai":"",
        "SKG Cilamaya":"",
        "SKG Mundu":"",
        "SKG Pangkalan Brandan":"",
        "SKG Rantau Panjang":"",
        "SKG Tegal Gede":"",
    },
	
};
	
	const handleLinkClick = () => {
    setRimoxKey(prevKey => prevKey + 1); // Update key untuk refresh komponen Rimox
    tampilIfr(0,'rimox_rbi','bk_rimox_rbi','ttp_rimox_rbi');
  };
	
	const tampilIfr = (ifr,idfile,ida,ida2) => {
		
		document.getElementById('iframetbl2').src = "";
		allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
		
		if(ifr == 0){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				
				if(idfile == "rimox_rbi"){
					
					document.getElementById('prm_rimox').innerHTML='rbi';
					
				}else if(idfile == "rimox_fmeca"){
					
					document.getElementById('prm_rimox').innerHTML='fmeca';
					
				}
				document.getElementById('pilihstasiun').style.display="block";
				document.getElementById('rimox').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				
			}else{
				document.getElementById('rimox').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
				
			}
			
		}else if(ifr == 1){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('ifr'+ifr).style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl'+ifr).src = ifr1[idfile].preview;
			}else{
				document.getElementById('ifr'+ifr).style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}else if(ifr == 2){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('ifr'+ifr).style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl'+ifr).src = ifr1[idfile].preview;
			}else{
				document.getElementById('ifr'+ifr).style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}  else if(ifr == 3){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('ifr'+ifr).style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl'+ifr).src = ifr1[idfile].preview;
			}else{
				document.getElementById('ifr'+ifr).style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}
		
	};
	
	const [openSections, setOpenSections] = useState({});

	const toggleCollapse = (section) => {
		setOpenSections((prevState) => ({
		  ...prevState,
		  [section]: !prevState[section],
		}));
	};

	const handlePofChange = async (selectedOption) => {

	

	};
	
  const [pointOfConcernOptions, setPointOfConcernOptions] = useState([
    { value: '', label: 'Point of Concern' },
    { value: 'sfpoc', label: 'Station Facilities' },
    { value: 'nipoc', label: 'Network Infrastructure' },
    { value: 'repoc', label: 'Rotating Equipment' },
    { value: 'lngrpoc', label: 'LNG Regasification' },
    { value: 'lpgppoc', label: 'LPG Plant' },
    { value: 'cngrpoc', label: 'CNG Refueling' },
    { value: 'pgpoc', label: 'Power Generation' }
  ]);
  
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		ifr2css: {
			margin: '50px',
			width: 'calc(100vw - 270px)',
			height: '700px'
		},

		placeholder_tbl2css: {
			marginTop: '0px',
			marginLeft: '-0px',
			maxHeight: '700px',
			overflow: 'hidden',
		},

		iframetbl2css:{
			marginTop: '0px',
			width: 'calc(100vw - 270px)',
			height: '700px',
			border: '0px none', 
			height: '800px', 
			marginTop: '-60px'
		},
		
		iframetbl3css: {
			width: '1450px',
			height: '1250px',
		  },

		  ifr3css: {
			marginTop: '10px',
			width: 'calc(100vw - 200px)',
			height: '500px',
			overflow: 'hidden',
		  },
		  
		  placeholdertbl3css: {
			marginTop: '-0px',
			marginLeft: '-1px',
			width: '1450px',
			height: '1200px',
			overflowX: 'hidden',
			overflowY: 'hidden',
			scrollbarWidth: 'none',
		  },
		
		selectcompany:{
			margin: '0px 10px 0px 0px',
			width: '250px',
			float: 'left',
		},
		
		selectstasiun:{
			margin: '0px 0px 0px 0px',
			width: '250px',
			float: 'left',
		},
		
		
		divsides:{
			//position: 'relative', 
			overflow: 'visible',
		},
		
		divsides2:{
			float: 'left',
			marginLeft: '160px',
			position : 'absolute',
			width : '100px',
			background: 'transparance',
			borderRadius: '5px',
			textAlign: 'justify',
			padding: '5px',
			height: '100px',
			overflow: 'auto', 
			scrollbarWidth: 'none',
			color: 'white',
		},
		
		divsides3:{
			float: 'left',
			marginLeft: '250px',
			position : 'absolute',
			maxWidth : '400px',
			background: 'grey',
			borderRadius: '5px',
			textAlign: 'justify',
			padding: '5px',
			height: '100px',
			overflow: 'auto', 
			scrollbarWidth: 'none',
			color: 'white',
		},
		
		dividesdalam:{
			fontSize: '0px', 
			height: '50px', 
			width:'50px',
		},
		
	};

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit
    const hours = String(now.getHours()).padStart(2, '0'); // jam dalam format 2 digit
    const minutes = String(now.getMinutes()).padStart(2, '0'); // menit dalam format 2 digit

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

useEffect(() => {
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
			
			setUserData(response.data);			
			 
			try {
				const response = await axios.post('https://alive.my.id/restapialive3/api/data', {
				  tgl: getCurrentDateTime()
				}, {
				  headers: {
					Authorization: `Bearer ${storedToken}`,
					'Content-Type': 'application/json'
				  }
				});

				console.log('Data successfully sent:', response.data);
			} catch (error) {
				console.error('Error sending data:', error);
			}
            
          } else {
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();
	const divElement = document.getElementById('ifr3');

        divElement.addEventListener('mouseenter', function() {
            divElement.style.overflow = 'scroll';
        });

        divElement.addEventListener('mouseleave', function() {
            divElement.style.overflow = 'hidden';
        });
  }, [navigate]);
  
  useEffect(() => {
    if (selectedPointOfConcern) {
      navigate("/"+selectedPointOfConcern.value);
	  
    }
  }, [selectedPointOfConcern, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
	
		<div id="prm_entitas" style={{display: 'none'}}>PGN</div>
		<div id="prm_rimox" style={{display: 'none'}}></div>
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`}
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		<img style={{float: 'right', marginTop: '0px', marginRight: '15px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		  
		<div style={{ clear: 'both' }}></div>
		
		<div style={{ margin: '0px 10px 0px 0px', width: '250px', float: 'left' }}>
			<Select
			  styles={customStyles}
			  options={pointOfConcernOptions}
			  onChange={setSelectedPointOfConcern}
			  placeholder="Point of Concern"
			  value={selectedPointOfConcern}
			/>
		</div>
		
		<div style={{clear: 'both'}}></div>
	  
		<table>
			
			<tbody>
			<tr>
				<td align="left" valign="top" style={{width: '200px', fontWeight: '500'}}>
					
				<div style={{width: '100%', maxHeight: '560px', overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#888 #f1f1f1'}}>
				<div
					style={{
					  width: '100%',
					  maxHeight: '560px',
					  overflowY: 'auto',
					  scrollbarWidth: 'none',  // Menyembunyikan scrollbar
					}}
					className={`custom-scroll ${isHovered ? 'hover' : ''}`}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				  >
					{/*lightning_protection_system*/}
					<div style={{}}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('lightning_protection_system')}
						  onMouseEnter={() => handleMouseEnter('lightning_protection_systemhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'lightning_protection_systemhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Lightning Protection System (LPS) berfungsi untuk melindungi instalasi pipa gas dari efek berbahaya akibat sambaran petir. Pipa gas, baik yang berada di atas tanah maupun di bawah tanah, sangat rentan terhadap risiko sambaran petir yang dapat menyebabkan kebakaran, ledakan, atau kerusakan pada infrastruktur gas itu sendiri. LPS dirancang untuk mengurangi risiko ini dengan menyediakan jalur konduktif yang aman untuk arus petir agar langsung menuju tanah tanpa melalui jalur instalasi pipa gas.
									
								</div>
							 </>
						)}
							
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Lightning Protection System
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['lightning_protection_system'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[0] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_iec613122012" onClick={() => tampilIfr(1,'iec613122012','bk_iec613122012','ttp_iec613122012')}>IEC 61312/2012</a>
														<a ref={(el) => (allIframesRef.current[0] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_iec613122012" onClick={() => tampilIfr(1,'iec613122012','ttp_iec613122012','bk_iec613122012')}><b style={{color: 'black'}}>IEC 61312/2012</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 0px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[1] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_nfpa7802020" onClick={() => tampilIfr(1,'nfpa7802020','bk_nfpa7802020','ttp_nfpa7802020')}>NFPA 780-2020</a>
														<a ref={(el) => (allIframesRef.current[1] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_nfpa7802020" onClick={() => tampilIfr(1,'nfpa7802020','ttp_nfpa7802020','bk_nfpa7802020')}><b style={{color: 'black'}}>NFPA 780-2020</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '0px 0px 15px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[2] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_puil2011" onClick={() => tampilIfr(1,'puil2011','bk_puil2011','ttp_puil2011')}>Peraturan Umum Instalasi Listrik 2011 (PUIL)</a>
														<a ref={(el) => (allIframesRef.current[2] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_puil2011" onClick={() => tampilIfr(1,'puil2011','ttp_puil2011','bk_puil2011')}><b style={{underline: 'none',textDecoration: 'none', color: 'black'}} >Peraturan Umum Instalasi Listrik 2011 (PUIL)</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 15px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_calc'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[3] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_nfpacalc7802020" onClick={() => tampilIfr(1,'nfpacalc7802020','bk_nfpacalc7802020','ttp_nfpacalc7802020')}>NFPA 780 RAC (Risk Assessment Calculators)</a>
														<a ref={(el) => (allIframesRef.current[3] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_nfpacalc7802020" onClick={() => tampilIfr(1,'nfpacalc7802020','ttp_nfpacalc7802020','bk_nfpacalc7802020')}><b style={{color: 'black'}}>NFPA 780 RAC (Risk Assessment Calculators)</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 15px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[4] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_lpldc" onClick={() => tampilIfr(2,'lpldc','bk_lpldc','ttp_lpldc')}>Lightning Protection Layout Design/Calculation - Rolling Sphere Method</a>
														<a ref={(el) => (allIframesRef.current[4] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_lpldc" onClick={() => tampilIfr(2,'lpldc','ttp_lpldc','bk_lpldc')}><b style={{color: 'black'}}>Lightning Protection Layout Design/Calculation - Rolling Sphere Method</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 15px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[27] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_rsm" onClick={() => tampilIfr(1,'rsm','bk_rsm','ttp_rsm')}>Rolling Sphere Method</a>
														<a ref={(el) => (allIframesRef.current[27] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_rsm" onClick={() => tampilIfr(1,'rsm','ttp_rsm','bk_rsm')}><b style={{color: 'black'}}>Rolling Sphere Method</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 15px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[28] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_sphere_method" onClick={() => tampilIfr(3,'sphere_method','bk_sphere_method','ttp_sphere_method')}>Virtual Model Rolling Sphere Method</a>
														<a ref={(el) => (allIframesRef.current[28] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_sphere_method" onClick={() => tampilIfr(3,'sphere_method','ttp_sphere_method','bk_sphere_method')}><b style={{color: 'black'}}>Virtual Model Rolling Sphere Method</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 15px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_lzopc" onClick={() => tampilIfr(1,'lzopc','bk_lzopc','ttp_lzopc')}>LPS Zone of Protection Calculator</a>
														<a ref={(el) => (allIframesRef.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_lzopc" onClick={() => tampilIfr(1,'lzopc','ttp_lzopc','bk_lzopc')}><b style={{color: 'black'}}>LPS Zone of Protection Calculator</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_lastreport'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														Download
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 0px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														Print
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end lightning_protection_system*/}
					
					{/*quantitative_risk_assesment*/}
					<div style={{}}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('quantitative_risk_assesment')}
						  onMouseEnter={() => handleMouseEnter('quantitative_risk_assesmenthover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'quantitative_risk_assesmenthover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Quantitative Risk Assessment (QRA) adalah metode penilaian risiko yang digunakan untuk menghitung risiko terkait dengan operasional dan insiden yang mungkin terjadi pada jaringan pipa gas. QRA memberikan penilaian yang numerik untuk berbagai jenis bahaya dan potensi kecelakaan, seperti kebocoran gas, ledakan, atau kebakaran. Tujuan dari QRA adalah untuk mengidentifikasi risiko-risiko ini, mengevaluasi kemungkinan dan dampaknya, serta menentukan langkah-langkah mitigasi yang tepat untuk meminimalkan risiko tersebut.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Quantitative Risk Assesment
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['quantitative_risk_assesment'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('quantitative_risk_assesment_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['quantitative_risk_assesment_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('quantitative_risk_assesment_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['quantitative_risk_assesment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('quantitative_risk_assesment_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('quantitative_risk_assesment_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['quantitative_risk_assesment_lastreport'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[20] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_qra1" onClick={() => tampilIfr(1,'qra1','bk_qra1','ttp_qra1')}>PGN SENA QRA Bojonegara Assumption Register Rev.B2 Signed by PIH-signed</a>
														<a ref={(el) => (allIframesRef.current[20] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_qra1" onClick={() => tampilIfr(1,'qra1','ttp_qra1','bk_qra1')}><b style={{color: 'black'}}>PGN SENA QRA Bojonegara Assumption Register Rev.B2 Signed by PIH-signed</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 0px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[21] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_qra2" onClick={() => tampilIfr(1,'qra2','bk_qra2','ttp_qra2')}>PGN-JK-F005-EPS-RE-0204_Quantitative Risk Assessment Report_Rev 0</a>
														<a ref={(el) => (allIframesRef.current[21] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_qra2" onClick={() => tampilIfr(1,'qra2','ttp_qra2','bk_qra2')}><b style={{color: 'black'}}>PGN-JK-F005-EPS-RE-0204_Quantitative Risk Assessment Report_Rev 0</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '0px 0px 15px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[22] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_qra3" onClick={() => tampilIfr(1,'qra3','bk_qra3','ttp_qra3')}>Process Safety Studies for PGN Bojonegara Station - PT Perusahaan Gas Negara v1</a>
														<a ref={(el) => (allIframesRef.current[22] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_qra3" onClick={() => tampilIfr(1,'qra3','ttp_qra3','bk_qra3')}><b style={{underline: 'none',textDecoration: 'none', color: 'black'}} >Process Safety Studies for PGN Bojonegara Station - PT Perusahaan Gas Negara v1</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end quantitative_risk_assesment*/}
					
					{/*FMEA/FMECA*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('fmeafmeca')}
						  onMouseEnter={() => handleMouseEnter('fmecahover')}
						  onMouseLeave={handleMouseLeave}
						>
							{hoveredItem === 'fmecahover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
							  <div style={{
								...styles.divsides3,
								marginTop: `-${scrollPosition}px`,
							  }}>
								  FMEA (Failure Mode and Effect Analysis) adalah metode kualitatif yang digunakan untuk mengidentifikasi potensi mode kegagalan dalam suatu proses dan menganalisis dampaknya. Metode ini membantu tim mengevaluasi risiko yang terkait dengan kegagalan tersebut. FMECA (Failure Mode, Effects, and Criticality Analysis) mengembangkan FMEA dengan menyediakan analisis kualitatif dan kuantitatif, yang memungkinkan pengguna menilai kekritisan setiap mode kegagalan secara lebih efektif. Kedua teknik tersebut sangat penting untuk meningkatkan keandalan dan keselamatan dalam proses dengan mengidentifikasi dan memprioritaskan potensi kegagalan.
							  </div>
							 </>
							)}
							
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											FMECA (Failure Modes And Effects Criticallity Analysis) / FMEA (Failure Modes And Effects Analysis)
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['fmeafmeca'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('fmeafmeca_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['fmeafmeca_ref'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<ul>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[12] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_panduan_fmea" onClick={() => tampilIfr(1,'panduan_fmea','bk_panduan_fmea','ttp_panduan_fmea')}>Panduan Failure Modes and Effects Analysis (FMEA)</a>
															<a ref={(el) => (allIframesRef.current[12] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_panduan_fmea" onClick={() => tampilIfr(1,'panduan_fmea','ttp_panduan_fmea','bk_panduan_fmea')}><b style={{color: 'black'}}>Panduan Failure Modes and Effects Analysis (FMEA)</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									 
									 </div>
								
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('fmeafmeca_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['fmeafmeca_calc'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										<ul style={{margin: '-15px 0px 15px 0px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{fontSize: '12px', verticalAlign: 'top' }}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[30] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_fmecacalc" onClick={() => tampilIfr(1,'fmecacalc','bk_fmecacalc','ttp_fmecacalc')}>FMECA Calc</a>
															<a ref={(el) => (allIframesRef.current[30] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_fmecacalc" onClick={() => tampilIfr(1,'fmecacalc','ttp_fmecacalc','bk_fmecacalc')}><b style={{color: 'black'}}>FMECA Calc</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									</div>
								
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('fmeafmeca_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['fmeafmeca_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('fmeafmeca_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['fmeafmeca_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*FMEA/FMECA*/}
					
					{/*insulation*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('insulation')}
						  onMouseEnter={() => handleMouseEnter('insulationhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'insulationhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>
									
									Insulation adalah proses pelapisan pipa dengan bahan isolasi untuk menjaga suhu gas dalam pipa tetap stabil, melindungi pipa dari kondisi lingkungan, mencegah kondensasi, dan meningkatkan efisiensi energi. Isolasi pipa gas sangat penting dalam operasi di industri migas (minyak dan gas) untuk memastikan keamanan, keandalan, dan efisiensi jaringan distribusi gas.
									
								</div>
							 </>
						)}
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Insulation
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['insulation'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('insulation_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['quantitative_risk_assesment_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('insulation_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['quantitative_risk_assesment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('insulation_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('insulation_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['quantitative_risk_assesment_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end insulation*/}
					
					{/*Risk Based Inspection (RBI)*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('risk_based_inspection')}
						  onMouseEnter={() => handleMouseEnter('risk_based_inspectionhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'risk_based_inspectionhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
							<div style={{
								...styles.divsides3,
								marginTop: `-${scrollPosition}px`,
							}}>
							
								Risk Based Inspection (RBI) adalah metodologi analisis yang digunakan untuk mengidentifikasi dan memahami risiko, faktor pemicu risiko, dan posisi peralatan dalam siklus hidupnya untuk memfokuskan upaya dan sumber daya inspeksi pada aset berisiko tinggi. Sementara metodologi inspeksi preskriptif tradisional menangani semua aset dengan interval yang ditetapkan, metodologi RBI justru merekomendasikan inspeksi atau aktivitas lain berdasarkan tingkat risiko yang dihitung untuk setiap aset.

								Menurut API RP 580, RBI terutama difokuskan pada pemeliharaan integritas mekanis peralatan bertekanan dan meminimalkan risiko yang terkait dengan hilangnya penahanan akibat kerusakan. Program RBI yang diterapkan dengan benar mengkategorikan masing-masing peralatan berdasarkan risikonya, memprioritaskan upaya pemeriksaan, dan memberikan panduan untuk upaya mitigasi risiko, seperti perubahan bahan konstruksi, penambahan lapisan, perubahan kondisi pengoperasian, dll.
								
							</div>
							 </>
						)}
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Risk Based Inspection (RBI)
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['risk_based_inspection'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_based_inspection_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['risk_based_inspection_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[5] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_rbi_api580" onClick={() => tampilIfr(1,'rbi_api580','bk_rbi_api580','ttp_rbi_api580')}>API 580</a>
														<a ref={(el) => (allIframesRef.current[5] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_rbi_api580" onClick={() => tampilIfr(1,'rbi_api580','ttp_rbi_api580','bk_rbi_api580')}><b style={{color: 'black'}}>API 580</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 15px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[6] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_rbi_api581" onClick={() => tampilIfr(1,'rbi_api581','bk_rbi_api581','ttp_rbi_api581')}>API 581</a>
														<a ref={(el) => (allIframesRef.current[6] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_rbi_api581" onClick={() => tampilIfr(1,'rbi_api581','ttp_rbi_api581','bk_rbi_api581')}><b style={{color: 'black'}}>API 581</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 15px 0px'}}>
										<li>
											<table>
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[23] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_api_rp_5802002" onClick={() => tampilIfr(1,'api_rp_5802002','bk_api_rp_5802002','ttp_api_rp_5802002')}>API RP 580-2002</a>
														<a ref={(el) => (allIframesRef.current[23] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_api_rp_5802002" onClick={() => tampilIfr(1,'api_rp_5802002','ttp_api_rp_5802002','bk_api_rp_5802002')}><b style={{underline: 'none',textDecoration: 'none', color: 'black'}} >API RP 580-2002</b></a>
													</td>
												</tr>
												</tbody>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_based_inspection_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['risk_based_inspection_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_based_inspection_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['risk_based_inspection_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('lightning_protection_system_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end RBI*/}
					
					{/*Process Hazard Analysis*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('pha')}
						  onMouseEnter={() => handleMouseEnter('process_hazard_analysishover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'process_hazard_analysishover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
							<div style={{
								...styles.divsides3,
								marginTop: `-${scrollPosition}px`,
							}}>
							
								Process Hazard Analysis (PHA) adalah metodologi sistematis untuk mengidentifikasi, mengevaluasi, dan mengendalikan bahaya yang terkait dengan proses industri, termasuk jaringan pipa gas. Dalam konteks pipa gas, PHA digunakan untuk mengidentifikasi risiko yang mungkin terjadi selama operasi perpipaan, seperti kebocoran, ledakan, kebakaran, dan kegagalan mekanis, serta untuk menentukan langkah-langkah mitigasi yang dapat diambil untuk meminimalkan dampak potensialnya.
								
							</div>
							 </>
						)}
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Process Hazard Analysis
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['pha'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pha_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['pha_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pha_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['pha_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pha_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['pha_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pha_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['pha_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Process Hazard Analysis*/}
					
					{/*Piping Assesment Stasiun Gas*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('pasg')}
						  onMouseEnter={() => handleMouseEnter('piping_asessment_stasiun_gashover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'piping_asessment_stasiun_gashover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
							<div style={{
								...styles.divsides3,
								marginTop: `-${scrollPosition}px`,
							}}>
							
								Piping Assessment (Penilaian Pipa) pada Stasiun Gas adalah proses evaluasi kondisi pipa dan komponen terkait di stasiun gas untuk memastikan bahwa mereka beroperasi dengan aman, efisien, dan sesuai dengan standar teknis dan regulasi yang berlaku. Penilaian ini bertujuan untuk mengidentifikasi potensi masalah, kegagalan, atau kebutuhan pemeliharaan yang mungkin diperlukan untuk menjaga integritas jaringan pipa gas.

							</div>
							 </>
						)}
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Piping Assesment Stasiun Gas
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['pasg'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pasg_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['pasg_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pasg_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['pasg_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pasg_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['pasg_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pasg_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['pasg_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Piping Assesment Stasiun Gas*/}
					
					{/*RCM (Reliability Centered Maintenance)*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('RCM')}
						  onMouseEnter={() => handleMouseEnter('rcmhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'rcmhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
							<div style={{
								...styles.divsides3,
								marginTop: `-${scrollPosition}px`,
							}}>
							
								RCM (Reliability Centered Maintenance)
								Salah satu tujuan dari maintenance adalah untuk memastikan bahwa suatu aset fisik tetap melakukan tugasnya sesuai parameter yang diinginkan oleh penggunanya. Dalam hal ini, Reliability Centered Maintenance (RCM) mempunyai peranan penting dalam melakukan maintenance. RCM (Reliability Centered Maintenance) merupakan sebuah proses atau metode yang digunakan untuk menentukan kebutuhan maintenance suatu aset fisik agar tetap bisa melakukan tugasnya. Berikut merupakan tahapan–tahapan dari maintenance strategy RCM (Reliability Centered Maintenance) :
								
								<br/>
								a. Asset Register merupakan backbone dari semua tahapan yang ada. Hal ini dikarenakan asset register merupakan daftar aset fisik yang nantinya akan dilakukan maintenance untuk kedepannya. Asset register dibuat berdasarkan ketentuan dari perusahaan dan standard yang ada.
								<br/>
								b. Criticallity Ranking merupakan nilai tingkat kekritisan yang menempel pada suatu aset fisik berdasarkan matriks PoF (Probality of Failure) dan CoF (Consequence of Failure), sehingga nantinya akan dihasilkan apakah suatu aset fisik masuk dalam tingkat kekritisan high, medium, dan low.
								<br/>
								c. FMEA dan Task Development disini mendefinisikan failure mode dengan basis equipment. Dan di setiap failure mode akan mempunyai maintenance task ataupun tindakan pencegahan yang dibutuhkan. Penentuan task mana yang tepat untuk digunakan, dapat mengacu pada RCM Decision Diagram.
								<br/>
								Dalam penentuan FMEA ini juga harus menjawab 7 Basic Question yang ada:
								<br/>
								&nbsp; 1. What is the function?
								<br/>
								&nbsp; 2. In what ways does the function fail?
								<br/>
								&nbsp; 3. What causes each functional failure?
								<br/>
								&nbsp; 4. What happens when each failure occurs?
								<br/>
								&nbsp; 5. In what way does each failure matter?
								<br/>
								&nbsp; 6. What can be done to prevent each failure?
								<br/>
								&nbsp; 7. What should be done if a suitable preventive task cannot be found?
								<br/>
								&nbsp; d. Bundling and Levelling. Job Bundling merupakan tahapan yang akan menyatukan aktivitas untuk maintenance task yg sejenis, maintenance task di lokasi yg sama, atau maintenance-maintenance task pada peralatan yg sama. Levelling merupakan tahapan yang akan mendistribusikan load maintenance, dimana maintenance load akan dibandingkan dengan resources yang tersedia. Disini nantinya akan terlihat apakah resources sudah sesuai dengan load yang ada atau belum.
								<br/>
								&nbsp; e. Setelah levelling selesai maka tahapan selanjutnya adalah pembuatan check list dan melakukan upload ke CMMS. Setelah uplaod selesai, maka maintenance stategy RCM yang sudah dibuat dapat dijalankan, dievaluasi, dan dilakukan improvment jika dibutuhkan.
											
								
							</div>
							 </>
						)}
						
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											RCM (Reliability Centered Maintenance)
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['RCM'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('RCM_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['RCM_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
											
												<tbody>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[12] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_rcm_prcm" onClick={() => tampilIfr(1,'rcm_prcm','bk_rcm_prcm','ttp_rcm_prcm')}>Panduan Reliability Centered Maintenance</a>
														<a ref={(el) => (allIframesRef.current[12] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_rcm_prcm" onClick={() => tampilIfr(1,'rcm_prcm','ttp_rcm_prcm','bk_rcm_prcm')}><b>Panduan Reliability Centered Maintenance</b></a>
													</td>
												</tr>
												</tbody>
												
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('RCM_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['RCM_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('RCM_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['RCM_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('RCM_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['RCM_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end RCM (Reliability Centered Maintenance)*/}
					
					{/*LNG Calculator*/}
					
					<div
						style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						onClick={() => toggleCollapse('lpg_ref')}
						onMouseEnter={() => handleMouseEnter('lpgrefhover')}
						onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'lpgrefhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
							<div style={{
								...styles.divsides3,
								marginTop: `-${scrollPosition}px`,
							}}>
							
								Reference File
								
							</div>
							 </>
						)}
							<ul>
								<li>
									<span className="lbn" style={{ fontSize: '12px'}}></span>
										Reference
								</li>
							</ul>
					</div>
					{openSections['lpg_ref'] && (
					<div style={{ margin: '-10px 0px 0px -15px', fontSize: '13px' }}>
						<ul>
							<li>
								<table>
									<tbody>
										<tr>
											<td valign="top">
												<span className="lbn" style={{ fontSize: '12px'}}></span>
											</td>
											<td>
												<a ref={(el) => (allIframesRefbk.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_api25102001" onClick={() => tampilIfr(1,'api25102001','bk_api25102001','ttp_api25102001')}>api.2510.2001</a>
												<a ref={(el) => (allIframesRef.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_api25102001" onClick={() => tampilIfr(1,'api25102001','ttp_api25102001','bk_api25102001')}><b>api.2510.2001</b></a>
											</td>
										</tr>
									 </tbody>
								</table>
							</li>
						</ul>
						
						<ul>
							<li>
								<table>
									<tbody>
										<tr>
											<td valign="top">
												<span className="lbn" style={{ fontSize: '12px'}}></span>
											</td>
											<td>
												<a ref={(el) => (allIframesRefbk.current[16] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_NFPA58" onClick={() => tampilIfr(1,'NFPA58','bk_NFPA58','ttp_NFPA58')}>NFPA 58</a>
												<a ref={(el) => (allIframesRef.current[16] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_NFPA58" onClick={() => tampilIfr(1,'NFPA58','ttp_NFPA58','bk_NFPA58')}><b>NFPA 58</b></a>
											</td>
										</tr>
									 </tbody>
								</table>
							</li>
						</ul>
					</div>
						
					
					)}
					
					{/*LNG Calculator*/}
				</div>
				</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 200px)'}}>
					
					<div style={{width: '100%', height: '100%', overflow: 'auto'}}>
						<img 
							id="homealive_samping_sidemenu"
							src={`${process.env.PUBLIC_URL}/gambar/alive_poc.jpeg`} 
							alt="Alive home" 
							style={{width: '98%', height: '73vh', margin: '10px'}}
						/>
						
						<div ref={(el) => (allIframesRef.current[8] = el)} id="ifr1" style={{ width: '100%', height: '75vh', margin: '10px', display: 'none'}}>
							<iframe id="iframetbl1" src="" style={{width: '100%', height: '100%'}}></iframe>
						</div>
						
						<div style={styles.ifr2css} ref={(el) => (allIframesRef.current[14] = el)} id="ifr2" style={{display: 'none', marginTop: '10px'}}>
							<div style={styles.placeholder_tbl2css} id="placeholder_tbl2">
								<iframe id="iframetbl2" style={styles.iframetbl2css}  src="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
						</div>
						
						<div ref={(el) => (allIframesRef.current[28] = el)} id="ifr3" style={{ display: 'none', ...styles.ifr3css }}>
							<div id="placeholder_tbl3" style={styles.placeholdertbl3css}>
								<iframe id="iframetbl3" src="" style={styles.iframetbl3css}></iframe>
							</div>
						</div>
						
					</div>
					
				</td>
			</tr>
			</tbody>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Lpgppoc;
