import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Nativestock_sor1 = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	 var photostocklist = {
        
        BELAWAN:{
            "1":"13boJw1yIrIVFu_ZrjW7YFG4DG3BzlszK",
            "2":"16B9vTKy-Ecyw7V_RUtqNjGrLqZUd1PrO",
            "3":"1yREX1cC4x9BWKaU4jWOZAq5ETdlrSspZ",
            "4":"1oCZM9C_w-uQwQMKN75qgNnLeGaJLhvLO",
            "5":"1qir7-BHntUEqxWZNyOu6oWTco7C5zC0V",
            "6":"15e7WA2KlWYs_zVBtXilS9-IoF_Ru9GeK",
            "7":"1DsbnOcmFBGhYUJXK92MYfWijCU-epGh_",
            "8":"1IfWQJJKiDmZ7ZHpznvzZZddnMqph5cIb",
            "9":"1wyqoFw04l7xE8jslioo3F-l0-a_H9sal",
        },
        
        SICANANG:{
            "1":"1812Dt0eZDQ0ktdx1rJ6ycZTDBCRzd3IG",
            "2":"1LOZBrYhLPCiO4aOT-YT5dduYNT4B9NQj",
            "3":"1WwEOwrfQhrr27e-t4S0xBpYTqbiiasHZ",
            "4":"1rmfB7odaS4PZWb-_pGzD7zvcRyffbyPG",
            "5":"1Z5_FyW8oD6ZbHGi2LpFOEp3rLrk3dLFr",
        },
        
        PAYA_PASIR:{
           "1":"1Dpvd9-yXw8xDXssmbjLbC-k3d4ykWB2s",
            "2":"16TvFBXgMpeXqkFEcw3BJIzV10l4SFWFc",
            "3":"187nlnNth9jpipGCpUt4zygABj2A_YPbY",
            "4":"1GRL8WS2JRdaRfpgWji9YWS0XvMT4i5EJ",
            "5":"1QDFtZuhvPQ1UGFlz2oxy9zeAbItcos3g",
            "6":"1XaGDAdI-PjpNjJU09437jH9I1l4gdxV4",
            "7":"1cMtnck-xozZCH9EIaqfG1QNJ_V4DTahk",
            "8":"1inHwERbDh9nvzlEMWh5Xjdvjt-zwNRZn",
            "9":"1p26NVntMipkGWVhZfk5_8IJ0sReNRjUv",
            "10":"1EmKNUPiWSTruTdh6hSYER61pwXsMY1Rf",
            "11":"1f5QalrAqV0KOMpnIQTPsj1Jq37Zqb4-I",
        },
        
        PASAR_IX:{
            "1":"1egVRS53hRJ8NIakq3gd21-XOmL_pYxnb",
            "2":"1DTYMJ3V56Feo9No1w_7_HsK-gSdadwoe",
            "3":"1_dv0SotpWQH7AxUBspLCOCYJi-CPa4xS",
            "4":"1q1PHJeMVT_kdUWDhMx4x6C7Hlfi8ZwFB",
            "5":"1hwtmA4y4qYG-erWx1D4fxDFDZ1BzT-u8",
            "6":"1B1bJX2cM13ZSaY17D4gNUdvZH9AKtJGY",
        },
        
        PANARAN:{
            "1":"1HCotY7n5G8AWDArvfwovHzu_g052_0SN",
            "2":"1Xr0yJ5znrX4LkiV7WRMHsOwNTaffGXrT",
            "3":"1X7gNzn_phsvf83x12TvnJ6-oDey7W-Yh",
            "4":"1cc90EtSm66Ves1vmdl4GEzLpjhD4uUSE",
            "5":"1mf47n_slINdI7briJpKl_Z6ac0EUFOfQ",
            "6":"1pOkoxyvEr2kiqlcrezGjaQ6YLXyBInmG",
            "7":"1Tjxx8fU4pr-UiPS06eKeLKlS6q9wWClU",
            "8":"1vktCIcu8pEOqH9KWVzAybEkIYmMoi2k3",
            "9":"1s7Ypv7bLTEgn4H6KDFZn2U_2xYJUMzNS",
        },
        
        DUMAI:{
            "1":"1ZFj2sft5n64GHgTKxvxHVpu-zo2fGIAD",
            "2":"1_4G2B1KV1U1Vtdt3nBJxim4bgv0FlzkA",
            "3":"1ukOPodOpJIWWs9snFj8p9GKE0Ri7OL9q",
            "4":"1xntsrNb0K74vfMyluZWOUuvmLl6agBfS",
            "5":"1BWXO360SjJzzLKTLI8vBsEZDeFPS6qrv",
            "6":"1E2G7YUPmAU8rF6LYdmq0Hj-zwXm1ufJH",
            "7":"1Ev1qTJczo5dU8GIKVzvK0Um_veMSGcvS",
            "8":"1PigFoKrXFXK0NcHfoGsgWkXV9upV0jJs",
            "9":"1UtLRk7v631iK4b9w4vX3cARO7ajSoIVC",
            "10":"1lRwzddRcLmBv-C5FcB7jWxVlrJxVtpmg",
            "11":"1DP3HKZsAHiUe7D6_dNLyjI69y1sxls40",
            "12":"1DksXHhEWi4z6K7YYESyDwO_EclV6MsPK",
            "13":"1FiBQAQ7K2NgI6_ZaEnY6xUF-g8oZR_m5",
            "14":"1KZl2r8VcAgbUtqbU7TR-9FJN-cZD9Ymy",
            "15":"1QXF8_FVBypFoJXXNLCsdVz0h9pUXGNRF",
            "16":"1Txivg-eCUS_XFqbAxQXZcUq_aiHbkEAG",
            "17":"1VBCFcoMbRrzgEFUa9Ag4Lennw_0nrnCY",
            "18":"1WvsS2242X9v_HjhyIT2KpfF0XH2gfO3q",
            "19":"1dq04XjDutsnnvf7HKlyb85A09pRKV04R",
            "20":"1pgfLpPwxYZkc65wtmrl4DYR4oAxMPh0W",
            "21":"1qQrLXPudvv821z5z4Z4xi59fHES5qxfO",
            "22":"1zFUJUd7SyRF4RBd4fK2HlHgFBjQqWJTq",
            "23":"1zia8DotQRk-FaEVeQ3IaRgwM-Zs0kj07",
            "24":"1ea23DtkAiN3GDbszSrtd8XPhlpPQGNpj",
            "25":"1KuFJ2hLFbgn_pTRRAuijyS70V18STHSB",
            "26":"1PilHV9U7Fig7t4g7zQ6VkUS1d-oK7be-",
            "27":"1_6F-FTSVqlxfhOjEDaiNjChhxEvvCgzf",
            "28":"1kr1EHVINGAiDw-6PMTxhAtCtEvfEm1rI",
            "29":"1O7KblOg86K3d_AgXLHoPihsV68Mh6Wgn",
            "30":"1Y8TLUfQyJEwpjugbcfJyZFUcSjH1mpb7",
            "31":"1hQ4O-16UD_bjDHasu7nI35AnbE77eeV1",
            "32":"1lNVRKbua66QWRAnyKQH5DRSC-_gzaReO",
            "33":"15MIM_-KpZSbT23F1nzrkFWxQFtph8E8b",
            "34":"1BKWj6AxOFxRdHjIL55qAyKFNq3Or38GF",
            "35":"1E5Je8unqR_hjq63pNKFTJ6fcMvkGH6GW",
            "36":"1FWKwpXy1iXtF7IEzP0nHV0d_tcr464ct",
            "37":"1Gn40F4fKZHVCqKW39OqcFFpI1cBEZYjN",
            "38":"1Y1wIP1meZa7rrVJNWNpS-b1XVidWon6-",
            "39":"1iHurHfAcva2_ivzRVKz3jJ7jMefrzJ3_",
            "40":"1tBo_rvunYZfjFmpkuzfUrtV201Ul0onx",
            "41":"139yKwadHI1h5O98qMBiKwiIcR19EBs8h",
            "42":"19R6Z5R7QfGLBEz0FAVj3QUW7sGiKiuB1",
            "43":"1gS-aUpJN6XqghnuJuBvTnGqL9xIHuIEO",
            "44":"1gj06ZVFGaa7ADUtmz4-sJ_BWLhDq12Eg",
            "45":"1-shqHtyJGxTcU1NDlReF1HKCnCws6FjL",
            "46":"118jB4eajLC6fnq85by5JU3Lx0jCy2cXD",
            "47":"12qq0P3NQUZM9qWb7dnvvhdeL-AVxK9N0",
            "48":"14nqn8YGTruS3ktcYqPlPA9Ixi9mOOCdW",
            "49":"1A9m5aV8Cnoj9bRS7tRcbEYnQcOXTtmFP",
            "50":"1AYswGH5cENqawspQMD9IAQ210Ud4Ccdw",
            "51":"1BCM9xOuc6nMkqr4EDhYQNXuSHi2HxN8K",
            "52":"1CSGVauOnkCpxjZkf6R2SePVO2vWjebq1",
            "53":"1CnwKnncYJaXGsRc52v6xFzvwHnF_StuW",
            "54":"1D_hkFl1HbvSqLOkiWC9qMKN3_P6GadEb",
            "55":"1Uf9YXWjO9dENrAuZwepEPN5gNR4P2jwG",
            "56":"1Y9HBthGJ1Wb9_EBQ6GTSTZyXzX0jseuz",
            "57":"1cr-LpcMPdCIlYbLegc1U0txqORPUzGOO",
            "58":"1fkUwMklkN34FGPQRVZtNpIpllpYZW447",
            "59":"1isMzt3NuHhFIhweABTlyeovnEsXbPrDS",
            "60":"1lexQscucv2DzDk9_5Nalq0iyj7cUsAaN",
            "61":"1mFfhb43-BhrLg0tu1yzYi5z0ws793_wn",
            "62":"1xAc2GN5O95fRNDbtc0LlnCvA9HQCVnKF",
            "63":"19DWikwDXpfkTTPT27-yUpbukvaCNgbgF",
            "64":"1KXS3vRn9DbqUpmUOwIzSnNPLPj40cYpi",
            "65":"10E6upnABFPS3FQw3joPR1ToTboji8s6s",
            "66":"183Ok9R6zKsPq7iVZiCERBAOywgNCRMGM",
            "67":"19HYXt_vt7ON_uvRvUATZoK9PJNNm3gwO",
            "68":"19PB2kHrzP-QdXpSPN7WICJoKaaBX7QXP",
            "69":"1AYb8arVca4cttkstnsU3qAy8WhA70Sxp",
            "70":"1Cz3E71l5UzP61954Tf0T0cLr6ftdm10B",
            "71":"1DjR2gKtxWERsS92yEqIXwlfxwSzP9S5L",
            "72":"1HUU28DeoBmhk66nG2AtDraOwlLtwdD9Y",
            "73":"1J43BwQMMw7u1IUT98WRPCoMG-YnGtd_B",
            "74":"1Ky9XsRD8PAuxM-OYahEgsGz8mbENcn_2",
            "75":"1O8B6FdfpjTngjqpH9GWITf7HhcKDExTz",
            "76":"1OJnq97IaJJfLKkOMZzbT11pkxUX3AfWE",
            "77":"1PNdLjGTJZqSpV3HtnydqBhqwMQJyAW_E",
            "78":"1QXhqXHerab0ahYxI1i5MUdzHM32ALsJH",
            "79":"1Rl8k70cl0fX89hd6eBfbA1Gex_M9Orin",
            "80":"1SL_LB2QqYrCneEYoIqr9Bmb_cp157njT",
            "81":"1WgKYbBbLuvSFTUcXkU0nu4Qi68bR_Lai",
            "82":"1Y29zY85aym62tfmr_z30jfw-WCWad-zB",
            "83":"1agMh9mNPqDAuFMauWwgdpFtQuKU8CRyC",
            "84":"1bmk6vuacnGKHJz9ePXy6N73VonuEpSlR",
            "85":"1bwgmfXi6-cytPJlV-FPvEHA1RVC-hHtF",
            "86":"1cEGrvqUbN4CSSjgu7FV4vF9DQWUFhLrX",
            "87":"1e-QkxL8qhI7FAJywwzP3966IRfUxEOmX",
            "88":"1iJnyeCBw8Wsx9R0fFtM_Q4TO4Y-Y-vF-",
            "89":"1iYWZxHCqyFCIhaydK8WpyNMIggDs1GAc",
            "90":"1joaArCcGY93jkaItas9yrA5E8OjneUpQ",
            "91":"1lCqMUc9JJhJugxUIL4btiB3lTHcy74dW",
            "92":"1lSm8B2PCuadgRg32zW5B2gH5jG2aiIme",
            "93":"1lhVPm6hUzj1-boCL5nJ0P_Q2s_CvJW17",
            "94":"1oCXwquvKhFADJjJHoNUNd5egelSIVn6V",
            "95":"1pJgFEW0hyBtwsvF0-S2i0J6ASu0whw8K",
            "96":"1rd0Zkg5R7ZZ9Fj6_4rh0dqvJhU0VgEy6",
            "97":"1tBTp2QBXabVNf_nEDkiGlgyd2s4x-NiJ",
            "98":"1tV-l1_si6CORu5X7na1oZNSU80S0bIEz",
            "99":"1u-X4yMc1LzMkUuIeDh06e2w29QFGN5OX",
            "100":"1wHkqIfR9FbPSAquKyVOqcifxwYNtTD2a",
            "101":"10GKPi4rTJqpaH4wyxg4bL4IzdMfnabqG",
            "102":"12rsUavwfUCDlAnCxIlKxJb94p05iAXrV",
            "103":"13nVE3717NrF3XSsg_ptbe7VdgbJsK1Px",
            "104":"1AOWg8krYbf41SuvfExt-tSDeOOomsTeY",
            "105":"1JqznBD2RxOg-7GDplTF2lEd8obJJ8sbE",
            "106":"1QW2CHWd8NgQNoc0Kv3G-yWU2yUBgxgrR",
            "107":"1Tni0iaYKncRQoqMMI2rsxdSlCUD_sSre",
            "108":"10CbzeQFVdE94tLTDlHFD3PGlWCpRqTHU",
            "109":"11jeNBX3HhWtEiRKqMyQ7Gqpv3CMHhrgl",
            "110":"14dEfF6jjbmmpKsbYnlCwACwHW_uDGnCW",
            "111":"19b1taZmKR7NeSO2nYmt4qIGRLXSGO8ZU",
            "112":"1ItoGzlYzwYkBbr9qU51d0BM3L7eC_glT",
            "113":"1Kd0FMj6VKRAmLlIWG9h6-2oZLvrjZM_G",
            "114":"1P7YdFvq2g7sMPfl_266GVUn8fw3IuPDw",
            "115":"1VzoMa7emf_rFSWbNYYsBV1eT9aGz8uJp",
            "116":"1W0Op6rPWYNX3r_6dO5HQln0vy7kZhpj-",
            "117":"1pP0ArgF9JLG_F8MLL-xGS5WkNa3kIcuc",
            "118":"1r0MR9TC5AtPJdBmoHtVBAitFtrmfcJWP",
            "119":"1wX_mQ7jbc9OUw53RqszEADlI880Aebu_",
            "120":"1x6q7eCGAW2NToO1L36ItKBWuguETj5Nf",
            "121":"1yS9T7PiMZe-tFZ8JXaMKN2--sBK6shMK",
            "122":"1zG6NsZpzCsgvAJb3ka3Nll6osiH3_Wyp",
            "123":"17dlmDl_tIg1IhPiaTcufnla9tpgBVluL",
            "124":"1oExdxpJ8cw0Asmlk4kDRngktj4a3BcbR",
            "125":"1DAbOKIBJVTHw0NDCmyQIG4poJHwfBe5v",
            "126":"1iFBlbryjplrMao-Ts-Ux0pCcd3NkEgPo",
            "127":"1lmg-1Jl4fn6z8rqC7FfAdCmSPFb7Ble-",
            "128":"1n_gzP6RkgGw9t7cBqZTYgfMKapXVciYn",
            "129":"1zEeaUFMWMLRKJRynaXOQgyfit3h3gBpI",
            "130":"1EGZzm2gRfSJKwuAhe4SM6S6lA85RqEB0",
            "131":"1OmnD6r2xrzq0-KnkNZamSDmiBjvHNxcy",
            "132":"1oJVuMSbYxrWX1Gagfmj7_uduqUgFAOAw",
            "133":"12hcozIj-xVQ0iZpHei2PDE9r62jMfffP",
            "134":"16jf_iXW77DWmypaqb1zEHHx13XajYVYH",
            "135":"16uCXpQwczHYloDBng1l0ccA-LTsUa7O5",
            "136":"19ogTdz89NNvg1Lm2kymUNsIISoodWGDa",
            "137":"1D6CHouMARvQVMtj8-kHIWakBeeasZjVs",
            "138":"1KjmTS-se0qSPIeFR7xqUIrJFcDjTk40F",
            "139":"1NRb9EXOFyniA_sBdlSne7_23bs5dz8iJ",
            "140":"1Zvz7s5EhW4bjVXz6Kh6Z7BU3qlKCyMsE",
            "141":"1b_PtjgU1NYtSSSCn1dNGqkZ6r_zW3VKs",
            "142":"1fEVIfFlHvXpUR31a0_TAJBsBf5_KCMCc",
            "143":"1gncARcAX4-rfh5ZfTX3NckJyQppiJI_x",
            "144":"1hZP0SiymdGyZ1DRs5fMR82i5YesZj20E",
            "145":"1kI3e2YR6WOFZ3XCPitFK4vJaDWtLYQ4V",
            "146":"1s69gYTx-Bma362VLoQhqDd_3o_wuyQlG",
            "147":"1vcRZnvARu2miq8DQ9qhnKMCuC2l1jgm4",
            "148":"1ytJDR9ATEv_PdoQeHKgkhCdFa7L3RkG0",
            "149":"11R4vGTxXfjDVo-aGJkd_Vy6r3_04lyWd",
            "150":"129IYHlUJAjmBjUBIHK7zD_VJ9xLGL-6Z",
            "151":"17J5Ky4JqbMs5qeCxQzzeabCvNzdnWYvE",
            "152":"17J8qW9TXllitzc9MCiijlbm2uOV2UD2y",
            "153":"193-zsj_K1UQb01wYniJDkQFx-hdUlyGz",
            "154":"19TxpVewKHLit-iJlprdrNMLMpKokH6J_",
            "155":"1Bwto2YGzUFiqbLFE8Q_oIPnJg6ohPf8z",
            "156":"1Cgn0AF-di8sJIak11Ja5K2w07jOqEM_y",
            "157":"1DC4coS77PWZFw3yIRe1PPps6HwRFxBnS",
            "158":"1IABkRKXJvKimlHl3rxetVJ2lnHAjfyWM",
            "159":"1JzNEx3rClIWPklXjqpl44K3Ow2evP9T0",
            "160":"1M3Qg0eo9iyrZCiuwu4dEawSUViq_fKYI",
            "161":"1MAhvjWEDbVeSPc2Vr-e4BEjd4HU7Hvtf",
            "162":"1MEmVcc-ZXSlvJi_vi3Nv4F5TraXtMfKa",
            "163":"1N-1Uam-fjpbecHSuRfI435GZvcC3D2CU",
            "164":"1QSkQP9zjS4WBH9aN2tylbdSmiaRwBjWI",
            "165":"1YK7Sb7KUyBRp3iJa13DcP2cxO1r9aKrt",
            "166":"1Znxk-Ofhk5xd0hwB12qZDkJjRjS7AX_v",
            "167":"1bgjEK4mTZzeovyMkcHgGZf7OtlHR2PQK",
            "168":"1jL9iYPFWXfYa-vC1g5lR3da3CnRyjnC4",
            "169":"1nA7lu1aJs4pQ83FU4UxKXeCTCarwUA_6",
            "170":"1nofscI6HbrVgptt87qSEsv_0qs5zmaLs",
            "171":"1qFvHtF6CTLKD0PVAonOY6wHSF5wFYBhx",
            "172":"1qg4bzp8ep30XNXKMb24eaA-pysQHZ8e1",
            "173":"1sxZAD8csdd7SoIRAI4V0FszxbacLiye2",
            "174":"1yIctHAnnlDbMiDE412JUmKTf49T_oRrs",
            "175":"1zTtvLh55Z3zf2nLhOtH8Ia_rHhUuAL76",
            "176":"1zbOUmt28_p_2eLUar2rT8iBNvi0c-kfA",
            "177":"18ZCXUBwShFIhdHdFALFNliNHIpzQNWCy",
            "178":"19LNhbDGTyDjHzDhH-2kqkwnYsfgxKp8F",
            "179":"1EY9X9B2IaLCbR--5imWrP7fUdhxsM62q",
            "180":"1JN2TqPMvTcc0BCVTsjAc3t-sKZxUaJYX",
            "181":"1LgC9X8_Do7y6TrlQSwfW4P7EIPbpfmLO",
            "182":"1cNODj_qnLoygkCsBsR5elg0Egk0WMOaF",
            "183":"1ek_VKMVMeyPEGnKobiAvX2A0q-BDVvzN",
            "184":"1hye7dNY9z3K6kuh2S6zU79lVPg-Mkkfh",
            "185":"1jafs01QvPd_KYBDhuMDWIEZa8HPv3Up3",
            "186":"1kHWeQ8Oz1NXvNws_UIs2Aw4fC30PbNWY",
            "187":"1u6x-NjzJGhwT2BjF-UiLoNo29FLMYunN",
            "188":"1-62dnlfChGYqs64XgIKDGYFj4EX-R6pN",
            "189":"17ube_T6cBYAR1VOpyYMOQ0un1PssDBsl",
            "190":"19u1zOctnGRFzoD-jVxSo8mk7YFb4tR9E",
            "191":"1EikvtkV3Icwyr3jmpNHYC8iCgEPlO5kI",
            "192":"1ExQOAW-0dXJNJGBVESnSQ_HO5dLDbym0",
            "193":"1EzcvokbmCJgONx-lIhRo3VQL3f0E0N4X",
            "194":"1FjIncpcgotfubIR-ziLPDaEBPpe1ocno",
            "195":"1LvuSx7zOpaYFI9AYTdZKyMxtN9VMjiQW",
            "196":"1Y_0MvylqpGZZpIlnEwtgzS1MRRxJ2M0c",
            "197":"1c3dqQ8BTixoI1luwpwn8LFM0ZxGeraTO",
            "198":"1cFzZsVNfmzWpAHeDIIicqrmK15MjDUKH",
            "199":"1f_kHsnXx4hocSqSKNriMlxNPvKGLzEKu",
            "200":"1mJzYX-bm0oyX5mfSpucR9nVYLjXyqZn-",
            "201":"1qav90Ds4Ny9fy6yN0ooGAmDk3oqoIhlZ",
            "202":"1sEiXnJa-D-_MCMaxWc8dhTlfUu8eQWMH",
            "203":"1zcw8-YBot0EHkjiPDh1YZiAx-Nxz3vL7",
            "204":"1Gw1RIgJHB15qkt9cda-Up6oLzU_3ITTA",
            "205":"1Xh5n1mqxsUBObmvS3aS52zGtHeZuU4h0",
            "206":"14I_wr9MZr5T_ydmOszsdG8IjinkeG8_M",
            "207":"1OLJdIggCICRBI9oA1gnpHG-hNw7mXkvt",
            "208":"1ZniW5MnCU1Vn2tVXP_oislHsEgI2XunA",
            "209":"1aoaUNzKKqZ8tvhrp778C_y0j2-OnCXep",
            "210":"1l2gwKDQ0fQKKPjRBDDH_I1VkZIDJxfPB",
            "211":"1l7m8xXM7G8ILQg7LtdYekfjUiBNlEOaM",
            "212":"147XRP_QOHJ-Qb1efF5rFFwpmeYD-c3AP",
            "213":"1EDub-Qd7YZiFC-dadcH6REome-xN9jI_",
            "214":"1OfJgLddmyirFkdLHtjFgiEprbYDMUG4R",
            "215":"1TMiwE8ev01EmALhJer1MlhiwQKyRICzm",
            "216":"1mWVi2TrmhZdbGAJ_RtXIAyOgMkk5XBZq",
            "217":"1oAat4o4iwJMcMeQmtDXhMFfQVH6fW-9x",
            "218":"1OLfxc_wuUDRQft_7kSuS8snfCGPXgxzn",
            "219":"11L_5JwbLP7IrOBOMy7GRj6HN5_xuCGRX",
            "220":"18132eHCJT3F1Ap_9oYSsDpnvsDouGBbP",
            "221":"1CioHsMSZF9zlvCUkeGp0w7j4jxwzKDO-",
            "222":"1DaXh_ff9-dpeRoid_V1bAkg63ATqyAqR",
            "223":"1LAL9sMYRbjL9NzwuwGqC6jd-sMjjQ7uI",
            "224":"1WDgzvzXKs9cysQ4HzjTTWs3zHpIIIx6C",
            "225":"1ZMcah3TYnH8bqYJIK9QN-qcdGTItYRY4",
            "226":"1cu_dk9Bh8j5R-oC8OJLTEt227_HQY1IO",
            "227":"1nBbKZhVpMtRDOKnMz1zjnnvfIeUppvO6",
            "228":"1pXM-_LBn0Yp1qKobr3rfd1LSqOvcdhbC",
            "229":"1prqOgtS5P28Z1FcJGoJUjLptnE6sOeEe",
            "230":"1qcg-sOML_6_ZSiZ9V1pNv02d9MEp9giI",
            "231":"1teIyVE07ZkbYKuameLrkj22vDI8vHU1f",
            "232":"1yRv314NVGqLulgf_ec02uTpydaefeX0y",
            "233":"11SUVeE9JmywLpzebKIzumxXNIL7WIOEa",
            "234":"16TOrOISl62LRc0FYR_-ZdP3lvf6_n1Kd",
            "235":"19VPfc7l-mS6PXTXsQI2thAlV1Ac0QEdz",
            "236":"1D04Knq3wPPN60_na0clnXLwZf5hBmufr",
            "237":"1HvR8eCQZrPrRjUiPdf1nS4YcuNX85vbe",
            "238":"1I92NHkcKI1HiYebqkbrtlGO89mLh_xGC",
            "239":"1JeJT2t5HQNl9boPTXuCcBy8rQEX-LZkr",
            "240":"1Jy77gsKGAkjMndWXgDcrKn8vnwehIoSy",
            "241":"1KV2BFMXpyLZQmKyaleiiWP35yugRFCUs",
            "242":"1PWESHbs_bYFN4lAcC75Fg5ciPiwTaN5W",
            "243":"1PibmN2CPcJ644SUMj1aVUrFLlJGniuMR",
            "244":"1S0qRlfyhPMrz4waQmCX3AzIIa3NnvECo",
            "245":"1X8YblRUuH_LYl7N3EY_Qf8cVN-ywPfbr",
            "246":"1a-nap39AgZ4zxm1jOTewbigt2uxFrn0m",
            "247":"1cM-kQa_hLWpLyAxYJrt0qFowjskcXirc",
            "248":"1df6MCir5LCfGk568Eq7S2-a8OpIomwoJ",
            "249":"1jqccu3rbdJv2ZDb5LEa9rmSK1NtiVElN",
            "250":"1kSZXdGN_FW73KTLu_bJi6B6dUFZSDBBy",
            "251":"1sbj3j50x2NfQS1WfCCDpoKiA-4YNqb0O",
            "252":"1uWv5L1vwjh85sR9oVStD4SeqhWMuxmaE",
            "253":"14Cg8wvBlI6QJjtN1MVAuL60hoYfolp2J",
            "254":"1J8J_srQJFqJoPwUk1-oW2MoKYIOmPsTS",
            "255":"1PoC5unGrE9HOh4Nxz1_1JG2Xo02Bw67K",
            "256":"1Q8E0ghYXQJ4ez4GWA4j1FlEm8vSgnFZj",
            "257":"1RpHfSpkfrufSMmdHvv7EDgvjo62Kim_s",
            "258":"1RsWqJnwlu84voEw1EICBJHPhpv8Nj5dC",
            "259":"1SXEho-sbTCUAKJw6xc-39PFvqDvmf9R0",
            "260":"1UjdU7-GetlGByPrfS0vJHy5rL2KAkcWA",
            "261":"1VUatGeKbARApGhOUuKFZFLmGJFrPVOzY",
            "262":"1s7pDFjJCn_vhDY018Gr6l1ZHLd4bQ1dC",
            "263":"1sZqQn4urJc3wE63LcqAv9QJybYOE12wL",
            "264":"1tNl-5g1gDOeQ4yhtaxWO6bp9-POQhpK7",
            "265":"1uiksZ-h3SrHf7vmAfQIsd7B6LtMAm_FP",
            "266":"1vQSOGDH5iWo5YO8W04yfUWwx9NzTzWYE",
            "267":"1wtNi9Dm1561iNKQs8qYFCPU3mkYp9qEK",
            "268":"1x9c9N_WhobasQ_a-K5TPdSRxuK4KdgbH",
            "269":"12VjhI1OV-3fB2GtOYgKwx5OX5SUzsSz9",
            "270":"13b8R5E8TxmCQCQwxJCPAHgnIwmGACFCo",
            "271":"13w1qxDLcFsOA0mIvqzhuEZhoE8scNYUa",
            "272":"15hx0zU7OY18QX64oM0sH7_E_Y5Z_LSZI",
            "273":"1KjAZp9uYF9ikG_KHPJQahHq6YqUoz6As",
            "274":"1L02Px5txsaqrg4skgzDVWqTOR108GpjB",
            "275":"1ca17sFd0vrqCdzH3VbRylV-cUvug6isP",
            "276":"1ompo2WdYrts6Ow63z6zwUitZU4yPXGTU",
            "277":"1pJoEfSWLyMbO_Qnn8TD8nVyc25sAIYTU",
            "278":"1ucgP6cssEYHv2CtLoFdTXxpXJR2bOW5Q",
            "279":"1xQxzjMo9lUbj434QKZZC-iPC3a1Ve3eJ",
            "280":"1y8J1giAMYPVvNFW2Vc9M27_HZm30xCnd",
            "281":"17ExNN8AQpEG2ZJTWxOAoTvLVeITlcaFv",
            "282":"1Aco98gH1s34tgCjIQOxuQ80IxXdaM5qH",
            "283":"1GzLcfdz-b67mdaiBLnOQVADLuxkcwuPN",
            "284":"1HQungBT6GMNHrIMHkGQ5-Y1eHDkAj11I",
            "285":"1HgFo7sdgoJe6p98bipPEb_h3YIfb7ADd",
            "286":"1fExC8MXyNSeoXF7KLaxVm_0TrChCBJQp",
            "287":"1y-TGWaPqL1DIq3VoJW2gTqT3LspybCZL",
            "288":"12Nqfj8QDyARywNUSrgjvSbx23Nr1EZT9",
            "289":"18WJFJEjhcSvYnwcgjVgSssWB-n8s2BiN",
            "290":"19SJ_Z_6qiFxA7LDV9kfx2FV55P5D9f72",
            "291":"1DELsI7ecBbELj4BiiVxR1UB7rUDSCFjN",
            "292":"1FvPQhvA3Ext7Klsjq1D5sR4Vpq1Zk7cS",
            "293":"1Zn7hYKKEdmuhsjMNMz84xnsFOKU9F3xi",
            "294":"1jsAWW7JYOet3e1NBl4KRxlRc4-CW8uNk",
            "295":"1kq2SEm_Ep9h76EZz9haElqH4E-6IW0NZ",
            "296":"1lmMNO53CdcQ-mumV0R6qpnzrqkoYkOD-",
            "297":"1pQYpjsJiFLz3Lq7r-OJ48APePXCSPiWd",
            "298":"1vftwJtuetblfthDizkLRUVUZKVb2eJNs",
            "299":"14IGHwazbZmf7LoQBGkAojTChNdDvv_Ex",
            "300":"106ZB-comJAT8LGs1zHTcy1ndWUQ6bBvk",
            "301":"1CsTC-96Ygwc5ZOTk8ii9ClLetYcdGgqi",
            "302":"1JPtvxFR6HPpUX4Ds_39_TnsINWRak4et",
            "303":"1P5_dEQV2eLWuVS-Bof83iNj5Hb9kFGnr",
            "304":"1Pd95GXSVWVpt9HY5S04L8wkdtakPBCwC",
            "305":"10cCa5S0zpPRWuzIpmXbTohU_susER1_e",
            "306":"1BK3HV-D47d2lufiEpci6Rpsdu5qdZTD3",
            "307":"1IKJyqWLCriTfT84f2RleN7OizjWBukyo",
            "308":"1cz6FULeulzmASm1IGU3aLO26eygbdLw1",
            "309":"1ge-9rz5ZovyrVVa9T9crbU7CjBKMosbN",
            "310":"1iPy3645psdR3rSoEAf9fY0L6bFV55SeC",
            "311":"1GiBbrAD-yBZRQMJmJ-FRokrOw1B-p7-a",
            "312":"11vzWw2upGjz06_g3LxMGcOCAHYNKpilV",
            "313":"1fXcFCuUFOj2zO6fYjRGBDmdJsq39Xr4T",
            "314":"16jjqca_LhQlP5Dc0eriQN5iq4HaU4Z_0",
            "315":"1VCFZqX4DqRBM_UXui5NFtc4I70eW5i4z",
            "316":"1av-RFpiTLMzKA6hQRgwBf-dNTHfyZPeP",
        },
        
        PERAWANG:{
            "1":"16u7Cr2vJv3GurmgdIfKJHSl5qIOEv-sq",
            "2":"17IJqjMtQKhK8_a_EJndtxtlbhOPWUKEE",
            "3":"18ubBzS1_-eIM4DinBm7eEThTqV50HZrm",
            "4":"1Lmeuz67vjN1Xz7Fggs30WGGS687fS2Ko",
            "5":"1LwnJ0Xow3h4SN-E6CVHQya_5Xw8vb_2z",
            "6":"1VFdghkqzdvXwW9KjxeBHnny-jRW_vmkK",
            "7":"1X-Lg1nkad2VZ3ThkCyPJDNbWlfw-zBh2",
            "8":"1e0LFYcxtyzJ_iqj14ZWN608R_0MCrRse",
            "9":"1eFXNh2teOs2mx8_TozoImgsiThVkwejp",
            "10":"1yxhGnnaBXuSaqFTmlsHd80EzArBJnn0p",
            "11":"1ZEdDXhM3brSRtVyipog77Nrfv4FupszF",
            "12":"1V3nV0inD4Ze3oLSrJZiaEDfyxHwaltMT",
            "13":"1Z6S8M-lSqY_BpFwW7MyrT7KHLYc5fX5i",
        },
        
        UKUI:{
            "1":"1rj4UrJYY4C-nuON0kOfMmXLQizObvQ_w",
            "2":"1k2JkTKVT3W5fyyFnCUdnJFx1Yh9K-WTl",
            "3":"1d7RLkIqXVOyhKkFepkZSIFmAEg0pt_FZ",
            "4":"1qmI1yVpzkjAp7m1tlfvCzwfec92xHWcl",
            "5":"1Vcr9OUtRIHjFcZlexK2lc-8dx6oRUarw",
            "6":"1cDFNGKMqWgpLrQibQ-BUs37-Ehm2gRgF",
            "7":"1kJh6mUu4c6uKjcyTst48ausuqknulNC2",
            "8":"1dyT2lHSKgv4yim--zykcU-eq3SOFUdxD",
        },
        
        LIRIK:{
            "1":"17FTw7AaAcOnPEhaUuMG-qAjf_PxQnwcr",
            "2":"1tTCIroFhIHXa1g0rOZalh2f8hX90ib0_",
            "3":"1xeyIlBhJ9SWtmJHL7ub4ftz6_sOjGGdf",
            "4":"1BtK2Gsej9EikdTi21rRcqVY6BFl3Pw1S",
            "5":"12M1v67RgsMmgjxGxv75ZO6gWuGPcC_LG",
        },
        
        DEMANG_LEBAR_DAUN:{
            "1":"1BUN4AuxdajnlKDZwYIA8nq5ux0r3My32",
            "2":"1GajSl68X_t9_O4BKBX1MRzOutwsXs-Ov",
            "3":"1RB36UsJdvXZxO7m2BKS1iKk-S92LG_He",
            "4":"1icv2bSkAwceClu7A-DQWMOAw6XXSAMQO",
            "5":"1ruADpNiFxWK3Lpy9B9-gQDDukgvg-cOr",
            "6":"1uZZqQvgkZnmHgT6nTWMWRT4E4vGV87Vw",
            "7":"1udPBQ8ztJ-vKVL1VmjtNvudgbFGv01Bn",
            "8":"1fHn5Wuwcq_bvng98or4iCJHWLS_f8CRo",
            "9":"1BxyqnLpVIyMc92SjTMVdk4PhKR9xAgpH",
            "10":"1-Lh331--9F-FKm-s8XPxTi-NBF85_L3l",
            "11":"1wUpMt-JHWGWnLvzd969LWH8dgIJmOxCM",
        },
        
        SUTAMI:{
            "1":"1jO5d2oGGo3QJuU4pY85adGlzdtlWUyEm",
            "2":"11qbWI1ajMF_Am_anfxnN1l6eEVCidtBb",
            "3":"12aLD3OQY4jobFxmzhEuGdQ60oHTfWZxx",
            "4":"15ZDKLzMFYe9wpuDBFZeOLsCGCketZxSM",
            "5":"1KlPn7tNmZaUFQ6bVLKhSZgJYIpTP3nkk",
            "6":"1qiKonI3NJjbmHsyQ73SvA6MfTaG5SFM0",
            "7":"1xb4JxzbgoFD_TsdzLhOKW2Cz7nwh2iJB",
            "8":"1zWSG18ir9QEIbWldNIMO66zEni4vac6B",
            "9":"1CH1_6wa9IH_nOEhko0BxWS5gdfTrTF6q",
            "10":"1It5KQFRrHzj7odcc5TBi3Dd5do4AHc4g",
            "11":"1SgsrNDkpja5c84WBdeHXfUJw-uOrKTOT",
            "12":"1lHz-huO2NXcYA7jJ-oPbxXSv5TqBUUUI",
            "13":"1mJAX9IMxhBn46v21iIKf8DGapQytmpLk",
            "14":"1biJkQGg8bV7Htjv1_0I26xjGPhXLgalB",
            "15":"1OSXe-4cEmSJFOqLOmfRmBcs9Izx0z6x1",
            "16":"1nya_X9J107zux6AmBERbMaEcadqL0ais",
        },
        
        SEKAMPUNG_UDIK:{
            "1":"14ueqL545TI7fVLpOhsN_yiqakcldWDcd",
            "2":"1G7x9O6cgrSOm4wg431LGQ-xYdzzFXyXx",
            "3":"1ViamHKYiZDp7SS3mNGxr-awukzN7EyVQ",
            "4":"1ewt1aaYTuNLgQ3XSVntlOJ7jvJqlLta5",
            "5":"1ryjjd6NrzHA4zeWS6WyihUiC4tFSnsJo",
            "6":"1vdhXch0XGyjtJV1CxQtEwjK7JfSi0h8R",
            "7":"1z7KsnUszEVo-VT1Pza16bQRFVGgDVfVI",
            "8":"10b2JHk7SY4j9394NdWWphxzOUgevO2wc",
            "9":"1ni5aBtPKlXW41pLCnZI-4jSO204R79uS",
        },
        
    };
	
	
 const photostockData = [
   { id: 'BELAWAN', label: 'BELAWAN' },
  { id: 'SICANANG', label: 'SICANANG' },
  { id: 'PAYA_PASIR', label: 'PAYA PASIR' },
  { id: 'PASAR_IX', label: 'PASAR IX' },
  { id: 'PANARAN', label: 'PANARAN' },
  { id: 'DUMAI', label: 'DUMAI' },
  { id: 'PERAWANG', label: 'PERAWANG' },
  { id: 'UKUI', label: 'UKUI' },
  { id: 'LIRIK', label: 'LIRIK' },
  { id: 'DEMANG_LEBAR_DAUN', label: 'DEMANG LEBAR DAUN' },
  { id: 'SUTAMI', label: 'SUTAMI' },
  { id: 'SEKAMPUNG_UDIK', label: 'SEKAMPUNG UDIK' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesNativeStock, setSelectedTablesNativeStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesNativeStockOptions, setTablesNativeStockOptions] = useState([
    { value: '', label: 'NativeStock' },
    
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'kosong', label: '2. Pertamina Gas' },
	{ value: 'nativestock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'nativestock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'nativestock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'nativestock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'nativestock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'nativestock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'nativestock_sor1', label: '16. PGN-SOR1' },
	{ value: 'nativestock_sor2', label: '17. PGN-SOR2' },
	{ value: 'nativestock_sor3', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesNativeStock) {
		
		if(selectedTablesNativeStock.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesNativeStock(null);
		}else{
			navigate("/"+selectedTablesNativeStock.value);
		}
	  
    }
  }, [selectedTablesNativeStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tnts" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesNativeStockOptions}
			  onChange={setSelectedTablesNativeStock}
			  placeholder="NativeStock"
			  value={selectedTablesNativeStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/native_stock_bg.jpeg`}
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Nativestock_sor1;
