import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_sswj = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var photostocklist = {
        
        NR:{
            "1":"1PQ17trzR7xVVLx6TPd_QEBc2TqEEWv_u",
            "2":"1bidXGFNUWQ4wivK1q_NpiBaY3O9Dbf1g",
            "3":"1XTuVtcItFDfdFVc1Ue3aR9FBVdtG4rpY",
            "4":"1QC6_TeRs06jOS4xseBWVUsDV4C9565F7",
            "5":"1m7VtQd2ipkPhBalNcsX2NW3dhNd514xZ",
            "6":"17ftc6fvN5EF1l_RTULjq4p2w2_oVaTWV",
            "7":"126t5J8rj3hgkGxIs2rUD9kco9yOab8-t",
            "8":"1lZgiq-oBtoAaMhrCnr4NPIzLF3J1npds",
            "9":"1bxV3qpdrk2RTPyUpmfeJLrv2QLej3lEW",
            "10":"1bpdDw6v9m8Xh4t5cLrrbej2-Dt3w17Qv",
            "11":"1J38PvP3JXnTUob23spYOQI_q2NOPekxk",
            "12":"1eZni462hOUjBvnBdY9Pnk6UGBoFjM2sl",
            "13":"1maf73aLzAqjoUOboBR_2q9rAJBfUHa-u",
            "14":"1sTyJ-cg7STpaDStxi15W9D5TOG-u3yVL",
            "15":"1B8FcD_3OFECHo1ZLvHWCvx5CL4eZa6at",
            "16":"1sLH48wXgc_rbQ4iR8kD6uCZWTMSAv9a2",
            "17":"1Xh-Q4E1SNj0sYurfyGMuq1tymRvQgFKz",
            "18":"1idcbmmPT4vqehRP2k8tBeLJBgXgHvXQm",
            "19":"1W263AjkRaRmfhRyH-qjQubcCc5C_MFPD",
            "20":"1o-zKGLczuceqWHuDiDsISXEsmkvtt3Xo",
            "21":"1I8NPlkFjpwbv7Lr147tAyEybGUqUsGm6",
            "22":"1UnBLYS2HyQiQY1dfWvSSb0i27vYiJbBf",
            "23":"1mMky9MImGz4WHqtk-lDzML328JFH8ych",
            "24":"1kRlRLBu4Y2SC2yBoh0XA6sNHQ2TjZ3fo",
            "25":"1UTikEUjaCncW0ZB1cU6w_TCscPqdGY0I",
            "26":"1VmTzZ1fcMxzc9LTCyGv4aJkldsECsXJU",
            "27":"1c-XLdK0-lBWcUoFsWosTD8M66lFfl_Wn",
            "28":"1eDjNNj2mb308oOu_zm8ejD2OljBTE3OK",
            "29":"1F6ymdvz7OLpxOd6c2t90-WNPra835nYg",
            "30":"1sx5ApkwJvY9Cl2pLloD4-JBJxajQj1fL",
            "31":"12CKem7tjeFAz9xM2xwlHS0Z0vAKMG4Gb",
            "32":"1CgoGDzOiW1OzvYqtAmtN8Q3JKQtzb7BC",
            "33":"1e5FUN8UWpQRRhBKVIUhRxUSKg2IfxeNm",
            "34":"1oHTl7pE-EyifKNSQE8-A6-yQ-a7Xu_Hm",
            "35":"1-UcMYpj8h-sNY7lbC1f9jPKkqEpHM4vS",
            "36":"1GF6cdUrXk2B3r46-VaB2l2mPb_EjkkW2",
            "37":"1mHrLbNWZqw4qNHAhkO5Uxb9VkiCVZjq5",
            "38":"11IEVvSRlKUfWryQ7pXmUR9SfgpMBiOyo",
            "39":"175gsesPB7fzNbRdPrrWnAvjWLOWAuu3n",
            "40":"1Eci_DysYcYE8g9edF7CLfMq2zZ2PvGo5",
            "41":"1Ek6aIhovmGia9sUAK94toH7UJrw2D7Rv",
            "42":"1LTgzOpHNc-IUL3dKZWmisYb_9NNx8rYo",
            "43":"1PBh1K85rZYOvNOiZfd8CgZsDQ8owS9vh",
            "44":"1SoE8dkxSG5z6i3h9Ig4mYiS9jOpcK_Mp",
            "45":"1TPfA2SUMzKjE4fLvKP4j5vOekFnRmPa_",
            "46":"1YwbVsccC9Q4ADnU5O2zQsRWw4rLV0nVR",
            "47":"1_IQ9Yf6keWxsgrmu5r3_KtGu84h2Mpba",
            "48":"1bMrjfwFrcPxV7AX7srcg-kku5m2ZVWc8",
            "49":"1dnSEjMxpWB9qapza4xu7Olk1LoaDSHxK",
            "50":"1xDIg15oM1aN4PwJJ5rz2QNfEKG7Db4l4",
            "51":"1xIyDe7_85jbusR1mPz6YYf9ipC3xMz40",
        },
        
    };
	
	 
 const photostockData = [
   { id: 'NR', label: 'ORF Muara Karang' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
   <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock.png`} 
						
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status of Infrastructure Operation Assets and Virtual Model @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_sswj;
