import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Tables_conversion_calculator = () => {
	const [isHovered, setIsHovered] = useState(false);
	
   const Standardcodelist = {
    
    "DEFECT FREE PIPE": {
        "Burst Tests on Defect Free Pipe": "https://docs.google.com/spreadsheets/d/1gLNvDrtIU6RVJlQmxyzcYbs4bEzV6M0e/edit?gid=728294588#gid=728294588",
        "External Pressure and Other Loads Tests": "https://docs.google.com/spreadsheets/d/1iZqFhvgjYSyym5i4NrnrebwUkhMA10Bc/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Buckle Propagation Tests":"https://docs.google.com/spreadsheets/d/1qE8vOsmxIrpmuzkTCZbxIFzHorCs7U8h/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
    
    "CORROSION":{
        "Burst Tests on Corrosion":"https://docs.google.com/spreadsheets/d/1ZKtHXH7wPGPxWs0vDZIpZi8-yG7X3LWF/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true", 
        "Tests on Corrosion (internal pressure and other loads)":"https://docs.google.com/spreadsheets/d/1pGUjwVo7eUhH8tjYVLmXSF7oRogA-dAV/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Tests on Circumferential Corrosion":"https://docs.google.com/spreadsheets/d/1IGRRIVsTfiCvcGvURx-fywFaIq0xyJXm/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
    
    "GOUGES":{
        "Burst Tests on Gouges (Part-Wall Defects)":"https://docs.google.com/spreadsheets/d/1lbdFZSKx0mb-aAARFYDjZOu5FJ3kmn7x/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Fatigue Tests on Gouges (Part-Wall Defects)":"https://docs.google.com/spreadsheets/d/151kljwdW-kA6mktsLlBAJRyB1xVWTZcg/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Tests on Longitudinal Part-Wall Defects":"https://docs.google.com/spreadsheets/d/1ZKZr70Gt6P7M2yk6C1CAyOQmmU4PbGJG/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Tests on Circumferential Part-Wall Defects":"https://docs.google.com/spreadsheets/d/1f57jl-_V6tGnEuMVu1oRdut9BT3yOljM/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Tests on Helical Through-Wall Part-Wall Defects":"https://docs.google.com/spreadsheets/d/1dJbBotzZ6B8nyB690-mmWKt2T8RBI0_l/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Burst Tests on Through-Wall Defects":"https://docs.google.com/spreadsheets/d/1-GtQMGaG4aUGgg2_uOiRPKmu3IVDGC3W/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Tests on Circumferential Through-Wall Defects":"https://docs.google.com/spreadsheets/d/1oNL6q_HFzm2AGzO3tO3u_B4wVvRbqjiT/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
    
    "DENTS":{
        "Burst Tests on Dents":"https://docs.google.com/spreadsheets/d/1JrYzcVHJJQBbZs05PF7VBBJZjbQm-07S/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true", 
        "Burst Tests on Dents on Welds":"https://docs.google.com/spreadsheets/d/1btfZ0B46mpGJe65BsvMAxwhcAflu_GJy/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Fatigue Tests on Dents":"https://docs.google.com/spreadsheets/d/1Fji8V7RpsKhBN4zM9uzs759O4ay7npSP/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Fatigue Tests on Dents on Welds":"https://docs.google.com/spreadsheets/d/13UN_PDJq9jdM-u9Q4JNBpW9fKPycD-Si/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Spring Back and Rounding of Dents":"https://docs.google.com/spreadsheets/d/1xVd_wJtRZILZEX_oZ66bp26Pyowl9SZe/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
    
    "DENTS AND GOUGES":{
        "Burst Test on Dents and Gouges":"https://docs.google.com/spreadsheets/d/1AFNviDvD04IDEBek9bDQfTbWMI0LoJOp/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Burst Test on Dents and Other Defects":"https://docs.google.com/spreadsheets/d/1uiSKTR3DERENYu9d_bRG1PFdZO6fnJNZ/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Fatigue Test on Dents and Gouges":"https://docs.google.com/spreadsheets/d/1Y6xF6bdRvgULFGPe3ADc-Ph8OtW9tzz6/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
    
    "DEFECTS IN PIPELINE FITTINGS":{
        "Test on Defect Free Bends":"https://docs.google.com/spreadsheets/d/17Eu7sPVyD8U6_Yr4YpFaX95P1-Vn2g8M/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Test on Defects in Bends":"https://docs.google.com/spreadsheets/d/1PgrvXMg0PnPKv26tqj1txndbpAJ9nOCt/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
    
    "DEFECT INTERACTION":{
        "Tests on Interaction":"https://docs.google.com/spreadsheets/d/1tQr1lIcj5iT2z3YhZY4BWzAI-vOIriw2/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",  
    },
    
    "TIME DEPENDENT BEHAVIOUR":{
        "Tests on Defects at Constant Pressure":"https://docs.google.com/spreadsheets/d/1kgzube1q6bMz4s_QPFHE5qWvBF_uR7AD/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
    
    "LEAK AND RUPTURE":{
        "Burst Tests on Through-Wall Defects":"https://docs.google.com/spreadsheets/d/1-GtQMGaG4aUGgg2_uOiRPKmu3IVDGC3W/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Leak and Rupture":"https://docs.google.com/spreadsheets/d/1W0F3bKWzGzfSz5eCkewtJNMrS4wPQ5hi/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
    
    "FRACTURE PROPAGATION":{
        "Full Scale Burst Test Pipes Data Bank":"https://docs.google.com/spreadsheets/d/1hXJSHA5p7fSjLTfKV-jiix0a8LEBV9fX/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Full Scale Burst Test on Longitudinal Welded Pipes Data Bank":"https://docs.google.com/spreadsheets/d/1hnZuX56EbtGNcRWYMekxFO0CgjFlOqeT/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
        "Full Scale Burst Test on Spiral Welded Pipes":"https://docs.google.com/spreadsheets/d/1W2GVD9DPhpdsevxk4JGhGXUVSKjlh-Cl/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
    },
	
};

   const [userData, setUserData] = useState(null);
   const [token, setToken] = useState(null);
   const [rimoxKey, setRimoxKey] = useState(0);
   const [selectedStandardCode, setSelectedStandardCode] = useState(null);
   const [codeList, setCodeList] = useState([]);
   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};

  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
  useEffect(() => {
    console.log('Selected Standard Code:', selectedStandardCode);

    if (selectedStandardCode && selectedStandardCode.value) {
      const list = Standardcodelist[selectedStandardCode.value];

      if (list) {
        const newList = Object.entries(list).map(([key, value], index) => ({
          id: index + 1,
          key,
          value,
        }));
        console.log('Mapped Code List:', newList);
        setCodeList(newList);
      } else {
        setCodeList([]);
      }
    } else {
      setCodeList([]);
    }
  }, [selectedStandardCode]);
  
  const [standardCodeOptions, setStandardCodeOptions] = useState([
    { value: 'back', label: 'Table Conversion Calculator' },
    { value: 'DEFECT FREE PIPE', label: 'DEFECT FREE PIPE' },
    { value: 'CORROSION', label: 'CORROSION' },
    { value: 'GOUGES', label: 'GOUGES' },
    { value: 'DENTS', label: 'DENTS' },
    { value: 'DENTS AND GOUGES', label: 'DENTS AND GOUGES' },
    { value: 'DEFECTS IN PIPELINE FITTINGS', label: 'DEFECTS IN PIPELINE FITTINGS' },
    { value: 'DEFECT INTERACTION', label: 'DEFECT INTERACTION' },
    { value: 'TIME DEPENDENT BEHAVIOUR', label: 'TIME DEPENDENT BEHAVIOUR' },
    { value: 'LEAK AND RUPTURE', label: 'LEAK AND RUPTURE' },
    { value: 'FRACTURE PROPAGATION', label: 'FRACTURE PROPAGATION' }
  ]);
  
  const handleItemClick = (id, key) => {
	
allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});	
	
	document.getElementById('iframetbl1').src = Standardcodelist[selectedStandardCode.value][key];
    document.getElementById('ifr1').style.display="block";
	document.getElementById('homealive_standard_code').style.display="none";
	document.getElementById(`${id}open`).style.display="none";
	document.getElementById(`${id}close`).style.display="block";
	  
  };

  const handleItemCloseClick = (id) => {
    
    document.getElementById('ifr1').style.display="none";
	document.getElementById('homealive_standard_code').style.display="block";
	document.getElementById(`${id}open`).style.display="block";
	document.getElementById(`${id}close`).style.display="none";
	
  };

 const location = useLocation();

  // Mendapatkan code dari location state
  const { code } = location.state || {};

  // Mengupdate selectedStandardCode berdasarkan code yang didapat dari location
  useEffect(() => {
    if (code) {
      const selectedOption = standardCodeOptions.find(option => option.value === code);
      if (selectedOption) {
        setSelectedStandardCode(selectedOption);
      }
    }
  }, [code]);

  // Mengupdate codeList ketika selectedStandardCode berubah
  useEffect(() => {
	  
	  
    if (selectedStandardCode && selectedStandardCode.value) {
	  if(selectedStandardCode.value == "back"){
		  window.location.href = "./";
	  }
      const list = Standardcodelist[selectedStandardCode.value];

      if (list) {
		
        const newList = Object.entries(list).map(([key, value], index) => ({
          id: index + 1,
          key,
          value,
        }));
        console.log('Mapped Code List:', newList);
        setCodeList(newList);
      } else {
        setCodeList([]);
		
      }
    } else {
      setCodeList([]);
	  
    }
	
	
  }, [selectedStandardCode]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
	
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`}
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', marginTop: '10px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="sc" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={standardCodeOptions}
			  onChange={setSelectedStandardCode}
			  placeholder="Standard Code"
			  value={selectedStandardCode}
			/>
        </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '250px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '250px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						{selectedStandardCode && (
						<ul>
						  {codeList.length > 0 ? (
							codeList.map(({ id, key }) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  style={{color: 'black'}}
										  id={`${id}open`}
										  href="#"
										  ref={(el) => (allIframesRefbk.current[id] = el)}
										  onClick={() => handleItemClick(`${id}`, key)}
										>
										  {key}
										</a>
										<a
										  style={{color: 'black'}}
										  id={`${id}close`}
										  className="all_ifr"
										  style={{ display: 'none' }}
										  href="#"
										  ref={(el) => (allIframesRef.current[id] = el)}
										  onClick={() => handleItemCloseClick(`${id}`)}
										>
										  <b style={{color: 'black'}}>{key}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))
						  ) : (
							<li>No data available</li>
						  )}
						</ul>
					  )}
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="tmp_url_print" style={{display: 'none', float: 'right'}}>
						<a id="url_print" href="" target="_blank">
							<img src="https://drive.google.com/thumbnail?id=1B3NWf05IkIaifnTa9g64mVGEJEDse3Tc&sz=w1000" style={{margin: '0px 0px 10px 0px'}} />
						</a>
					</div>
					
					<div id="tmp_url_download" style={{display: 'none', float: 'right'}}>
						<a id="url_download" href="">
							<img src="https://drive.google.com/thumbnail?id=1wHk7gUjP0XZwoY78aSvm_37P7VTj4aei&sz=w1000" style={{margin: '0px 0px 10px 0px'}} />
						</a>
					</div>
					
					<div id="ifr1" style={{display: 'none', width: 'calc(100vw - 270px)'}}>
						<iframe id="iframetbl1" style={{width: 'calc(100vw - 270px)', height: '600px', overflowX: 'auto', overflowY: 'hidden'}} src=""></iframe>
					</div>
					
					<img id="homealive_standard_code" style={{width:'calc(100vw - 270px)', height: '600px', marginTop: '10px'}} src={`${process.env.PUBLIC_URL}/gambar/tables_conversion_calculator_new.jpeg`} />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		  ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Tables_conversion_calculator;
